import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Components
import { ProductTable } from "../components/Table/TableComponent";
import Pagination from "../components/Pagination/Pagination";
import axios from "axios";

// API
import { ProductApi, ProductDetailReport } from "../api/ProductApi";
import { BASE_API_URL } from "../../../server";
import { Checkbox, FormControlLabel, Popover, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function Product() {
  const [ProductData, setProduct] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]);

  // search State
  const [searchParam, setSearchParam] = useState("");

  //Popover States
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [startDate, setStartDate] = useState(dayjs("2023-01-01"));
  const [endDate, setEndDate] = useState(dayjs("2024-02-29"));

  const handleStatusChange = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? "" : status));
    handlePopoverClose();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleSearch = async () => {
    const name = "search=" + searchParam;
    try {
      const data = await ProductApi(name);

      setProduct(data?.result);
      setSelectedStatus("");
    } catch {}
  };

  useEffect(() => {
    if (searchParam === "") {
      handleSearch();
      setSelectedStatus("");
    }
  }, [searchParam]);

  useEffect(() => {
    if (selectedStatus == "") {
      ProductApi();
    }
  }, [selectedStatus]);

  useEffect(() => {
    (async () => {
      try {
        // Initialize an array to hold query parameters
        let queryParams = [];

        // Check if there is a search parameter and append it to queryParams
        if (searchParam) {
          queryParams.push("search=" + encodeURIComponent(searchParam));
        }

        // Check if a status is selected and append it to queryParams
        if (selectedStatus == "0") {
          queryParams.push("available_stock_exact=" + selectedStatus);
        }
        if (selectedStatus == "1") {
          queryParams.push("available_stock_min=" + selectedStatus);
        }

        // Join the queryParams array into a single string for the API call
        const queryString = queryParams.join("&");

        // Make an API call with the query string if it exists, otherwise make a default call
        let data = queryString
          ? await ProductApi(queryString)
          : await ProductApi();
        setProduct(data.result); // Make sure you also set the fetched data
        let currentPage = data.data.current_page;
        let pages = data.data.pages;
        let prevPageUrl = data.data.prev; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }
        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }
        console.log(paginationLinks);
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {}
    })();
  }, [selectedStatus]);

  const handleNext = async () => {
    if (nextUrl) {
      try {
        const response = await axios.get(nextUrl, { withCredentials: true });
        const data = response;
        console.log(data);
        const result = response.data.results.map((item) => ({
          id: item.id,
          product_img: `${BASE_API_URL}` + item.image,
          name: item.name,
          small_description: item.small_description,
          slug: item.slug,
          price: item.price,
          one: item.price_7,
          three: item.price_3,
          seven: item.price_7,
          fourteen: item.price_14,
          is_featured: item.is_featured,
          inventory: item.available_stock,
          renvenue_generated: "", // This field is not available in the API response
          number_of_days_booked: "", // This field is not available in the API response
          total_in_wishlist: item.total_wishlist,
          available_stock: item.available_stock,
          order: item.order_by,
          sku: item.sku,
          stock_status: item.available_stock > 0 ? true : false,
          stock: item.total_stock,
          categories: item.category, // You might need to adjust this based on the actual structure of the category field
        }));
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }
        console.log("NEXT DATA", result);
        setProduct(result);
        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {
        console.error("Error fetching the next page:", error);
      }
    }
  };

  const handlePrev = async () => {
    if (prevUrl) {
      try {
        const response = await axios.get(prevUrl, { withCredentials: true });
        const data = response;
        console.log(data);
        const result = response.data.results.map((item) => ({
          id: item.id,
          product_img: `${BASE_API_URL}` + item.image,
          name: item.name,
          small_description: item.small_description,
          slug: item.slug,
          price: item.price,
          one: item.price_7,
          three: item.price_3,
          seven: item.price_7,
          fourteen: item.price_14,
          is_featured: item.is_featured,
          inventory: item.available_stock,
          renvenue_generated: "", // This field is not available in the API response
          number_of_days_booked: "", // This field is not available in the API response
          total_in_wishlist: item.total_wishlist,
          available_stock: item.available_stock,
          stock_status: item.available_stock > 0 ? true : false,
          order: item.order_by,
          sku: item.sku,
          stock: item.total_stock,
          categories: item.category, // You might need to adjust this based on the actual structure of the category field
        }));
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        setProduct(result);
        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {
        console.error("Error fetching the previous page:", error);
      }
    }
  };

  const handlePage = async (e) => {
    try {
      const response = await axios.get(e, { withCredentials: true });
      const data = response;
      console.log(data);
      const result = response.data.results.map((item) => ({
        id: item.id,
        product_img: `${BASE_API_URL}` + item.image,
        name: item.name,
        small_description: item.small_description,
        slug: item.slug,
        price: item.price,
        one: item.price_7,
        three: item.price_3,
        seven: item.price_7,
        fourteen: item.price_14,
        stock_status: item.available_stock > 0 ? true : false,
        is_featured: item.is_featured,
        inventory: item.available_stock,
        renvenue_generated: "", // This field is not available in the API response
        number_of_days_booked: "", // This field is not available in the API response
        total_in_wishlist: item.total_wishlist,
        available_stock: item.available_stock,
        order: item.order_by,
        sku: item.sku,
        stock: item.total_stock,
        categories: item.category, // You might need to adjust this based on the actual structure of the category field
      }));
      let currentPage = data.data.current_page;
      let pages = data.data.links.pages;
      let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      console.log(paginationLinks);

      setProduct(result);
      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  const exportExcel = async () => {
    try {
      const response = await ProductDetailReport(startDate, endDate);

      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ProductUtilization.xlsx"); // Set the file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM
    } catch (error) {
      console.error("Error exporting Excel:", error);
    }
  };

  console.log("dates for product", startDate, endDate);

  return (
    <>
      <div className="title-container mb-25">
        <h5>Product</h5>
        <div>
          <div style={{ display: "flex", gap: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                format="YYYY-MM-DD"
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                format="YYYY-MM-DD"
                // Disable dates before the fromDate
                shouldDisableDate={(date) =>
                  dayjs(date).isBefore(dayjs(startDate), "day")
                }
              />
            </LocalizationProvider>
            <button className="button yellow" onClick={() => exportExcel()}>
              Export Excel
            </button>
          </div>
          <div
            className="search"
            style={{ display: "flex", gap: "10px", marginTop: "10px" }}
          >
            <TextField
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              id="outlined-basic"
              color="green"
              label="Search"
              variant="outlined"
            />
            <button className="green button" onClick={handleSearch}>
              Search
            </button>
            <button className="green button" onClick={handlePopoverOpen}>
              Filter
            </button>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStatus === "1"}
                      onChange={() => handleStatusChange("1")}
                      name="In stock"
                    />
                  }
                  label="In Stock"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStatus === "0"}
                      onChange={() => handleStatusChange("0")}
                      name="Out of Stock"
                    />
                  }
                  label="Out of Stock"
                />
              </div>
            </Popover>
          </div>
        </div>
      </div>
      {ProductData ? (
        <>
          {/* Product Table */}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <ProductTable
                extrasmall={12}
                small={12}
                medium={12}
                cardtitle={"Product table"}
                data={ProductData}
              ></ProductTable>
              <section className="container pagination-container mt-25">
                <div
                  className={prevUrl ? "prev button green" : "prev-none"}
                  onClick={handlePrev}
                >
                  Prev
                </div>
                {pages ? (
                  <>
                    {pages.map((e, index, url) => {
                      return (
                        <>
                          <div
                            className={
                              e.current ? "current-page pages" : " pages"
                            }
                            data-attr={e.url}
                            onClick={() => handlePage(e.url)}
                          >
                            {e.page}
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <div
                  className={nextUrl ? "next button green" : "prev-none"}
                  onClick={handleNext}
                >
                  Next
                </div>
              </section>
            </Grid>
          </Box>
          {/* Product Table */}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Product;
