import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../style/common.css";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

// Form
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Swal from "sweetalert2";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

// API
import { AgeApi } from "../../api/AgeApi";
import {
  AddProductApi,
  AddProductImageApi,
  ProductCategoryApi,
  ProductSubCategoryApi,
} from "../../api/ProductApi";
import { BASE_API_URL } from "../../../../server";
import { Autocomplete } from "@mui/material";
import { Formik } from "formik";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));

const MultiSelect = ({ label, options, selectedValues, onChange, key }) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={Array.isArray(selectedValues) ? selectedValues : []} // Use prop here
        onChange={onChange} // Use prop here
        input={<OutlinedInput label={label} />}
        renderValue={(selected, label) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((label) => (
              <Chip key={label} label={label} />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option.id} label={option.label} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

function AddProduct() {
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [children, setChildren] = useState(null);

  // Function to handle adding a new child
  const addChild = () => {
    if (!children) {
      // If there are no children yet, add the first child
      setChildren([{ label: "", value: "" }]);
    } else {
      // Otherwise, add a new child to the existing array
      setChildren([
        ...children,
        { label: "", value: "" },
      ]);
    }
  };

  // Function to handle changes to a child's name and age
  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const initialProductData = {
    image: null,
    name: "",
    sku: "",
    gg_number: "",
    article_no: "",
    description: "",
    small_description: "",
    category: "",
    subCategory: "",
    price_1: 0,
    price_3: 0,
    price_7: 0,
    price_14: 0,
    available_stock: "",
    total_stock: "",
    deposit: "",
    age: "",
    slug: "",
  };
  const [productData, setProductData] = useState(initialProductData);
  const [categoryData, setCategoryData] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState(null);
  const [ageData, setAgeData] = useState(null);

  const fetchData = async () => {
    const [subCategoryData, category, age] = await Promise.all([
      ProductSubCategoryApi(),
      ProductCategoryApi(),
      AgeApi(),
    ]);
    setSubCategoryData(subCategoryData);
    setCategoryData(category);
    setAgeData(age?.results);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length) {
      // Update state to hold an array of files
      setThumbnail((prev) => [...prev, ...files]);

      // Update productData with the array of files
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    console.log("Product posteddd");

    // Create FormData object
    const formData = new FormData();

    for (const key in productData) {
      if (key !== "image" || key !== 'age') {
        formData.append(key, productData[key]);
      }
    }

    // if (productData?.age) {
    //   formData.append('extra', JSON.stringify({ age: productData?.age, subCategory: productData?.subCategory }));
    // }

    //  Append each image file separately
    thumbnail?.forEach((file, index) => {
      formData.append(`image`, file);
    });

    const extra_param_data = {
        age: productData?.age, 
        subCategory: productData?.subCategory,
        extra_param: {
          children
        } 
    }

    formData.append('extra', JSON.stringify(extra_param_data))

    try {
      // Make the API request using axios
      const response = await AddProductApi(formData);

      // Assuming thumbnail is an array of files
      if (thumbnail && thumbnail.length) {
        for (const image of thumbnail) {
          const imageForm = new FormData();
          imageForm.append('product', response?.data?.pk);
          imageForm.append('name', image?.name);
          imageForm.append('image', image);

          // Make sure to handle or await the response of each image upload if needed
          await AddProductImageApi(imageForm);
        }
      }

      // Log the response from the API
      console.log("Product API Response:", response);

      // Show a success message using sweetalert2
      Swal.fire({
        title: 'Success!',
        text: 'Product has been posted successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          // Redirect to the home page
          window.location.href = '/admin/products'
        }
      });
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error posting data:", error);
      Swal.fire('Error', 'Failed to post the product. Please try again.', 'error');
    } finally {
      setProductData(initialProductData);
      setThumbnail([]);
      setThumbnailUrl("");
    }
  };


  const validationSchema = Yup.object({
    name: Yup.string().required("Product Name is required"),

    gg_number: Yup.string().required("GG Number is required"),

    sku: Yup.string().required("SKU is required"),

    article_no: Yup.string().required("Article Number is required"),

    description: Yup.string().required("Description is required"),

    small_description: Yup.string().required("Small Description is required"),

    // Assuming category and subCategory are selected from a dropdown or similar
    category: Yup.number()
      .required("Category is required")
      .positive("Category must be a positive number")
      .integer("Category must be an integer"),

    subCategory: Yup.number()
      .required("SubCategory is required")
      .positive("SubCategory must be a positive number")
      .integer("SubCategory must be an integer"),

    price_1: Yup.number()
      .required("Price 1 is required")
      .positive("Price 1 must be a positive number"),

    price_3: Yup.number()
      .required("Price 3 is required")
      .positive("Price 3 must be a positive number"),

    price_7: Yup.number()
      .required("Price 7 is required")
      .positive("Price 7 must be a positive number"),

    price_14: Yup.number()
      .required("Price 14 is required")
      .positive("Price 14 must be a positive number"),

    available_stock: Yup.number()
      .required("Available Stock is required")
      .positive("Available Stock must be a positive number")
      .integer("Available Stock must be an integer"),

    total_stock: Yup.number()
      .required("Total Stock is required")
      .positive("Total Stock must be a positive number")
      .integer("Total Stock must be an integer"),

    deposit: Yup.number()
      .required("Deposit is required")
      .positive("Deposit must be a positive number"),

    age: Yup.number()
      .required("Age is required")
      .positive("Age must be a positive number")
      .integer("Age must be an integer"),

    slug: Yup.string()
      .required("Slug is required")
      .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Slug must be in kebab-case"),
  });

  console.log("productData", thumbnail);

  return (
    <>
      <div className="title-container">
        <h5>Add Product</h5>
      </div>

      {/* Order Add Form */}
      <Box sx={{ flecGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Item className="popular-box">
              <form
                className="product-add"
                onSubmit={(e) => handleSubmitProduct(e)}
              >
                <div className="name">Product Details</div>
                <div className="form-group-container">
                  <div className="form-group">
                    <FormControl fullWidth color="primary">
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="upload-btn"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                      />
                      <label htmlFor="upload-btn" className="upload-btn">
                        {thumbnail?.map((file, index) => {
                          // Ensure file is a Blob or File object
                          if (file instanceof Blob) {
                            const thumbnailUrl = URL.createObjectURL(file);
                            return (
                              <img
                                key={index}
                                src={thumbnailUrl}
                                alt={`Thumbnail`}
                              />
                            );
                          }
                          return null;
                        })}
                      </label>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="Product Name"
                        name="name"
                        value={productData?.name}
                        onChange={handleChange}
                        required
                        label="Product Name"
                        color="green"
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="gg_number"
                        value={productData?.gg_number}
                        onChange={handleChange}
                        name="gg_number"
                        required
                        label="GG Number"
                        color="green"
                      />
                    </FormControl>
                  </div>
                  <FormControl fullWidth color="green">
                    <TextField
                      fullWidth
                      id="Sku"
                      name="sku"
                      value={productData?.sku}
                      onChange={handleChange}
                      required
                      label="Sku"
                      color="green"
                    />
                  </FormControl>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="Article Number"
                        name="article_no"
                        required
                        label="Article Number"
                        color="green"
                        value={productData?.article_no}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">

                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        required
                        label="Description"
                        color="green"
                        multiline
                        rows={5}
                        value={productData?.description}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    {/* <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="Small Description"
                        name="small_description"
                        required
                        label="Small Description"
                        color="green"
                        value={productData?.small_description}
                        onChange={handleChange}
                      />
                    </FormControl> */}
                    <Autocomplete
                      fullWidth
                      options={categoryData}
                      getOptionLabel={(option) => option?.label}
                      name="category"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="green"
                          value={productData?.category}
                          name="item"
                          label="Select Skills"
                          variant="outlined"
                        />
                      )}
                      onChange={(e, newValue) => {
                        setProductData((prev) => ({
                          ...prev,
                          category: newValue?.id,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      fullWidth
                      options={subCategoryData}
                      getOptionLabel={(option) => option?.label}
                      name="category"
                      color="green"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          color="green"
                          value={productData?.subCategory}
                          name="item"
                          label="Select Type of Toys"
                          variant="outlined"
                        />
                      )}
                      onChange={(e, newValue) => {
                        setProductData((prev) => ({
                          ...prev,
                          subCategory: newValue?.id,
                        }));
                      }}
                    />

                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="price_1"
                        name="price_1"
                        required
                        label="Price 1"
                        type="number"
                        color="green"
                        value={productData?.price_1}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="price_3"
                        name="price_3"
                        required
                        label="Price 3"
                        type="number"
                        color="green"
                        value={productData?.price_3}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="price_7"
                        name="price_7"
                        required
                        label="Price 7"
                        type="number"
                        color="green"
                        value={productData?.price_7}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="price_14"
                        name="price_14"
                        required
                        label="Price 14"
                        type="number"
                        color="green"
                        value={productData?.price_14}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="available_stock"
                        name="available_stock"
                        required
                        label="Available Stocks"
                        color="green"
                        value={productData?.available_stock}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="total_stock"
                        name="total_stock"
                        required
                        label="Total Stock"
                        color="green"
                        value={productData?.total_stock}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="deposit"
                        name="deposit"
                        required
                        label="deposit"
                        color="green"
                        value={productData?.deposit}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <Autocomplete
                      fullWidth
                      options={ageData}
                      getOptionLabel={(option) => option?.label}
                      name="age"
                      color="green"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="item"
                          label="Select Age"
                          variant="outlined"
                          color="green"
                        />
                      )}
                      onChange={(e, newValue) => {
                        setProductData((prev) => ({
                          ...prev,
                          age: newValue?.id,
                        }));
                      }}
                    />

                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="slug"
                        name="slug"
                        required
                        label="slug"
                        color="green"
                        value={productData?.slug}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </div>

                  <div>
                    {children &&
                      children?.map((child, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "10px 0",
                          }}
                        >
                          <TextField
                            label={`Label ${index + 1}`}
                            value={child.label}
                            fullWidth
                            color="green"
                            onChange={(e) =>
                              handleChildChange(
                                index,
                                "label",
                                e.target.value
                              )
                            }
                          />

                          <TextField
                            label={`Value ${index + 1}`}
                            value={child.value}
                            fullWidth
                            color="green"
                            onChange={(e) =>
                              handleChildChange(
                                index,
                                "value",
                                e.target.value
                              )
                            }
                          />

                        </div>
                      ))}
                    <div className="button yellow" onClick={addChild}>
                      Add Extra Fields
                    </div>
                  </div>
                </div>

                <button type="submit" className="button green my-25">
                  Add Product
                </button>
              </form>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AddProduct;
