import axios from 'axios';
import { BASE_API_URL } from '../../../server'


export const AgeApi = async () => {
    const apiEndpoint = `${BASE_API_URL}api-admin/web/age/list/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const AgeData = response.data
        return AgeData;

    } catch (error) {
        console.log('***** ERROR in Fetching Age *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};
