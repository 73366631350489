import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { BASE_API_URL } from '../../../../server';

import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../web/context/UserContext';



function LogoutButton() {
	const logoutApiUrl = `${BASE_API_URL}account/logout/`;
	const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;

    const [csrfToken, setCsrfToken] = useState();


	const { updateCartcount,updateSuperUser,updatecustomer,UpdateLogin } = useAppContext();
	const navigate = useNavigate();

	const fetchCsrfToken = async () => {
        const abortController = new AbortController();
        try {
            const response = await axios.get(
                csrfTokenApiUrl,
                { withCredentials: true }
            );
            await setCsrfToken(response.data.csrftoken);
            return () => {
                abortController.abort()
            }
        } catch (error) {
            console.error('There was a problem fetching the CSRF token:', error);
        }
    };

	const handleLogout = async () => {
		try {
			const response = await axios.get(
				logoutApiUrl,
				{
					headers: {
						'Accept': 'application/json',
						
						
					},
					withCredentials: true,
				}
			);
			updateSuperUser(false);
			UpdateLogin(false);
			updatecustomer(false);
			updateCartcount(0);
			
			


		} catch (error) {
			console.log(error)
		}
	};
	
	// Calling the fetch CSRF
    useEffect(() => {
        // fetchCsrfToken();
    }, []);

	return(
		<>
			<div className="button green" onClick={handleLogout}>Logout</div>
		</>
	)

}

export default LogoutButton;