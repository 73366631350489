import React from 'react'

function HowitWorks() {
  return (
    <>
            <section className='container case-detail-container'>
                <div class="case-study-detail">
                    <div class="case-study-title-container">
                        <div class="case-study-title">
                            <h1>Direct Plan</h1>
                        </div>
                    </div>
                    <div class="case-study">
                        <div class="section client-problem">
                            <div class="heading">
                                Register
                            </div>
                            Fill out a short form and register yourself on our website
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Make your choices
                            </div>
                            Fill your cart!
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Place order
                            </div>
                            Recheck and confirm your selection.
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Receive Delivery
                            </div>
                            Expect your goodies to arrive at your place as per your schedule.
                        </div>
                    </div>
                </div>
            </section>
            <section className='container case-detail-container'>
                <div class="case-study-detail">
                    <div class="case-study-title-container">
                        <div class="case-study-title">
                            <h1>Subscription Based</h1>
                        </div>
                    </div>
                    <div class="case-study">
                        <div class="section client-problem">
                            <div class="heading">
                                Register
                            </div>
                            Fill out a short form and register yourself on our website
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Select a Plan
                            </div>
                            Check out our plans and choose what suits your needs the best.
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Make your choices
                            </div>
                            Fill your cart!
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Place order
                            </div>
                            Recheck and confirm your selection
                        </div>
                        <div class="section client-problem">
                            <div class="heading">
                            Receive Delivery
                            </div>
                            Expect your goodies to arrive at your place as per your schedule.
                            <br />
                            You can fill the questionnaire in case you would like to receive recommendations from us before you make your choices. Kindly note that this step is completely optional. 
                        </div>

                    </div>
                </div>
            </section>
        </>
  )
}

export default HowitWorks