import React, { useEffect, useState } from 'react'

// API
import { BlogListApi } from '../api/BlogApi';

// CSS
import '../style/pages/blogs/blogs.css';

function Blogs() {

    const [Blog, setBlogList] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [prevUrl, setPrevUrl] = useState(null);

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const data = await BlogListApi();
                setBlogList(data.results);
                console.log(data)
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };
        fetchBlogData();
    }, [2]);

    return (
        <>
            <section className='container case-studies-grid-container'>
                <div class="title">
                    <h2>
                        Our Blogs
                    </h2>
                </div>
                <div className="case-studies-grid">
                    {Blog.map((e) => {
                        return (
                            <div class="case">
                                <div class="content">
                                    <div class="case-img">
                                        <img src={e.thumbnail} alt="" />
                                    </div>
                                    <div class="case-title">
                                        {e.title}
                                    </div>
                                    <div class="subtitle">
                                        {e.sub_title}
                                    </div>
                                    <div class="date">
                                        23-01-2003
                                    </div>
                                    <div class="short-description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, nisi.
                                    </div>
                                    <a href="">
                                        <div class="readmore">
                                            READ MORE
                                        </div>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </>
    )
}

export default Blogs