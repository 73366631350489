import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Components
import { CounterBox } from "../components/DashboardComponents/DashboardComponents";
import {
  CusomterTable,
  SubscriberTable,
} from "../components/Table/TableComponent";

import "../style/components/DashboardComponents/DashboardComponents.css";
import axios from "axios";
import Swal from "sweetalert2";

import { Link, NavLink } from "react-router-dom";

// API
import { AddProfileSubscriptionApi, CustomerApi, EditCustomerApi, ProfileSubscription } from "../api/CustomerApi";
import { DashboardApi } from "../api/DashboardApi";
import { Drawer, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AddOrderApi } from "../api/OrderApi";
import { SubscriptionAPI } from "../../../globalAPIs/SubscriptionApi";

function Subscriber() {
  const [Customer, setCustomer] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]);
  const [searchParam, setsearchParam] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const data = await ProfileSubscription("");
        setCustomer(data.results); // Make sure you also set the fetched data

        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.current_page;
        let pages = data.links.pages;
        let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    })();
  }, []);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;

      setCustomer(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }
      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setCustomer(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch { }
  };

  const handlePage = async (e) => {
    try {
      const data = await axios.get(e, { withCredentials: true });

      setCustomer(data.data.results);
      let currentPage = data.data.current_page;
      let pages = data.data.links.pages;
      let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  const handleSubscriberSearch = async () => {
    const name = "&search=" + searchParam;
    try {
      const data = await ProfileSubscription(name);
      setCustomer(data?.results);
    } catch { }
  };

  useEffect(() => {
    if (searchParam === "") {
      handleSubscriberSearch();
    }
  }, [searchParam]);
  return (
    <>
      {/* Subscriber table*/}
      {Customer && (
        <div className="search" style={{ display: "flex", gap: "10px" }}>
          <TextField
            value={searchParam}
            onChange={(e) => setsearchParam(e.target.value)}
            id="outlined-basic"
            color="green"
            label="Search"
            variant="outlined"
          />
          <button className="green button" onClick={handleSubscriberSearch}>
            Search
          </button>
        </div>
      )}

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Customer ? (
            <>
              <SubscriberTable
                extrasmall={12}
                small={12}
                medium={12}
                cardtitle={"Subscriber"}
                data={Customer}
              ></SubscriberTable>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>
    </>
  );
}

function Customer() {
  const theme = useTheme();
  const style2 = {
    // Default width
    [theme.breakpoints.up("sm")]: {
      width: 300, // Width on small devices and up
    },
    [theme.breakpoints.up("md")]: {
      width: 400, // Width on medium devices and up
    },
    [theme.breakpoints.up("lg")]: {
      width: 500, // Width on large devices and up
    },
    p: 4,
  };

  const [Customer, setCustomer] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]); 
  const [Analytics, setAnalytics] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [searchParam, setsearchParam] = useState("");

  const [retainDrawer, setRetainDrawer] = useState(null)
  const [retainSubscripstion, setRetainSubscripstion] = useState(null)
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs().add(12, 'month'));
  const [pricing, setSubscription] = useState();
  const [token, setToken] = useState();
  const [subPrice, setsubPrice] = useState();
  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const data = await SubscriptionAPI();
        setSubscription(data.results)
        console.log(data)
      } catch (error) {

      }
    }
    fetchSubscription();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await CustomerApi("");
        const analytics = await DashboardApi();
        setCustomer(data.results); // Make sure you also set the fetched data
        setAnalytics(analytics);

        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.current_page;
        let pages = data.links.pages;
        let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    })();
  }, []);

  const handleSearch = async () => {
    const name = "&name=" + searchParam;
    try {
      const data = await CustomerApi(name);

      setCustomer(data?.results);
    } catch { }
  };

  useEffect(() => {
    if (searchParam === "") {
      handleSearch();
    }
  }, [searchParam]);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;

      setCustomer(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }
      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setCustomer(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch { }
  };

  const handlePage = async (e) => {
    try {
      const data = await axios.get(e, { withCredentials: true });

      setCustomer(data.data.results);
      let currentPage = data.data.current_page;
      let pages = data.data.links.pages;
      let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
    // Automatically set "To Date" to be 12 months after "From Date"
    const newToDate = dayjs(newValue).add(12, 'months');
    setToDate(newToDate);
  };

  const handleToDateChange = (newValue) => {
    // You can add additional logic here if needed, 
    // but the key validation happens via shouldDisableDate on the DatePicker
    setToDate(newValue);
  };


  const handleRenewCustomer = async () => {
    const formattedFromDate = fromDate.format('YYYY-MM-DD');
    const formattedToDate = toDate.format('YYYY-MM-DD');
      
    console.log(retainDrawer)
    const subscription_data = {
      user: retainDrawer?.user?.id,
      subscription: retainSubscripstion,
      start_date: formattedFromDate,
      end_date: formattedToDate
    }
    const totaltoken = token+retainDrawer?.gg_token
    const order_data = {
      "user": retainDrawer?.user?.id,
      "email": retainDrawer?.user?.email,
      "school": retainDrawer?.school_name?.id,
      "country": retainDrawer?.country,
      "first_name": retainDrawer?.user?.first_name,
      "last_name": retainDrawer?.user?.last_name,
      "address": retainDrawer?.address_1 ?? retainDrawer?.address_2,
      "city": retainDrawer?.city,
      "pincode": retainDrawer?.postal_code,
      "phone_number": retainDrawer?.mobile_number,
      "total":subPrice,
      "status": 'working'
    }
    const updatedtoken = {
      "gg_token": totaltoken,
    }
    try {
      const updateToken = await EditCustomerApi(updatedtoken,retainDrawer?.id)
      console.log(updateToken)
      const data = await AddProfileSubscriptionApi(subscription_data)
      console.log(data)
      const orderdata = await AddOrderApi(order_data)
      console.log(orderdata)
      
      Swal.fire({
        icon: "success",
        title: `Renew Successfull`,
      }).then(() => {
        setRetainDrawer(false);
      });

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `${error}`,
      });
    }
  }


  console.log("customer Data", retainDrawer);
  return (
    <>
      <div className="title-container">
        <h5>Customer</h5>
        {/* <NavLink to="add-customer">
					<button className='button green'>
						Add Customer
					</button>
				</NavLink> */}
      </div>
      <div
        className="search"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <TextField
          value={searchParam}
          onChange={(e) => setsearchParam(e.target.value)}
          id="outlined-basic"
          color="green"
          label="Search"
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent the default action to avoid form submission if it's part of a form
              handleSearch();
            }
          }}
        />
        <button className="green button" onClick={handleSearch}>
          Search
        </button>
        <Link to={"add-customer"}>
          <button className="button green">Add Customer</button>
        </Link>
      </div>

      {Analytics ? (
        <>
          {/* Counter Container */}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <CounterBox
                extrasmall={12}
                small={6}
                medium={4}
                title={"Active Subscribers"}
                count={Analytics.active_subscriber_count}
              ></CounterBox>
              {/* <CounterBox extrasmall={12} small={6} medium={4} title={'Active Schools'} count={Analytics.active_schools}></CounterBox> */}
            </Grid>
          </Box>
        </>
      ) : (
        <></>
      )}

      {/* Customer table*/}
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Customer ? (
            <>
              <CusomterTable
                extrasmall={12}
                small={12}
                medium={12}
                cardtitle={"Customer"}
                data={Customer}
                setRetainDrawer={setRetainDrawer}
              ></CusomterTable>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>

      <Drawer
        anchor="right"
        open={retainDrawer}
        PaperProps={{
          sx: { style2 },
        }}
        onClose={() => setRetainDrawer(null)}
      >
        <Box sx={style2} role="presentation">

          <div className="form-group">
            <FormControl fullWidth>
              <InputLabel id="select-subscription-label" color="green">
                Select Subscription
              </InputLabel>
              <Select
                label="Select Subscription"
                labelId="select-subscription-label"
                id="select-subscription"
                color="green"
                value={retainSubscripstion}
                onChange={(e) => {
                  const selectedPriceId = e.target.value;
                  const selectedPrice = pricing.find(price => price.id === selectedPriceId);
                  if (selectedPrice) {
                    setsubPrice(selectedPrice.subscrption_fees)
                    setRetainSubscripstion(selectedPriceId); // Set the selected user value
                    setToken(selectedPrice.total_gg_token); // Set the token value
                  }
                }}
              >
                {pricing?.map((price, i) => (
                  <MenuItem key={price?.id} value={price?.id}>{price?.name}</MenuItem>
                ))}

              </Select>
            </FormControl>

          </div>

          <div className="form-group mt-20">
            <LocalizationProvider dateAdapter={AdapterDayjs} color="green">
              <DatePicker
                label="From Date"
                value={fromDate}
                color="green"
                fullWidth
                onChange={handleFromDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Date"
                    variant="outlined"
                    color="green"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { color: "green" }, // Change the input text color to green
                    }}
                  />
                )}
                // Use format to change the displayed date format
                format="YYYY-MM-DD"
              />

              <DatePicker
                label="To Date"
                color="green"
                value={toDate}
                className="mt-20"
                onChange={handleToDateChange}
                renderInput={(params) => <TextField {...params} />}
                format="YYYY-MM-DD"
                // Disable dates that are not exactly 12 months after the From Date
                shouldDisableDate={(date) =>
                  !dayjs(date).isSame(dayjs(fromDate).add(12, 'month'), "day")
                }
              />

            </LocalizationProvider>

          </div>
          <button className="button green my-25" onClick={handleRenewCustomer}>Submit</button>
        </Box>
      </Drawer>

      {/* Subscriber table*/}
      <Subscriber></Subscriber>
    </>
  );
}

export default Customer;
