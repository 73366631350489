import React from 'react'

function BlogsDetail() {
	return (
		<>
			<section className='container case-detail-container'>
				<div class="case-study-detail">
					<div class="case-study-banner">
						<img src="" alt=""/>
					</div>
					<div class="case-study-title-container">
						<div class="case-study-title">
							<h1>this is title</h1>
							<div class="subtitle">
								<h2>This is subheading</h2>
							</div>
							<div class="tag-container">
								<div class="tag">Tag 1</div>
								<div class="tag">Tag 2</div>
								<div class="tag">Tag 3</div>
							</div>
						</div>
						<div class="date">
							12-01-2023
						</div>
					</div>
					<div class="case-study">

						<div class="section client-problem">
							<div class="heading">
								The Client Problem:
							</div>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime voluptatibus accusantium mollitia commodi quam assumenda, modi nobis sapiente consectetur blanditiis. Esse repellat odio quas natus quos perspiciatis libero nulla reprehenderit atque, corporis deleniti vero fugit distinctio mollitia quod in? Unde.
						</div>
						<div class="section team-solution">
							<div class="heading">
								Our Team’s Solution:
							</div>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum sunt beatae, omnis nulla quisquam blanditiis saepe reiciendis et! Perspiciatis rem corporis architecto tempore. Nemo commodi, error inventore quibusdam atque voluptatibus.
						</div>
						
					</div>
				</div>
			</section>
		</>
	)
}

export default BlogsDetail