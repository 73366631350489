import React, { useState, useEffect } from 'react'

// Form Components
import { FormControl, OutlinedInput, InputAdornment, FormControlLabel, Checkbox, Collapse } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_API_URL } from '../../server';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Collapsible from 'react-collapsible';


// API
import { ToyCategoryAPI, ToySubCategoryAPI } from '../../globalAPIs/ToyCategoryApi';
import { AgeAPI } from '../../globalAPIs/AgeApi';


function Filter({ onFilterSubmit, onReset }) {

    const [ageOpen, setAgeOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);

    const [ToyCategoryData, setProductCategories] = useState([]);
    const [ToySubCategoryData, setProductSubCategories] = useState([]);
    const [AgeData, setAge] = useState([])

    const [checkboxValues, setCheckboxValues] = useState({
        age: [],
        category: [],
        subcategory: [],
    });

    useEffect(() => {
        const fetchAgeData = async () => {
            try {
                const data = await AgeAPI();
                setAge(data.results)
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };
        const fetchToyCategory = async () => {
            try {
                const data = await ToyCategoryAPI();
                setProductCategories(data.results)
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };
        const fetchToySubCategory = async () => {
            try {
                const data = await ToySubCategoryAPI();
                setProductSubCategories(data)
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };
        fetchAgeData();
        fetchToyCategory();
        fetchToySubCategory();
    }, []);
    // Filter
    const formik = useFormik({
        initialValues: {
            search: '',
            age: [],
            category: [],
            subcategory: [],
            productType: false,
            days: 'seven'
        },
        validationSchema: Yup.object({
            // Add your validation here if necessary
        }),
        onSubmit: values => {
            let baseURL = `${BASE_API_URL}api/product/`;
            let days = values.days
            let queryParams = [];

            if (values.search) {
                queryParams.push(`name=${values.search}`);
            }

            if (values.age && values.age.length > 0) {
                queryParams.push(`age=${values.age.join("&")}`);
            }

            if (values.category && values.category.length > 0) {
                queryParams.push(`category=${values.category.join("&category=")}`);
            }

            if (values.subcategory && values.subcategory.length > 0) {
                queryParams.push(`sub_category=${values.subcategory.join("&sub_category=")}`);
            }

            // Assuming productType is a boolean value representing if "Board Games" is selected or not
            if (values.productType) {
                queryParams.push("sub_category=Board Games");
            }

            

            if (queryParams.length > 0) {
                baseURL = `${baseURL}?${queryParams.join("&")}`;
            }
            onFilterSubmit(baseURL,days)

        }
    });
    const [fullList, setFullList] = useState(false);

    const reset = async () => {
        document.getElementById("filter-form").reset();
        let baseURL = `${BASE_API_URL}api/product/`
        onReset(baseURL)
    }


    const updateCheckboxValues = (name, id, checked) => {
        setCheckboxValues(prevValues => ({
            ...prevValues,
            [name]: checked
                ? [...prevValues[name], id]
                : prevValues[name].filter(item => item !== id),
        }));
    };
    const toggleAge = () => {
        setAgeOpen(!ageOpen);
    };

    const toggleCategory = () => {
        setCategoryOpen(!categoryOpen);
    };

    const toggleSubCategory = () => {
        setSubCategoryOpen(!subCategoryOpen);
    };
    return (
        <>

            <div className="filter-container">
                <div className="title-container">
                    <div className="title">Filter</div>
                    <div className="reset" onClick={reset}>Reset Filters</div>
                </div>
                <div className="filter-content">
                    <form onSubmit={formik.handleSubmit} id='filter-form'>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                name="search"
                                value={formik.values.search}
                                onChange={formik.handleChange}
                                color='green'
                                endAdornment={
                                    <InputAdornment position="end" onClick={formik.handleSubmit}>
                                        <img src="/images/search.svg" alt="" />
                                    </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                            />
                        </FormControl>

                        <div className="subtitle" onClick={toggleAge}>
                            Age 
                            <span>
                            {ageOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </span>
                        </div>
                        <Collapse in={ageOpen}>
                            <div className="form-content">
                            {AgeData.map(({ label, id, index }) => (

                                <FormControlLabel
                                    key={id}
                                    control={
                                        <Checkbox
                                            name="age"
                                            value={id}
                                            key={id}
                                            checked={formik.values.age.includes(id)}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    formik.setFieldValue("age", [...formik.values.age, id]);
                                                } else {
                                                    formik.setFieldValue("age", formik.values.age.filter(item => item !== id));
                                                }
                                            }}
                                            color="green"
                                        />
                                    }
                                    label={label}
                                />
                            ))}
                            </div>
                        </Collapse>
                                
                        
                        <div className="subtitle" onClick={toggleCategory}>
                            Skills 
                            <span>
                            {categoryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </span>
                        </div>

                        <Collapse in={categoryOpen}>
                        <div className="form-content">
                            {ToyCategoryData.map(({ label, id }) => (
                                <FormControlLabel
                                    key={id}
                                    control={
                                        <Checkbox
                                            name="category"
                                            key={id}
                                            value={id}
                                            checked={formik.values.category.includes(id)}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    formik.setFieldValue("category", [...formik.values.category, id]);
                                                } else {
                                                    formik.setFieldValue("category", formik.values.category.filter(item => item !== id));
                                                }
                                            }}
                                            color="green"
                                        />
                                    }
                                    label={label}
                                />
                            ))}
                        </div>
                        </Collapse>

                        <div className="subtitle" onClick={toggleSubCategory}>
                            Types of Toys
                            <span>
                            {subCategoryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </span>
                        </div>
                        <Collapse in={subCategoryOpen}>
                        <div className="form-content">
                            {ToySubCategoryData.map(({ label, id }) => (

                                <FormControlLabel
                                    key={id}
                                    control={
                                        <Checkbox
                                            name="subcategory"
                                            key={id}
                                            value={id}
                                            checked={formik.values.subcategory.includes(id)}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    formik.setFieldValue("subcategory", [...formik.values.subcategory, id]);
                                                } else {
                                                    formik.setFieldValue("subcategory", formik.values.subcategory.filter(item => item !== id));
                                                }
                                            }}
                                            color="green"
                                        />
                                    }
                                    label={label}
                                />
                            ))}
                        </div>  
                        </Collapse>
                        <div className="subtitle" onClick={toggleAge}>
                             Days
                        </div>
                        <div className="form-content">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                                formik.setFieldValue("days", e.target.value);
                            }}
                        >
                            <FormControlLabel value="one" color='green' control={<Radio color='green' />} label="1 Day" />
                            <FormControlLabel value="three" color='green' control={<Radio color='green' />} label="3 Days" />
                            <FormControlLabel value="seven" color='green' control={<Radio color='green' />} label="7 Days" />
                            <FormControlLabel value="fourteen" color='green' control={<Radio color='green' />} label="14 Days" />
                           
                        </RadioGroup>
                        </div> 
                        <div className="">
                            <input type="submit" value="Apply Filters" />
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}


export default Filter