
const data= [
    {
        key:"1",
        plan:"Basic Plan",
        icon:"/images/monkey.png",
        description:'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
        price:'3,299',
        duration:'monthly',
        tokens:'4200',
        exchanges:'2',
        maxpoints:'500',
        deposit:'3,500'
    },
    {   
        key:"2",
        plan:"Pro  Plan",
        icon:"/images/lion.png",
        description:'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
        price:'6,399',
        duration:'quaterly',
        tokens:'4200',
        exchanges:'2',
        maxpoints:'500',
        deposit:'3,500'
    },
    {
        key:"3",
        plan:"Pro  Plan",
        icon:"/images/bear.png",
        description:'Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. ',
        price:'6,399',
        duration:'quaterly',
        tokens:'4200',
        exchanges:'2',
        maxpoints:'500',
        deposit:'3,500'
    },
    
]

export default data;

