import axios from 'axios';
import { BASE_API_URL } from '../../server'

const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf


const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
        console.error('There was a problem fetching the CSRF token:', error);
    }
};

export const RazorpayVerification = async (order_id,rpayId,razorpay_signature) => {
    const apiEndpoint = `${BASE_API_URL}api/payment-confirmation/${order_id}/`;
    // await fetchCsrfToken()
    try {
        const response      = await axios.post(apiEndpoint,
        {
            razorpay_payment_id:rpayId,
            razorpay_signature:razorpay_signature,
        },
        { 
            headers: {
                'Accept': 'application/json',
                
                
            },
            withCredentials: true });
        const verfication       = response.data
        return verfication;

    } catch (error) {
        console.log('***** ERROR in Payment Verification *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};