import React, { useEffect, useState } from 'react'
import {RazorpayVerification} from '../api/RazorPayVerification'
import { useAppContext } from '../context/UserContext';
import { BASE_API_URL } from '../../server';

export default function RazorPayWidget(props) {

  
  const makePayment = () => {
    console.log('check outtttttttttt', props)
    let options = {
      "key": "rzp_live_fGgSauLoNE7biG", 
      "amount": props.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "order_id":props.rpay_order_id,
      "name": "Growing Giraffe",
      "description": "Test Transaction",
      "image": `${BASE_API_URL}media/image_master/logo.png`,
      "handler": function (response) {
        try {
          console.log(response)
          const data = RazorpayVerification(props.id,response.razorpay_payment_id,response.razorpay_signature)
          console.log(data)
          window.location = "/toy-store";

         
        } catch (error) {
          window.location.reload();

        }

      },
      "modal": {
        "ondismiss": function() {
            // Logic to refresh the modal
            window.location.reload();
        }
    },
      "prefill": {
        "name": props.first_name,
        "email": props.email,
        "contact": props.phone_number
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#4B9E99"
      }
    };

    let rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  useEffect(() => {
    makePayment()
  },[]);

  return (
    null
  );
}