import axios from "axios";
import { BASE_API_URL } from "../../../server";

export const CustomerInfoApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api/profile/`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const data = response.data;
    return data;
  } catch (error) {
    console.log("***** ERROR in AgeAPI Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const CustomerQuestionsApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api/api/milestone-questions/`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const data = response.data;
    return data;
  } catch (error) {
    console.log("***** ERROR in AgeAPI Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};
