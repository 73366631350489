import axios from "axios";
import { BASE_API_URL } from "../../../server";
import dayjs from "dayjs";

export const OrderApi = async (delivery_date) => {
  const apiEndpoint =
    `${BASE_API_URL}api-admin/web/customertoymapping/list/?` + delivery_date;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const OrderList = response;
    return OrderList;
  } catch (error) {
    console.log("***** ERROR in Fetching Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const DispatchApi = async (url) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/dispatch/list/` + url;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const DispatchList = response;
    return DispatchList;
  } catch (error) {
    console.log("***** ERROR in Fetching Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const PickuphApi = async (url) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/pickup/list/` + url;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const DispatchList = response;
    return DispatchList;
  } catch (error) {
    console.log("***** ERROR in Fetching Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AllPickupApi = async (url) => {
  const apiEndpoint =
    `${BASE_API_URL}api-admin/web/pickup/list/?` + url;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const DispatchList = response;
    return DispatchList;
  } catch (error) {
    console.log("***** ERROR in Fetching Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const PickupEditApi = async (id, days, date) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/pickup/${id}/change/`;
  const pickupdate = dayjs(date).add(days, "day");
  const adjustedDate = dayjs(pickupdate).add(5, "hour").add(30, "minute");

  try {
    const response = await axios.put(
      apiEndpoint,
      {
        date_of_pickup: adjustedDate.toISOString().split("T")[0],
      },
      { withCredentials: true }
    );
    const PickupEdit = response;
    return PickupEdit;
  } catch (error) {
    console.log("***** ERROR in Editing the Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const GGTokenApi = async (id, token) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profile/${id}/change/`;
  try {
    const response = await axios.put(
      apiEndpoint,
      {
        gg_token: token,
      },
      { withCredentials: true }
    );
    const PickupEdit = response;
    return PickupEdit;
  } catch (error) {
    console.log("***** ERROR in Editing the Order *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AddOrderApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/order/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        'Accept': "application/json",
        'Bypass-Subscription-Check': "true"
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};
