import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Components
import { DoughnutOrderChart } from "../components/DashboardComponents/DashboardComponents";
import {
  OrderTable,
  DispatchSummary,
  PickUpSummary,
} from "../components/Table/TableComponent";
import Pagination from "../components/Pagination/Pagination";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";

// API
import { OrderApi, DispatchApi } from "../api/OrderApi";
import { ConstructionOutlined } from "@mui/icons-material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

function Dispatch() {
  const [Order, setOrder] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const formattedFromDate = fromDate ? fromDate.format("YYYY-MM-DD") : "";
  const formattedToDate = toDate ? toDate?.format("YYYY-MM-DD") : "";

  const [allDispatchData, setallDispatchData] = useState(null);
  const [isLoading, setisLoading] = useState(false)
  const tableRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const url =
          "?status=&date_of_delivery_from=" +
          formattedFromDate +
          "&date_of_delivery_to=" +
          formattedToDate +
          "&station=";
        const data = await DispatchApi(url);
        let next;
        let prev;
        setOrder(data.data.results); // Make sure you also set the fetched data
        try {
          next = data.data.links.next.replace("http:", "https:");
        } catch {
          next = data.data.links.next;
        }
        try {
          prev = data.data.links.previous.replace("http:", "https:");
        } catch {
          prev = data.data.links.previous;
        }
        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {}
    })();
  }, [formattedToDate]);

  useEffect(() => {
    const fetchAllDispatch = async () => {
      setisLoading(true)
      const url =
        "?status=&date_of_delivery_from=" +
        formattedFromDate +
        "&date_of_delivery_to=" +
        formattedToDate +
        "&station=" +
        "&page_size=10000";
      try {
        const res = await DispatchApi(url);
        setallDispatchData(res?.data);
        setisLoading(false)
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllDispatch();
  }, [formattedToDate]);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 4; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {}
  };
  const handlePage = async (e) => {
    try {
      const response = await axios.get(e, { withCredentials: true });
      const data = response.data;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
    // Reset the toDate if it's before the new fromDate
    if (toDate && dayjs(newValue).isAfter(dayjs(toDate))) {
      setToDate(null);
    }
  };

  const handleToDateChange = (newValue) => {
    setToDate(newValue);
  };

  console.log("dateRanges", fromDate, toDate);

  return (
    <>
      <div className="title-container ">
        <h5>Dispatch</h5>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {(!isLoading && allDispatchData) ? (
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className="button yellow">Export Excel</button>
            </DownloadTableExcel>
          ) : <CircularProgress/>}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={fromDate}
              onChange={handleFromDateChange}
              renderInput={(params) => (
                <TextField
                  label="From Date"
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: { color: "green" }, // Change the input text color to green
                  }}
                />
              )}
              // Use format to change the displayed date format
              format="YYYY-MM-DD"
            />

            <DatePicker
              label="To Date"
              value={toDate}
              onChange={handleToDateChange}
              renderInput={(params) => <TextField {...params} />}
              format="YYYY-MM-DD"
              // Disable dates before the fromDate
              shouldDisableDate={(date) =>
                dayjs(date).isBefore(dayjs(fromDate), "day")
              }
            />
          </LocalizationProvider>
        </div>
      </div>

      {/* Order Statistic */}
      {/* <Box sx={{ flexGrow: 1, mt: 2 }}>

				<Grid container spacing={2}>
					<DoughnutOrderChart
					data={Orderdata}
					>

					</DoughnutOrderChart>
				</Grid>
			</Box> */}
      {/* Order Statistic */}

      {/* Order Table */}

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Order ? (
            <>
              <DispatchSummary
                extrasmall={12}
                small={12}
                medium={12}
                color={"green "}
                text={"Dispatch Table"}
                cardtitle={"Dispatch Table"}
                data={Order}
              ></DispatchSummary>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>

      {/* //All Dispactch Data Table for Excel */}
      <div
        className="table-container"
        style={{ display: "none" }}
        ref={tableRef}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Order ID",
                "Customer Name",
                "Mobile Number",
                "Toy Name",
                "Dispatch Date",
                "Order Status",
                "Name of Station",
                "Delivery Address",
                "Refundable Deposit",
                "Number of Pieces",
              ].map((headCell, index) => (
                <TableCell key={index}>{headCell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allDispatchData?.results?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.customer_toy_mapping.id}</TableCell>
                {item?.customer_toy_mapping?.user?.first_name ? (
                  <>
                    <TableCell>
                      {item?.customer_toy_mapping.user?.first_name +
                        " " +
                        item?.customer_toy_mapping?.user?.last_name}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      {item?.customer_toy_mapping?.profile?.name}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {item?.customer_toy_mapping?.profile?.mobile_number}
                </TableCell>
                <TableCell>
                  {item?.product?.name + "-" + item?.product?.article_no}
                </TableCell>
                <TableCell>{item.date_of_delivery}</TableCell>
                <TableCell>{item?.customer_toy_mapping?.status}</TableCell>
                <TableCell>{item.station}</TableCell>
                <TableCell>
                  {item.customer_toy_mapping.delivery_address}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {/* Order Table */}

      {/* Pickup Summary */}
      {/* <Box sx={{ flexGrow: 1, mt: 2 }}>
				<Grid container spacing={2}>
					<PickUpSummary
						extrasmall={12} small={12} medium={12}
						cardtitle={'Pickup Summary'}
					>

					</PickUpSummary>
				</Grid>
			</Box> */}
      {/* Pickup Summary */}
    </>
  );
}

export default Dispatch;
