import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


// Components
import { CounterBox } from '../../admin/components/DashboardComponents/DashboardComponents';
import { CustomerPastSubscription } from '../../admin/components/Table/TableComponent';
import '../../admin/style/components/DashboardComponents/DashboardComponents.css'

// API
import { CustomerInfoApi } from '../api/CustomerInfoApi';
import { SubscriptionInfoApi } from '../api/SubscriptionInfo';


function CustomerWallet() {
	const [subscriptionInfo, setsubscriptionInfo] = useState([])
	const [subscriptionList, setsubscriptionList] = useState([])
	const [customerInfo, setCustomerInfo] = useState()

	useEffect(() => {
		const fetchCustomer = async () => {
			try {
				const data = await CustomerInfoApi();
				setCustomerInfo(data.gg_token); // Make sure you also set the fetched data
			} catch (error) {
			}
		}
		const fetchSubscription = async () => {
			try {
				const data = await SubscriptionInfoApi();
				const result = data.results.map(item => ({
					id: item.subscription.id,
					order_by: item.subscription.order_by,
					created_at: item.subscription.created_at,
					updated_at: item.subscription.updated_at,
					image: item.subscription.image,
					name: item.subscription.name,
					description: item.subscription.description,
					price: item.subscription.price,
					total_gg_token: item.subscription.total_gg_token,
					exchange_month: item.subscription.exchange_month,
					max_point_per_cycle: item.subscription.max_point_per_cycle,
					refundable_deposit: item.subscription.refundable_deposit,
					total_month: item.subscription.total_month,
					is_active: item.subscription.is_active,
					start_date: item.subscription_from,
					end_date: item.subscription_to,
					is_deposit_refunded: item.is_deposit_refunded,
					deposit_refund_amount: item.deposit_refund_amount,
				}
				))
				setsubscriptionInfo(result[0]); // Make sure you also set the fetched data
			} catch (error) {
			}
		}
		const fetchSubscriptionList = async () => {
			try {
				const data = await SubscriptionInfoApi();
				const result = data.results.map(item => ({
					id: item.id,
					order_by: item.order_by,
					created_at: item.created_at,
					updated_at: item.updated_at,
					image: item.image,
					name: item.name,
					description: item.description,
					price: item.price,
					total_gg_token: item.total_gg_token,
					exchange_month: item.exchange_month,
					max_point_per_cycle: item.max_point_per_cycle,
					refundable_deposit: item.refundable_deposit,
					total_month: item.total_month,
					is_active: item.is_active,
					start_date: item.subscription_from,
					end_date: item.subscription_to,
					is_deposit_refunded: item.is_deposit_refunded,
					deposit_refund_amount: item.deposit_refund_amount,
				}
				))
				setsubscriptionList(result); // Make sure you also set the fetched data
			} catch (error) {
			}
		}

		fetchCustomer();
		fetchSubscription();
		fetchSubscriptionList();
	}, []);

	return (
		<>
			<div className="title-container">
				<h5>My Wallet</h5>
			</div>
			<Box sx={{ flexGrow: 1, mt: 2 }}>
				<Grid container spacing={2}>
						{customerInfo?(<>
						<CounterBox extrasmall={12} small={6} imageUrl={'/images/ggtoken.svg'} medium={4} title={'My Wallet'} count={customerInfo}></CounterBox>
						</>):(<></>)}
						{subscriptionInfo ? (<>
							<CounterBox extrasmall={12} small={6} imageUrl={'/images/monkey.png'} medium={4} title={'Active Plan'} start_date={subscriptionInfo.start_date} end_date={subscriptionInfo.end_date} activeplan={subscriptionInfo.name}></CounterBox>
						</>):(<>
							<CounterBox extrasmall={12} small={6} imageUrl={'/images/no-active-plan.png'} medium={4} title={'No Active Plan'}></CounterBox>
						</>)}

				</Grid>
			</Box>
			<Box sx={{ flexGrow: 1, mt: 2 }}>
				<Grid container spacing={2}>
					{subscriptionList ? (<>
						<CustomerPastSubscription
								extrasmall={12} small={12} medium={12} color={'green '} text={'Order Table'}
								cardtitle={'Past Subscriptions'}
								data={subscriptionList}
							>

						</CustomerPastSubscription>
					</>) : (<>

					</>)}
				</Grid>
			</Box>
		</>
	)
}

export default CustomerWallet