import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import { Modal } from "@mui/material";

// Components
import Pagination from "../../admin/components/Pagination/Pagination";
import {
  CustomerOrderTable,
  ActiveCustomerOrderTable,
} from "../../admin/components/Table/TableComponent";
import { CustomerOrderApi } from "../api/CustomerOrder";
import RetainCard from "../components/RetainCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  outline: "none",
};

function ActiveCustomerOrder() {
  const [Order, setOrder] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [pages, setPages] = useState([]);
  const [hasRetainedToday, setHasRetainedToday] = useState(false);

  const [product, setProduct] = useState();

  useEffect(() => {
    (async () => {
      try {
        const data = await CustomerOrderApi();
        let next;
        let prev;
        console.log(data.data.results);
        setOrder(data.data.results); // Make sure you also set the fetched data
        try {
          next = data.data.links.next.replace("http:", "https:");
        } catch {
          next = data.data.links.next;
        }
        try {
          prev = data.data.links.previous.replace("http:", "https:");
        } catch {
          prev = data.data.links.previous;
        }
        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {}
    })();
  }, [2]);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;
      console.log("", data);
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 4; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {}
  };
  const handlePage = async (e) => {
    try {
      const response = await axios.get(e, { withCredentials: true });
      const data = response.data;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };
  const handleOpen = (item) => {
    console.log(item);
    setProduct(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Order Table */}
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Order ? (
            <>
              <ActiveCustomerOrderTable
                extrasmall={12}
                small={12}
                medium={12}
                color={"green "}
                text={"Order Table"}
                cardtitle={"Active Order Table"}
                data={Order}
                handleOpen={handleOpen}
                hasRetainedToday={hasRetainedToday}
                setHasRetainedToday={setHasRetainedToday}
              ></ActiveCustomerOrderTable>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>
      {/* Order Table */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          {product ? (
            <>
              <>
                <RetainCard
                  img={product?.item?.image}
                  name={product?.item?.name}
                  price={product?.item?.price}
                  price_1={product?.item?.price_1}
                  price_3={product?.item?.price_3}
                  price_7={product?.item?.price_7}
                  price_14={product?.item?.price_14}
                  key={product?.item?.id}
                  product_id={product?.item?.id}
                  days={1}
                  date={product?.end_date}
                  id={product?.id}
                  onClose={handleClose}
                  setHasRetainedToday={setHasRetainedToday}
                />
              </>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
}

function CustomerOrder() {
  const [Order, setOrder] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await CustomerOrderApi();
        let next;
        let prev;
        console.log(data.data.results);
        setOrder(data.data.results); // Make sure you also set the fetched data
        try {
          next = data.data.links.next.replace("http:", "https:");
        } catch {
          next = data.data.links.next;
        }
        try {
          prev = data.data.links.previous.replace("http:", "https:");
        } catch {
          prev = data.data.links.previous;
        }
        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) {}
    })();
  }, [2]);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;
      console.log("", data);
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 4; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {}
  };
  const handlePage = async (e) => {
    try {
      const response = await axios.get(e, { withCredentials: true });
      const data = response.data;
      setOrder(data.results);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  return (
    <>
      <div className="title-container">
        <h5>My Orders</h5>
      </div>

      {/* Order Table */}
      <ActiveCustomerOrder />
      {/* Order Table */}
      {/* Order Table */}
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Order ? (
            <>
              <CustomerOrderTable
                extrasmall={12}
                small={12}
                medium={12}
                color={"green "}
                text={"Order Table"}
                cardtitle={"Order Table"}
                data={Order}
              ></CustomerOrderTable>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>
      {/* Order Table */}
    </>
  );
}

export default CustomerOrder;
