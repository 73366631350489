import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

// Components
import { CounterBox, HotCategories, MostPopular, OrderChart } from '../components/DashboardComponents/DashboardComponents';
import { WishListedToys, UpcomingRenewals } from '../components/Table/TableComponent';
import '../style/components/DashboardComponents/DashboardComponents.css'

// API
// import { ProductDataa } from '../api/ProductApi';
import { DashboardApi } from '../api/DashboardApi';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// import HotCategoriesData from '../../../web/api/HotCategoriesData';




// CounterBOX
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
	...theme.typography.body2,
	padding: "20px",
	boxShadow: "none",
	borderRadius: "10px",
	border: "1px solid #E7E7E7",
	color: "black",
	height: "100%",
}));



function Dashboard() {
	const [fromDate, setFromDate] = useState(dayjs());
	const [toDate, setToDate] = useState(dayjs());
	const formattedFromDate = fromDate ? fromDate.format("YYYY-MM-DD") : "";
	const formattedToDate = toDate ? toDate?.format("YYYY-MM-DD") : "";
  
	// var product = getMax(ProductDataa, "number_of_days_booked");
	const [Analytics, setAnalytics] = useState(null);
	const [Comingsoon, setCommingSoon] = useState(true)
	useEffect(() => {
		(async () => {
			try {
				const url =
				"?from_date=" +
				formattedFromDate +
				"&to_date=" +
				formattedToDate ;

				const data = await DashboardApi(url);
				setAnalytics(data); // Make sure you also set the fetched data
			} catch (error) {

			}
		})();
	}, [formattedFromDate,formattedToDate]);

	console.log('Analytics', Analytics)
	const handleFromDateChange = (newValue) => {
		setFromDate(newValue);
		// Reset the toDate if it's before the new fromDate
		if (toDate && dayjs(newValue).isAfter(dayjs(toDate))) {
		  setToDate(null);
		}
	};
	
	const handleToDateChange = (newValue) => {
		setToDate(newValue);
	};
	return (
		<>
			<div className="title-container " style={{marginBottom:"40px",display:'flex',justifyContent:"space-between"}}>
				<h5>Dashboard</h5>
				<div style={{display:'flex',justifyContent:"flex-end",gap:"10px"}}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
							value={fromDate}
							onChange={handleFromDateChange}
							renderInput={(params) => (
								<TextField
								label="From Date"
								{...params}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									style: { color: "green" }, // Change the input text color to green
								}}
								/>
							)}
							// Use format to change the displayed date format
							format="YYYY-MM-DD"
							/>

							<DatePicker
							label="To Date"
							value={toDate}
							onChange={handleToDateChange}
							renderInput={(params) => <TextField {...params} />}
							format="YYYY-MM-DD"
							// Disable dates before the fromDate
							shouldDisableDate={(date) =>
								dayjs(date).isBefore(dayjs(fromDate), "day")
							}
							/>
					</LocalizationProvider>
				</div>

			</div>
			{Analytics ? (
				<>
					{/* Counter Container */}
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<CounterBox extrasmall={12} small={6} medium={4} title={'Active Subscribers'} count={Analytics.active_subscriber_count}></CounterBox>
							<CounterBox
								extrasmall={12}
								small={6}
								medium={4}
								title={'Revenue via Subscription'}
								count={'₹' + (Analytics?.total_revenue -Analytics?.total_topup_revenue- Analytics?.total_deposit?.deposit__sum)}>
							</CounterBox>
							<CounterBox
								extrasmall={12}
								small={6}
								medium={4}
								title={'Revenue via Topup'}
								count={'₹' + (Analytics?.total_topup_revenue)}>
							</CounterBox>
							<CounterBox extrasmall={12} small={6} medium={4} title={'Active Schools'} count={Analytics.active_schools}></CounterBox>
						</Grid>
					</Box>
					{/* Counter Container */}

					{/* Order Chart & Most Popular Product */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>

						<Grid container spacing={2}>
							<MostPopular
								cardtitle={'Most Popular Product'}
								extrasmall={12} small={12} medium={12} color={'green '} text={'View Utilisation Report'}
								name={Analytics.popular_product.name}
								product_img={Analytics.popular_product.image}
								days={Analytics.popular_product.total_wishlist}
								revenue={Analytics.popular_product.revenue_generated}
								inventory={Analytics.popular_product.total_stock}

							>
							</MostPopular>

							{/* <Grid item xs={12} sm={6} md={6}>
								<Item className="popular-box">
									<div className="card-title-container">
										<div className="card-title">Order</div>
									</div>
									<div className="chart">
										<OrderChart data={Analytics.order_barchart_data} />
									</div>
								</Item>
							</Grid> */}
						</Grid>
					</Box>
					{/* Order Chart & Most Popular Product */}

					{/* Wish Listed Product & Hot Categories */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Grid container spacing={2}>
							{/* <WishListedToys
								extrasmall={12} small={12} medium={8}
								cardtitle={'WishListed Toys'}
								data={Analytics.top_5_wishlist}
							>


							</WishListedToys> */}

							<HotCategories
								extrasmall={12}
								small={12}
								medium={4}
								data={Analytics.popular_product.total_stock}
								comingsoon={Comingsoon}
							/>


						</Grid>

					</Box>
					{/* Wish Listed Product & Hot Categories */}

					{/* Upcoming Renewals Table */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Grid container spacing={2}>

							<UpcomingRenewals
								extrasmall={12} small={12} medium={12}
								cardtitle={'Upcoming Renewals'}
								data={Analytics.upcoming_renewals}
								comingsoon={Comingsoon}
							></UpcomingRenewals>

						</Grid>
					</Box>
					{/* Upcoming Renewals Table */}
				</>
			) : (
				<></>
			)
			}


		</>
	)
}

export default Dashboard



