import { BASE_API_URL } from '../../../server'
import axios from 'axios';


const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf

const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
        console.error('There was a problem fetching the CSRF token:', error);
    }
};

export const CustomerWishlistApi = async (product) => {
    const apiEndpoint = `${BASE_API_URL}api/my-wishlist/`;
    // await fetchCsrfToken();
    try {
        const response = await axios.get(apiEndpoint, 
            {
            headers: {
                'Accept': 'application/json',
            },
            withCredentials: true,
        });

        return response; // This will return the response from the server

    } catch (error) {
        console.log('***** ERROR in Deleting from Cart *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};
