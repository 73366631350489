import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Modal,
  Grid,
  Paper,
  styled,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import { CustomerInfoApi } from "../api/CustomerInfoApi";
import axios from "axios";
import { BASE_API_URL } from "../../../server";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  outline: "none",
};

function CustomerMilestone() {
  const [open, setOpen] = React.useState(false);
  const [editModal, seteditModal] = useState(false);
  const [childlist, setChildList] = useState(true);
  const [customerInfo, setCustomerInfo] = useState();
  const [selectedCustomer, setselectedCustomer] = useState();

  useEffect(() => {
    const fetchchild = async () => {
      try {
        const data = await CustomerInfoApi();
        console.log(data);
        setCustomerInfo(data); // Make sure you also set the fetched data
      } catch (error) {
        setCustomerInfo(0);
      }
    };
    fetchchild();
  }, []);

  const handleOpen = (item) => {
    console.log(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      child_name: "",
      age: "",
      id: null,
      milestone: null,
      // ... other form fields
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      child_name: Yup.string().required("Required"),
      age: Yup.string().required("Required"),
      // ... other validations
    }),
    onSubmit: async (values) => {
      // await fetchCsrfToken()
      try {
        const id = uuidv4(); // generate a new UUID
        values.id = id; // add the UUID to the form values
        console.log("milestone Valyuess", values, id);

        // Retrieve existing milestone array from customerInfo or initialize as an empty array if it doesn't exist yet
        const existingMilestone = customerInfo?.milestone?.child || [];

        // Append the new child to the existing milestone array
        const updatedMilestone = [...existingMilestone, values];
        const response = await axios.post(
          `${BASE_API_URL}api/profile/`,
          {
            milestone: { child: updatedMilestone },
          },
          {
            headers: {
              Accept: "application/json",
            },
            withCredentials: true,
          }
        );
        setCustomerInfo({ ...response.data });
      } catch (error) {
        Swal.fire({
          title: "error",
          text: "Something went wrong",
          showConfirmButton: false,
          showDenyButton: true,
        });
      } finally {
        handleClose();
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      child_name: `${selectedCustomer?.child_name}`,
      age: `${selectedCustomer?.age}`,
      id: `${selectedCustomer?.id}`,
      milestone: selectedCustomer?.milestone,
      // ... other form fields
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      child_name: Yup.string().required("Required"),
      age: Yup.string().required("Required"),
      // ... other validations
    }),
    onSubmit: async (values) => {
      try {
        console.log("edited milestone", values);

        // Retrieve existing milestone array from selectedCustomer or initialize as an empty array if it doesn't exist yet
        const existingMilestone = customerInfo?.milestone?.child || [];

        // Find the index of the child to be updated
        const childIndex = existingMilestone.findIndex(
          (child) => child.id === values.id
        );
        console.log("existingggg Dataa", existingMilestone, childIndex);

        // If child with the provided ID is found, update it; otherwise, do nothing

        if (childIndex !== -1) {
          // Update the child at the found index with the new values
          existingMilestone[childIndex] = values;

          // Make the PUT request with the updated milestone
          const response = await axios.put(
            `${BASE_API_URL}api/profile/`,
            {
              milestone: { child: existingMilestone },
            },
            {
              headers: {
                Accept: "application/json",
              },
              withCredentials: true,
            }
          );

          // Update the customerInfo state with the response data
          setCustomerInfo({ ...response.data });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          showConfirmButton: false,
          showDenyButton: true,
        });
      } finally {
        seteditModal(false);
        setselectedCustomer(null);
      }
    },
  });

  console.log("mile Formikkk", customerInfo, selectedCustomer);

  const handleDelete = async (id) => {
    try {
      // Filter out the child with the provided id
      const filteredMilestone = (customerInfo?.milestone?.child || []).filter(
        (child) => child?.id !== id
      );

      console.log("filteredMilestone", filteredMilestone);

      // Send the updated milestone array to the backend
      await axios.put(
        `${BASE_API_URL}api/profile/`,
        {
          milestone: { child: filteredMilestone },
        },
        {
          headers: {
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );

      // Optionally, update the local state with the filtered milestone array
      setCustomerInfo((prevCustomerInfo) => ({
        ...prevCustomerInfo,
        milestone: { child: filteredMilestone },
      }));
    } catch (error) {
      // Handle errors appropriately
      console.error("Error deleting child:", error);
    }
  };

  const deleteChild = (data) => {
    Swal.fire({
      title: `Are you sure you want to delete ${data.child_name}?`,
      text: `All the data of ${data.child_name} will be lost`,
      showConfirmButton: false,
      showDenyButton: true,
      denyButtonText: `Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        handleDelete(data?.id).then((res) => {
          Swal.fire("Deleted!", "", "success");
        });
      }
    });
  };

  return (
    <>
      <div className="title-container">
        <h5>Milestone</h5>
      </div>

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid item>
          <Item className="popular-box">
            <div className="card-title-container">
              <div className="card-title">Child Statistics</div>
              <div
                className="button green add-new-child"
                onClick={() => handleOpen()}
              >
                Add Child
              </div>
            </div>

            {childlist ? (
              <>
                <div className="table-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {["Sr no", "Child Name", "Age", "Action"].map(
                          (headCell, index) => (
                            <TableCell key={index}>{headCell}</TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerInfo?.milestone?.child?.map((milestone, i) => (
                        <TableRow key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{milestone?.child_name}</TableCell>
                          <TableCell>{milestone?.age}</TableCell>
                          <TableCell>
                            <div
                              className="flex-container"
                              style={{ justifyContent: "end" }}
                            >
                              <NavLink
                                to={`/customer-dashboard/milestone-detail/${milestone?.id}`}
                                state={milestone}
                              >
                                <div className="icon-container">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.5"
                                      y="0.5"
                                      width="23"
                                      height="23"
                                      rx="1.5"
                                      fill="#F9D54A"
                                      fillOpacity="0.05"
                                      stroke="#F9D54A"
                                    />
                                    <path
                                      d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
                                      stroke="#F9D54A"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5.63867 12.0002C6.48819 9.29542 9.01505 7.3335 12.0001 7.3335C14.9852 7.3335 17.5121 9.29544 18.3616 12.0002C17.5121 14.7049 14.9852 16.6668 12.0001 16.6668C9.01504 16.6668 6.48817 14.7049 5.63867 12.0002Z"
                                      stroke="#F9D54A"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </NavLink>

                              <div
                                className="icon-container"
                                onClick={() => {
                                  setselectedCustomer(milestone);
                                  seteditModal(true);
                                }}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="1.5"
                                    fill="#4B9E99"
                                    fillOpacity="0.12"
                                    stroke="#4B9E99"
                                  />
                                  <path
                                    d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                                    fill="#4B9E99"
                                  />
                                  <path
                                    d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                                    fill="#4B9E99"
                                  />
                                </svg>
                              </div>
                              <div
                                className="icon-container"
                                onClick={() => deleteChild(milestone)}
                              >
                                <svg
                                  width="23"
                                  height="24"
                                  viewBox="0 0 23 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="21.8"
                                    height="23"
                                    rx="1.5"
                                    fill="#B72126"
                                    fillOpacity="0.13"
                                    stroke="#B72126"
                                  />
                                  <path
                                    d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                                    stroke="#B72126"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              <>Add Child</>
            )}
          </Item>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <>
            <div className="title-container">
              <h5>Add new Child</h5>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group-container">
                <div className="form-group">
                  <FormControl fullWidth color="green">
                    <TextField
                      name="child_name"
                      value={formik.values.child_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label="Name of Child"
                      variant="outlined"
                      fullWidth
                      color="green"
                      error={
                        formik.touched.child_name &&
                        Boolean(formik.errors.child_name)
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label" color="green">
                      Age
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.age}
                      onChange={formik.handleChange}
                      error={formik.touched.age && Boolean(formik.errors.age)}
                      label="Age"
                      color="green"
                      key="age"
                      name="age"
                    >
                      <MenuItem key="1-2" value="1-2">
                        1-2
                      </MenuItem>
                      <MenuItem key="2-3" value="2-3">
                        2-3
                      </MenuItem>
                      <MenuItem key="3-4" value="3-4">
                        3-4
                      </MenuItem>
                      <MenuItem key="4-5" value="4-5">
                        4-5
                      </MenuItem>
                      <MenuItem key="5-6" value="5-6">
                        5-6
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    className="button green"
                    value={"Add child"}
                  />
                </div>
              </div>
            </form>
          </>
        </Box>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => {
          seteditModal(false);
          setselectedCustomer(null);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <>
            <div className="title-container">
              <h5>Edit Child</h5>
            </div>

            <form onSubmit={editFormik.handleSubmit}>
              <div className="form-group-container">
                <div className="form-group">
                  <FormControl fullWidth color="green">
                    <TextField
                      name="child_name"
                      value={editFormik.values.child_name}
                      onChange={editFormik.handleChange}
                      onBlur={editFormik.handleBlur}
                      label="Name of Child"
                      variant="outlined"
                      fullWidth
                      color="green"
                      error={
                        editFormik.touched.child_name &&
                        Boolean(editFormik.errors.child_name)
                      }
                    />
                  </FormControl>
                </div>
                <div className="form-group">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label" color="green">
                      Age
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={editFormik.values.age}
                      onChange={editFormik.handleChange}
                      error={
                        editFormik.touched.age && Boolean(editFormik.errors.age)
                      }
                      label="Age"
                      color="green"
                      key="age"
                      name="age"
                    >
                      <MenuItem key="1-2" value="1-2">
                        1-2
                      </MenuItem>
                      <MenuItem key="2-4" value="2-4">
                        2-4
                      </MenuItem>
                      <MenuItem key="4-6" value="4-6">
                        4-6
                      </MenuItem>
                      <MenuItem key="6-8" value="6-8">
                        6-8
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    className="button green"
                    value={"Add child"}
                  />
                </div>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </>
  );
}

export default CustomerMilestone;
