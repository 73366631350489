import React, { useState } from 'react'


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Form
import { TextField, IconButton, InputAdornment, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


// BOX
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'red' : 'white',
    ...theme.typography.body2,
    padding: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #E7E7E7',
    color: 'black',
    height: '100%',
}));

function AddSchool() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordsMatch(event.target.value === password);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="title-container">
                <h5>Add Schools</h5>
            </div>

            {/* Add School Form */}
            <Box sx={{ flecGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Item className="popular-box">
                            <form action="">
                                {/* School Details */}
                                <div className='name'>
                                    School Details
                                </div>
                                <div className='form-group-container'>
                                    <div className='form-group'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="School Name" type='text' color="green" label="School Name" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="E-Mail" type='text' color="green" label="E-Mail" variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='form-group'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="Mobile" type='text' color="green" label="Mobile" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="Address1" type='text' color="green" label="Address 1" variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='form-group'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="Address2" type='text' color="green" label="Address 2" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="Postcode" type='text' color="green" label="Postcode" variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className='form-group'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="City" type='text' color="green" label="City" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="State" type='text' color="green" label="State" variant="outlined" />
                                        </FormControl>
                                    </div>

                                </div>

                                {/* Personal Details */}
                                <div className='name'>
                                    Personal Details
                                </div>
                                <div className='form-group-container'>
                                    <div className='form-group'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="Principal Name" type='text' color="green" label="Principal Name" variant="outlined" />
                                        </FormControl>
                                    </div>
                                    <div className="form-group">
                                        <FormControl fullWidth color="green">
                                            <TextField
                                                type={showPassword ? 'text' : 'password'}
                                                label="Password"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)}
                                                fullWidth
                                                required
                                                color="green"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={toggleShowPassword}>
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField
                                                type="password"
                                                label="Confirm Password"
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                                error={!passwordsMatch}
                                                helperText={!passwordsMatch && "Passwords don't match"}
                                                fullWidth
                                                required
                                                color="green"

                                            />
                                        </FormControl>
                                    </div>

                                </div>

                                {/* School Commission */}
                                <div className='name'>
                                    School Commission
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <FormControl fullWidth color="green">
                                            <TextField key="Commission" type='text' color="green" label="Commissio(in %)" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker color="green" label="Eligibility From"
													renderInput={() => (
														<TextField className="myDatePicker" />
													)}
												/>
											</LocalizationProvider>
										</FormControl>
                                    </div>
                                    <div className='form-group mt-20'>
                                        <input type="submit" value="Add School"  className='button green'/>                                     
                                    </div>
                                </div>

                            </form>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            {/* Add School Form */}
        </>
    )
}

export default AddSchool