import React from 'react'

function Returns() {
    return (

        <>
            <section className='container case-detail-container'>
                <div class="case-study-detail">
                    <div class="case-study-title-container">
                        <div class="case-study-title">
                            <h1>Return & Refund</h1>
                        </div>
                    </div>
                    <div class="case-study">
                        
                        <div class="section team-solution">
                            <div class="heading">
                                Refunds
                            </div>
                            Please click on the ‘Refund’ button on your account page. You will need to fill a short form after which we will return your security deposit within 7 working days. Any balance due/damages to the toys or books will be deducted from your deposit. Please note that the balance from your membership plans for any unused GG Tokens will not be refunded.
                        </div>
                        
                        <div class="section team-solution">
                            <div class="heading">
                                Communication
                            </div>
                            Please get in touch with us by writing to us on info@growinggiraffe.com or give us a call on 9321146692 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                            Termination / Cancellation
                            </div>
                            In case of non adherence to any of the terms mentioned above Growing Giraffe holds complete and non-negotiable right to terminate your membership. GrowingGiraffe will not be liable to refund the Security Deposit in such a case. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                            Return & Refund
                            </div>
                            There is no provision for cancellation of an ongoing plan. If you wish to cancel the subscription going forward, you can log into your account and claim a refund of your security deposit. Please click on the ‘Refund’ button on your account page. You will need to fill a short form after which we will return your security deposit within 7 working days. Any balance due/damages to the toys or books will be deducted from your deposit. Please note that the balance from your membership plans for any unused GG Tokens will not be refunded. 
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Returns