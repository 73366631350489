import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Paper,
  FormControl,
  TextField,
  Autocomplete,
  IconButton,
  InputLabel,
} from "@mui/material";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import { AgeApi } from "../../api/AgeApi";
import {
  AddProductImageApi,
  DeleteProductImageApi,
  EditProductApi,
  ProductCategoryApi,
  ProductDetailApi,
  ProductImageApi,
  ProductSubCategoryApi,
} from "../../api/ProductApi";
import { useLocation } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));

const initialProductData = {
  image: null,
  name: "",
  sku: "",
  gg_number: "",
  article_no: "",
  description: "",
  small_description: "",
  category: "",
  subCategory: "",
  price_1: 0,
  price_3: 0,
  price_7: 0,
  price_14: 0,
  available_stock: "",
  total_stock: "",
  deposit: "",
  age: "",
  slug: "",
};

function AddProduct() {
  const location = useLocation();
  const lastPartOfUrl = window.location.pathname.split("/").pop();
  const [productData, setProductData] = useState(initialProductData);
  const [ageList, setAgeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [productImages, setproductImages] = useState([])

  const productId = window.location.pathname.split("/").pop();
  const [children, setChildren] = useState(null);

  // Function to handle adding a new child
  const addChild = () => {
    if (!children) {
      // If there are no children yet, add the first child
      setChildren([{ label: "", value: "" }]);
    } else {
      // Otherwise, add a new child to the existing array
      setChildren([
        ...children,
        { label: "", value: "" },
      ]);
    }
  };

  // Function to handle removing a child
  const remove_child = (index) => {
    if (children) {
      const updated_children = [...children];
      updated_children.splice(index, 1);
      setChildren(updated_children);
    }
  };

  // Function to handle changes to a child's name and age
  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ageData, categoryData, productDetails, subCategoryData] =
          await Promise.all([
            AgeApi(),
            ProductCategoryApi(),
            ProductDetailApi(productId),
            ProductSubCategoryApi(),
          ]);
        setAgeList(ageData.results);
        setCategoryList(categoryData);
        setsubCategoryList(subCategoryData);
        console.log(productDetails)

        setProductData((prevState) => ({
          ...prevState,
          id: productDetails?.data.id,
          image: location?.state?.product_img,
          name: productDetails.data.name,
          sku: productDetails.data.sku,
          article_no: productDetails.data.article_no,
          description: DOMPurify.sanitize(productDetails.data.description, {
            ALLOWED_TAGS: [],
          }),
          small_description: DOMPurify.sanitize(
            productDetails.data.small_description,
            { ALLOWED_TAGS: [] }
          ),
          category: productDetails.data.category.id,
          subCategory: productDetails?.data?.extra?.subCategory,
          price_1: productDetails.data.price_1,
          price_3: productDetails.data.price_3,
          price_7: productDetails.data.price_7,
          price_14: productDetails.data.price_14,
          available_stock: productDetails.data.available_stock,
          total_stock: productDetails.data.total_stock,
          deposit: productDetails.data.deposit,
          age: productDetails?.data?.extra?.age ?? productDetails?.data?.extra?.Age,
          slug: productDetails.data.slug,
          gg_number: productDetails?.data?.sku,
          extra: productDetails?.data?.extra
        }));

        console.log('product Detail', productDetails)
        setChildren(productDetails?.data?.extra?.extra_details)
        setSelectedAges(productDetails.data.age);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        Swal.fire("Error", "Failed to load data", "error");
      }
    };

    fetchData();
  }, []);

  const fetchProductImages = async () => {
    try {
      const res = await ProductImageApi(productId)
      console.log('ressssssssssssss', res)
      setproductImages(res?.results)
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchProductImages()
  }, [])

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length) {
      const newImages = Array.from(files).map(file => ({
        // Assuming you want to construct objects similar to your existing state
        // You might need to adjust based on how you want these objects structured
        name: file.name,
        image: file, // Store the File object directly
      }));

      // Update the productImages state by appending new images
      setproductImages(prevImages => [...prevImages, ...newImages]);
    }
  };


  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name == 'image') {
      setProductData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {

      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();

    for (const key in productData) {
      console.log('key', productData['image'], typeof (productData['image']));
      // Correctly check if the type is 'string'
      if (key == "image" && typeof (productData['image']) == "string") {
        continue; // Skip this iteration and move to the next key
      } else {
        formData.append(key, productData[key]);
      }
    }

    const extra_param_data = {
      age: productData?.age,
      subCategory: productData?.subCategory,
      extra_details: children
    }

    formData.append('extra', JSON.stringify(extra_param_data))

    try {
      // Make the API request using axios
      const response = await EditProductApi(formData, location?.state?.id);

      productImages?.forEach(async (image) => {
        const imageForm = new FormData();
        imageForm.append('product', location?.state?.id);
        imageForm.append('name', image?.name);
        imageForm.append('image', image?.image);

        if (!image?.id) {
          await AddProductImageApi(imageForm)
        }
      })

      Swal.fire({
        title: 'Success!',
        text: 'Product has been Updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          // Redirect to the home page
          window.location.href = '/admin/products'
        }
      });

    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error posting data:", error);
    } finally {
      setProductData(initialProductData);
    }
  };

  const handleRemoveImage = async (index, id) => {

    try {
      await DeleteProductImageApi(id)
      setproductImages((currentImages) => {
        const newImages = [...currentImages];
        newImages.splice(index, 1);
        return newImages;
      });
    } catch (error) {

    }
  };


  console.log("edit product", productData, ageList);

  return (
    <>
      <div className="title-container">
        <h5>Edit Product</h5>
      </div>

      {/* Order Add Form */}
      <Box sx={{ flecGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Item className="popular-box">
              {productData ? (
                <form
                  className="product-add"
                  onSubmit={(e) => handleSubmitProduct(e)}
                >
                  <div className="name">Product Details</div>
                  <div className="form-group-container">
                    <div className="form-group">
                      <FormControl fullWidth>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="upload-btn-thumbnail"
                          type="file"
                          name="image"
                          multiple
                          onChange={handleChange}
                        />
                        <label htmlFor="upload-btn-thumbnail" className="upload-btn-thumbnail">
                          <div>
                            <img
                              src={productData?.image instanceof Blob ? URL.createObjectURL(productData?.image) : `${productData?.image}`}
                              alt={`Thumbnail`}
                              style={{ width: '50%' }}
                            />
                          </div>
                        </label>
                      </FormControl>

                    </div>
                    <div className="form-group">
                      <FormControl fullWidth>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="upload-btn"
                          type="file"
                          multiple
                          onChange={handleFileChange}
                        />
                        <label htmlFor="upload-btn" className="upload-btn">
                          {productImages?.map((image, index) => (
                            <div key={index} style={{ position: 'relative', display: 'inline-block', margin: 8 }}>
                              <img
                                src={image?.image instanceof Blob ? URL.createObjectURL(image?.image) : `https://api.growinggiraffe.com/${image?.image}`}
                                alt={`Thumbnail`}
                                style={{ width: 100, height: 100 }} // Adjust size as needed
                              />
                              <IconButton
                                onClick={() => handleRemoveImage(index, image?.id)}
                                size="small"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  color: 'rgba(255, 255, 255, 0.7)', // Icon color
                                  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Icon background
                                  margin: 0,
                                  padding: 4,
                                  borderRadius: '50%',
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </div>
                          ))}
                        </label>
                      </FormControl>

                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="Product ID"
                          name="name"
                          value={productData?.id}
                          onChange={handleChange}
                          required
                          label="Product Id"
                          color="green"
                          disabled
                        />
                      </FormControl>

                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="Product Name"
                          name="name"
                          value={productData?.name}
                          onChange={handleChange}
                          required
                          label="Product Name"
                          color="green"
                        />
                      </FormControl>
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="gg_number"
                          value={productData?.gg_number}
                          onChange={handleChange}
                          name="gg_number"
                          required
                          label="GG Number"
                          color="green"
                        />
                      </FormControl>
                    </div>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="Sku"
                        name="sku"
                        value={productData?.sku}
                        onChange={handleChange}
                        required
                        label="Sku"
                        color="green"
                      />
                    </FormControl>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="Article Number"
                          name="article_no"
                          required
                          label="Article Number"
                          color="green"
                          value={productData?.article_no}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">

                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          required
                          label="Description"
                          multiline
                          rows={5}
                          color="green"
                          value={productData?.description}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      {/* <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="Small Description"
                          name="small_description"
                          required
                          label="Small Description"
                          color="green"
                          value={productData?.small_description}
                          onChange={handleChange}
                        />
                      </FormControl> */}
                      <Autocomplete
                        fullWidth
                        options={categoryList}
                        value={categoryList.find(category => category.id === productData?.category) || null}
                        getOptionLabel={(option) => option?.label}
                        name="category"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="green"
                            name="item"
                            label="Select SKills"
                            variant="outlined"
                          />
                        )}
                        onChange={(e, newValue) => {
                          setProductData((prev) => ({
                            ...prev,
                            category: newValue?.id,
                          }));
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Autocomplete
                        fullWidth
                        options={subCategoryList}
                        value={subCategoryList?.find(category => category.id === productData?.subCategory) || null}
                        getOptionLabel={(option) => option?.label}
                        name="category"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="green"
                            name="item"
                            label="Select Type of Toys"
                            variant="outlined"
                          />
                        )}
                        onChange={(e, newValue) => {
                          setProductData((prev) => ({
                            ...prev,
                            subCategory: newValue?.id,
                          }));
                        }}
                      />

                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="price_1"
                          name="price_1"
                          required
                          label="Price 1"
                          type="number"
                          color="green"
                          value={productData?.price_1}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="price_3"
                          name="price_3"
                          required
                          label="Price 3"
                          type="number"
                          color="green"
                          value={productData?.price_3}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="price_7"
                          name="price_7"
                          required
                          label="Price 7"
                          type="number"
                          color="green"
                          value={productData?.price_7}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="price_14"
                          name="price_14"
                          required
                          label="Price 14"
                          type="number"
                          color="green"
                          value={productData?.price_14}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="available_stock"
                          name="available_stock"
                          required
                          label="Available Stocks"
                          color="green"
                          value={productData?.available_stock}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="total_stock"
                          name="total_stock"
                          required
                          label="Total Stock"
                          color="green"
                          value={productData?.total_stock}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="deposit"
                          name="deposit"
                          required
                          label="deposit"
                          color="green"
                          value={productData?.deposit}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <Autocomplete
                        fullWidth
                        options={ageList}
                        // Find the age object in ageList that matches productData.age
                        value={ageList.find(age => age.id === productData.age) || null}
                        getOptionLabel={(option) => option?.label}
                        name="category"
                        required
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Select Age"
                            variant="outlined"
                          />
                        )}
                        onChange={(e, newValue) => {
                          setProductData((prev) => ({
                            ...prev,
                            age: newValue?.id,
                          }));
                        }}
                      />


                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="slug"
                          name="slug"
                          required
                          label="slug"
                          color="green"
                          value={productData?.slug}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </div>

                    <div>
                      {children &&
                        children?.map((child, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              gap: "10px",
                              margin: "10px 0",
                            }}
                          >
                            <TextField
                              color="green"
                              label={`Label ${index + 1}`}
                              value={child.label}
                              fullWidth
                              onChange={(e) =>
                                handleChildChange(
                                  index,
                                  "label",
                                  e.target.value
                                )
                              }
                            />

                            <TextField
                              color="green"
                              label={`Value ${index + 1}`}
                              value={child.value}
                              fullWidth
                              onChange={(e) =>
                                handleChildChange(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                            />

                            <button
                            type="button"
                              onClick={() => remove_child(index)}
                            >
                              <svg
                                width="23"
                                height="24"
                                viewBox="0 0 23 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="21.8"
                                  height="23"
                                  rx="1.5"
                                  fill="#B72126"
                                  fillOpacity="0.13"
                                  stroke="#B72126"
                                />
                                <path
                                  d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
                                  stroke="#B72126"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      <div className="button yellow" onClick={addChild}>
                        Add Extra Fields
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="button green my-25">
                    Edit Product
                  </button>
                </form>
              ) : (
                <></>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AddProduct;
