import React, { useEffect, useState } from "react";
import { NavLink, Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../style/components/layout/layout.css";
import "../style/common.css";
import Hamburger from "hamburger-react";

// Logout BTN
import LogoutButton from "./Logout/Logout";

// API
import { CustomerInfoApi } from "../../customer/api/CustomerInfoApi";

const Resize = () => {
  var width = document.getElementById("sidebar")?.offsetWidth;
  var height = document.getElementById("header")?.offsetHeight;
  document.getElementById("dashboard-main").style.marginLeft = width + "px";
  document.getElementById("dashboard-main").style.marginTop = height + "px";
};

function DashboardLayout() {
  const [isOpen, setOpen] = useState(false);
  window.onresize = Resize;

  // Color Change Of Link
  const [link, setLink] = useState("/admin");
  const location = useLocation();

  useEffect(() => {
    Resize();
    setLink(location.pathname);
    setOpen(false);
  }, [location]);

  const [showProductsSubmenu, setShowProductsSubmenu] = useState(false); // Using React's useState for submenu toggle
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setShowProductsSubmenu(!showProductsSubmenu);
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="slidebar" id="sidebar">
        <div className="content">
          <div className="logo-container">
            <img src="/images/logo.png" alt="" />
          </div>
          <div className="navbar">
            <nav>
              <ul>
                <NavLink
                  to="/admin"
                  className={link == "/admin" ? "active" : "unactive"}
                >
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6.25C5 5.55965 5.55965 5 6.25 5H23.75C24.4404 5 25 5.55965 25 6.25V8.75C25 9.44035 24.4404 10 23.75 10H6.25C5.55965 10 5 9.44035 5 8.75V6.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 16.25C5 15.5596 5.55965 15 6.25 15H13.75C14.4404 15 15 15.5596 15 16.25V23.75C15 24.4404 14.4404 25 13.75 25H6.25C5.55965 25 5 24.4404 5 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 16.25C20 15.5596 20.5596 15 21.25 15H23.75C24.4404 15 25 15.5596 25 16.25V23.75C25 24.4404 24.4404 25 23.75 25H21.25C20.5596 25 20 24.4404 20 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Dashboard
                  </li>
                </NavLink>
                <NavLink to="order">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 6.25H8.75C7.36929 6.25 6.25 7.36929 6.25 8.75V23.75C6.25 25.1307 7.36929 26.25 8.75 26.25H21.25C22.6307 26.25 23.75 25.1307 23.75 23.75V8.75C23.75 7.36929 22.6307 6.25 21.25 6.25H18.75M11.25 6.25C11.25 7.63071 12.3693 8.75 13.75 8.75H16.25C17.6307 8.75 18.75 7.63071 18.75 6.25M11.25 6.25C11.25 4.86929 12.3693 3.75 13.75 3.75H16.25C17.6307 3.75 18.75 4.86929 18.75 6.25M11.25 6.25H12.5M18.75 6.25H12.5M15 15H18.75M15 20H18.75M11.25 15H11.2625M11.25 20H11.2625M12.5 6.25V4.5H17.5V8H12.5V6.25Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Orders
                  </li>
                </NavLink>
                <NavLink to="dispatch">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 17C9 18.1046 8.10457 19 7 19C5.89543 19 5 18.1046 5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17Z"
                          fill="white"
                        />
                        <path
                          d="M19 17C19 18.1046 18.1046 19 17 19C15.8954 19 15 18.1046 15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17Z"
                          fill="white"
                        />
                        <path
                          d="M15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17M13 16V6C13 5.44772 12.5523 5 12 5H4C3.44772 5 3 5.44772 3 6V16C3 16.5523 3.44772 17 4 17H5L13 16ZM13 16C13 16.5523 12.5523 17 12 17H9L13 16ZM13 16V8C13 7.44772 13.4477 7 14 7H16.5858C16.851 7 17.1054 7.10536 17.2929 7.29289L20.7071 10.7071C20.8946 10.8946 21 11.149 21 11.4142V16C21 16.5523 20.5523 17 20 17H19L13 16ZM13 16C13 16.5523 13.4477 17 14 17H15L13 16ZM5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17H5ZM5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17H5ZM15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17H15Z"
                          stroke="#374151"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Dispatch
                  </li>
                </NavLink>
                <NavLink to="pickups">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17Z"
                          fill="white"
                        />
                        <path
                          d="M5 17C5 18.1046 5.8954 19 7 19C8.1046 19 9 18.1046 9 17C9 15.8954 8.1046 15 7 15C5.8954 15 5 15.8954 5 17Z"
                          fill="white"
                        />
                        <path
                          d="M9 17C9 15.8954 8.1046 15 7 15C5.8954 15 5 15.8954 5 17M11 16V6C11 5.44772 11.4477 5 12 5H20C20.5523 5 21 5.44772 21 6V16C21 16.5523 20.5523 17 20 17H19L11 16ZM11 16C11 16.5523 11.4477 17 12 17H15L11 16ZM11 16V8C11 7.44772 10.5523 7 10 7H7.4142C7.149 7 6.8946 7.10536 6.7071 7.29289L3.2929 10.7071C3.1054 10.8946 3 11.149 3 11.4142V16C3 16.5523 3.4477 17 4 17H5L11 16ZM11 16C11 16.5523 10.5523 17 10 17H9L11 16ZM19 17C19 18.1046 18.1046 19 17 19C15.8954 19 15 18.1046 15 17H19ZM19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17H19ZM9 17C9 18.1046 8.1046 19 7 19C5.8954 19 5 18.1046 5 17H9Z"
                          stroke="#374151"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Pickups
                  </li>
                </NavLink>
                {/* <NavLink to="subscriptions-plans" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.875 10H13.75M19.375 10H13.75M15.625 21.25L11.875 17.5H13.125C15.1961 17.5 16.875 15.8211 16.875 13.75C16.6667 12.5 15.75 10 13.75 10M11.875 13.75H19.375M26.875 15C26.875 21.2133 21.8383 26.25 15.625 26.25C9.4118 26.25 4.375 21.2133 4.375 15C4.375 8.7868 9.4118 3.75 15.625 3.75C21.8383 3.75 26.875 8.7868 26.875 15Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Subscriptions Plans
                                    </li>
                                </NavLink> */}
                <NavLink to="customers">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 25H27.5V22.5C27.5 20.4289 25.8211 18.75 23.75 18.75C22.5555 18.75 21.4914 19.3085 20.8048 20.1786M21.25 25L20.8048 20.1786M21.25 25H8.75M21.25 25V22.5C21.25 21.6798 21.092 20.8963 20.8048 20.1786M20.8048 20.1786C19.8831 17.8763 17.6315 16.25 15 16.25C12.3685 16.25 10.1169 17.8763 9.19531 20.1786M8.75 25H2.5V22.5C2.5 20.4289 4.17894 18.75 6.25 18.75C7.44451 18.75 8.50857 19.3085 9.19531 20.1786M8.75 25L9.19531 20.1786M8.75 25V22.5C8.75 21.6798 8.90802 20.8963 9.19531 20.1786M18.75 8.75C18.75 10.8211 17.0711 12.5 15 12.5C12.9289 12.5 11.25 10.8211 11.25 8.75C11.25 6.67894 12.9289 5 15 5C17.0711 5 18.75 6.67894 18.75 8.75ZM26.25 12.5C26.25 13.8807 25.1307 15 23.75 15C22.3693 15 21.25 13.8807 21.25 12.5C21.25 11.1193 22.3693 10 23.75 10C25.1307 10 26.25 11.1193 26.25 12.5ZM8.75 12.5C8.75 13.8807 7.63071 15 6.25 15C4.86929 15 3.75 13.8807 3.75 12.5C3.75 11.1193 4.86929 10 6.25 10C7.63071 10 8.75 11.1193 8.75 12.5Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Customer
                  </li>
                </NavLink>
                {/* <NavLink to="schools" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 26.25H17.5M23.75 26.25V6.25C23.75 4.86929 22.6307 3.75 21.25 3.75H8.75C7.36929 3.75 6.25 4.86929 6.25 6.25V26.25H23.75ZM23.75 26.25H26.25H23.75ZM23.75 26.25H17.5H23.75ZM6.25 26.25H3.75H6.25ZM6.25 26.25H12.5H6.25ZM11.25 8.74997H12.5H11.25ZM11.25 13.75H12.5H11.25ZM17.5 8.74997H18.75H17.5ZM17.5 13.75H18.75H17.5ZM12.5 26.25V20C12.5 19.3096 13.0596 18.75 13.75 18.75H16.25C16.9404 18.75 17.5 19.3096 17.5 20V26.25H12.5Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        School
                                    </li>
                                </NavLink> */}
                <div
                  className={isActive ? "active" : "inactive"}
                  onClick={toggleActive}
                >
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.75 5C13.75 3.61929 14.8693 2.5 16.25 2.5C17.6307 2.5 18.75 3.61929 18.75 5V6.25C18.75 6.94035 19.3096 7.5 20 7.5H23.75C24.4404 7.5 25 8.05965 25 8.75V12.5C25 13.1904 24.4404 13.75 23.75 13.75H22.5C21.1193 13.75 20 14.8693 20 16.25C20 17.6307 21.1193 18.75 22.5 18.75H23.75C24.4404 18.75 25 19.3096 25 20V23.75C25 24.4404 24.4404 25 23.75 25H20C19.3096 25 18.75 24.4404 18.75 23.75V22.5C18.75 21.1193 17.6307 20 16.25 20C14.8693 20 13.75 21.1193 13.75 22.5V23.75C13.75 24.4404 13.1904 25 12.5 25H8.75C8.05965 25 7.5 24.4404 7.5 23.75V20C7.5 19.3096 6.94035 18.75 6.25 18.75H5C3.61929 18.75 2.5 17.6307 2.5 16.25C2.5 14.8693 3.61929 13.75 5 13.75H6.25C6.94035 13.75 7.5 13.1904 7.5 12.5V8.75C7.5 8.05965 8.05965 7.5 8.75 7.5H12.5C13.1904 7.5 13.75 6.94035 13.75 6.25V5Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Products
                    {/* Adding a simple caret for the dropdown; style as desired */}
                    {showProductsSubmenu ? (
                      <span
                        className="icon-container"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 15L12 8L19 15"
                            stroke="#4A5568"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span
                        className="icon-container"
                        style={{ height: "20px", width: "20px" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 9L12 16L5 9"
                            stroke="#4A5568"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                  </li>
                </div>
                {showProductsSubmenu && (
                  <div className="products-submenu">
                    <NavLink to="products">Products List</NavLink>
                    <NavLink to="product-category">Product Categories</NavLink>
                    {/* Add as many links as required */}
                  </div>
                )}
                {/* <NavLink to="reports" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.25 21.25V18.75V21.25ZM15 21.25V16.25V21.25ZM18.75 21.25V13.75V21.25ZM21.25 26.25H8.75C7.36929 26.25 6.25 25.1307 6.25 23.75V6.25C6.25 4.86929 7.36929 3.75 8.75 3.75H15.7323C16.0638 3.75 16.3818 3.8817 16.6161 4.11611L23.3839 10.8839C23.6182 11.1183 23.75 11.4363 23.75 11.7678V23.75C23.75 25.1307 22.6307 26.25 21.25 26.25Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Reports
                                    </li>
                                </NavLink>
                                <NavLink to="promo" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.25 17.5L18.75 10L11.25 17.5ZM11.875 10.625H11.8875H11.875ZM18.125 16.875H18.1375H18.125ZM23.75 26.25V6.25C23.75 4.86929 22.6307 3.75 21.25 3.75H8.75C7.36929 3.75 6.25 4.86929 6.25 6.25V26.25L10.625 23.75L15 26.25L19.375 23.75L23.75 26.25ZM12.5 10.625C12.5 10.9702 12.2202 11.25 11.875 11.25C11.5298 11.25 11.25 10.9702 11.25 10.625C11.25 10.2798 11.5298 10 11.875 10C12.2202 10 12.5 10.2798 12.5 10.625ZM18.75 16.875C18.75 17.2201 18.4701 17.5 18.125 17.5C17.7799 17.5 17.5 17.2201 17.5 16.875C17.5 16.5299 17.7799 16.25 18.125 16.25C18.4701 16.25 18.75 16.5299 18.75 16.875Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Promo Codes
                                    </li>
                                </NavLink>
                                <NavLink to="account-settings" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.9057 5.39663C13.4387 3.20112 16.5612 3.20112 17.0942 5.39663C17.4385 6.81491 19.0634 7.48797 20.3097 6.72856C22.2391 5.55298 24.447 7.76087 23.2715 9.69022C22.512 10.9366 23.1851 12.5615 24.6034 12.9057C26.7989 13.4387 26.7989 16.5612 24.6034 17.0942C23.1851 17.4385 22.512 19.0634 23.2715 20.3097C24.447 22.2391 22.2391 24.447 20.3097 23.2715C19.0634 22.512 17.4385 23.1851 17.0942 24.6034C16.5612 26.7989 13.4387 26.7989 12.9057 24.6034C12.5615 23.1851 10.9366 22.512 9.69023 23.2715C7.76087 24.447 5.55298 22.2391 6.72856 20.3097C7.48797 19.0634 6.81491 17.4385 5.39663 17.0942C3.20112 16.5612 3.20112 13.4387 5.39663 12.9057C6.81491 12.5615 7.48796 10.9366 6.72856 9.69022C5.55298 7.76087 7.76087 5.55298 9.69023 6.72856C10.9366 7.48796 12.5615 6.81491 12.9057 5.39663Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Account Settings
                                    </li>
                                </NavLink> */}
              </ul>
            </nav>
          </div>
          <LogoutButton />
        </div>
      </div>
      <div className="header" id="header">
        <div className="content">
          <div className="login-btn">
            <div className="icon-container">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 23.75H20M6.4013 22.2546C8.94084 20.8192 11.8747 20 15 20C18.1253 20 21.0591 20.8192 23.5987 22.2546H15H6.4013ZM18.75 12.5C18.75 14.5711 17.0711 16.25 15 16.25C12.9289 16.25 11.25 14.5711 11.25 12.5C11.25 10.4289 12.9289 8.75 15 8.75C17.0711 8.75 18.75 10.4289 18.75 12.5ZM26.25 15C26.25 21.2133 21.2133 26.25 15 26.25C8.7868 26.25 3.75 21.2133 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2133 3.75 26.25 8.7868 26.25 15Z"
                  stroke="#7E7E7E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 20.5H20.5L21.5 23.5L16 26L10.5 25.5V20.5Z"
                  fill="#7E7E7E"
                />
              </svg>
            </div>
          </div>
          <div className="mobile-header">
            <div className="left">
              <div className="logo-container">
                <img src="/images/logo.png" alt="" />
              </div>
            </div>
            <div className="right">
              <Hamburger rounded toggled={isOpen} toggle={setOpen} />
              <div
                className={
                  isOpen ? "active mobile-list" : "inactive mobile-list"
                }
              >
                <div className="logo-container">
                  <img src="/images/logo.png" alt="" />
                </div>
                <div className="navbar">
                  <nav>
                    <ul>
                      <NavLink
                        to="/admin"
                        className={link == "/admin" ? "active" : "unactive"}
                      >
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 6.25C5 5.55965 5.55965 5 6.25 5H23.75C24.4404 5 25 5.55965 25 6.25V8.75C25 9.44035 24.4404 10 23.75 10H6.25C5.55965 10 5 9.44035 5 8.75V6.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 16.25C5 15.5596 5.55965 15 6.25 15H13.75C14.4404 15 15 15.5596 15 16.25V23.75C15 24.4404 14.4404 25 13.75 25H6.25C5.55965 25 5 24.4404 5 23.75V16.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 16.25C20 15.5596 20.5596 15 21.25 15H23.75C24.4404 15 25 15.5596 25 16.25V23.75C25 24.4404 24.4404 25 23.75 25H21.25C20.5596 25 20 24.4404 20 23.75V16.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Dashboard
                        </li>
                      </NavLink>
                      <NavLink to="order">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.25 6.25H8.75C7.36929 6.25 6.25 7.36929 6.25 8.75V23.75C6.25 25.1307 7.36929 26.25 8.75 26.25H21.25C22.6307 26.25 23.75 25.1307 23.75 23.75V8.75C23.75 7.36929 22.6307 6.25 21.25 6.25H18.75M11.25 6.25C11.25 7.63071 12.3693 8.75 13.75 8.75H16.25C17.6307 8.75 18.75 7.63071 18.75 6.25M11.25 6.25C11.25 4.86929 12.3693 3.75 13.75 3.75H16.25C17.6307 3.75 18.75 4.86929 18.75 6.25M11.25 6.25H12.5M18.75 6.25H12.5M15 15H18.75M15 20H18.75M11.25 15H11.2625M11.25 20H11.2625M12.5 6.25V4.5H17.5V8H12.5V6.25Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Orders
                        </li>
                      </NavLink>
                      <NavLink to="dispatch">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 17C9 18.1046 8.10457 19 7 19C5.89543 19 5 18.1046 5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17Z"
                                fill="white"
                              />
                              <path
                                d="M19 17C19 18.1046 18.1046 19 17 19C15.8954 19 15 18.1046 15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17Z"
                                fill="white"
                              />
                              <path
                                d="M15 17C15 15.8954 15.8954 15 17 15C18.1046 15 19 15.8954 19 17M13 16V6C13 5.44772 12.5523 5 12 5H4C3.44772 5 3 5.44772 3 6V16C3 16.5523 3.44772 17 4 17H5L13 16ZM13 16C13 16.5523 12.5523 17 12 17H9L13 16ZM13 16V8C13 7.44772 13.4477 7 14 7H16.5858C16.851 7 17.1054 7.10536 17.2929 7.29289L20.7071 10.7071C20.8946 10.8946 21 11.149 21 11.4142V16C21 16.5523 20.5523 17 20 17H19L13 16ZM13 16C13 16.5523 13.4477 17 14 17H15L13 16ZM5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17H5ZM5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17H5ZM15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17H15Z"
                                stroke="#374151"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Dispatch
                        </li>
                      </NavLink>
                      <NavLink to="pickups">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17Z"
                                fill="white"
                              />
                              <path
                                d="M5 17C5 18.1046 5.8954 19 7 19C8.1046 19 9 18.1046 9 17C9 15.8954 8.1046 15 7 15C5.8954 15 5 15.8954 5 17Z"
                                fill="white"
                              />
                              <path
                                d="M9 17C9 15.8954 8.1046 15 7 15C5.8954 15 5 15.8954 5 17M11 16V6C11 5.44772 11.4477 5 12 5H20C20.5523 5 21 5.44772 21 6V16C21 16.5523 20.5523 17 20 17H19L11 16ZM11 16C11 16.5523 11.4477 17 12 17H15L11 16ZM11 16V8C11 7.44772 10.5523 7 10 7H7.4142C7.149 7 6.8946 7.10536 6.7071 7.29289L3.2929 10.7071C3.1054 10.8946 3 11.149 3 11.4142V16C3 16.5523 3.4477 17 4 17H5L11 16ZM11 16C11 16.5523 10.5523 17 10 17H9L11 16ZM19 17C19 18.1046 18.1046 19 17 19C15.8954 19 15 18.1046 15 17H19ZM19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17H19ZM9 17C9 18.1046 8.1046 19 7 19C5.8954 19 5 18.1046 5 17H9Z"
                                stroke="#374151"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Pickups
                        </li>
                      </NavLink>
                      {/* <NavLink to="subscriptions-plans" >
                                                <li>
                                                    <div className="icon-container">
                                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.875 10H13.75M19.375 10H13.75M15.625 21.25L11.875 17.5H13.125C15.1961 17.5 16.875 15.8211 16.875 13.75C16.6667 12.5 15.75 10 13.75 10M11.875 13.75H19.375M26.875 15C26.875 21.2133 21.8383 26.25 15.625 26.25C9.4118 26.25 4.375 21.2133 4.375 15C4.375 8.7868 9.4118 3.75 15.625 3.75C21.8383 3.75 26.875 8.7868 26.875 15Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    Subscriptions Plans
                                                </li>
                                            </NavLink> */}
                      <NavLink to="customers">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.25 25H27.5V22.5C27.5 20.4289 25.8211 18.75 23.75 18.75C22.5555 18.75 21.4914 19.3085 20.8048 20.1786M21.25 25L20.8048 20.1786M21.25 25H8.75M21.25 25V22.5C21.25 21.6798 21.092 20.8963 20.8048 20.1786M20.8048 20.1786C19.8831 17.8763 17.6315 16.25 15 16.25C12.3685 16.25 10.1169 17.8763 9.19531 20.1786M8.75 25H2.5V22.5C2.5 20.4289 4.17894 18.75 6.25 18.75C7.44451 18.75 8.50857 19.3085 9.19531 20.1786M8.75 25L9.19531 20.1786M8.75 25V22.5C8.75 21.6798 8.90802 20.8963 9.19531 20.1786M18.75 8.75C18.75 10.8211 17.0711 12.5 15 12.5C12.9289 12.5 11.25 10.8211 11.25 8.75C11.25 6.67894 12.9289 5 15 5C17.0711 5 18.75 6.67894 18.75 8.75ZM26.25 12.5C26.25 13.8807 25.1307 15 23.75 15C22.3693 15 21.25 13.8807 21.25 12.5C21.25 11.1193 22.3693 10 23.75 10C25.1307 10 26.25 11.1193 26.25 12.5ZM8.75 12.5C8.75 13.8807 7.63071 15 6.25 15C4.86929 15 3.75 13.8807 3.75 12.5C3.75 11.1193 4.86929 10 6.25 10C7.63071 10 8.75 11.1193 8.75 12.5Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Customer
                        </li>
                      </NavLink>
                      {/* <NavLink to="schools" >
                                                <li>
                                                    <div className="icon-container">
                                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 26.25H17.5M23.75 26.25V6.25C23.75 4.86929 22.6307 3.75 21.25 3.75H8.75C7.36929 3.75 6.25 4.86929 6.25 6.25V26.25H23.75ZM23.75 26.25H26.25H23.75ZM23.75 26.25H17.5H23.75ZM6.25 26.25H3.75H6.25ZM6.25 26.25H12.5H6.25ZM11.25 8.74997H12.5H11.25ZM11.25 13.75H12.5H11.25ZM17.5 8.74997H18.75H17.5ZM17.5 13.75H18.75H17.5ZM12.5 26.25V20C12.5 19.3096 13.0596 18.75 13.75 18.75H16.25C16.9404 18.75 17.5 19.3096 17.5 20V26.25H12.5Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    School
                                                </li>
                                            </NavLink> */}
                      <div
                        className={isActive ? "active" : "inactive"}
                        onClick={toggleActive}
                      >
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.75 5C13.75 3.61929 14.8693 2.5 16.25 2.5C17.6307 2.5 18.75 3.61929 18.75 5V6.25C18.75 6.94035 19.3096 7.5 20 7.5H23.75C24.4404 7.5 25 8.05965 25 8.75V12.5C25 13.1904 24.4404 13.75 23.75 13.75H22.5C21.1193 13.75 20 14.8693 20 16.25C20 17.6307 21.1193 18.75 22.5 18.75H23.75C24.4404 18.75 25 19.3096 25 20V23.75C25 24.4404 24.4404 25 23.75 25H20C19.3096 25 18.75 24.4404 18.75 23.75V22.5C18.75 21.1193 17.6307 20 16.25 20C14.8693 20 13.75 21.1193 13.75 22.5V23.75C13.75 24.4404 13.1904 25 12.5 25H8.75C8.05965 25 7.5 24.4404 7.5 23.75V20C7.5 19.3096 6.94035 18.75 6.25 18.75H5C3.61929 18.75 2.5 17.6307 2.5 16.25C2.5 14.8693 3.61929 13.75 5 13.75H6.25C6.94035 13.75 7.5 13.1904 7.5 12.5V8.75C7.5 8.05965 8.05965 7.5 8.75 7.5H12.5C13.1904 7.5 13.75 6.94035 13.75 6.25V5Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Products
                          {/* Adding a simple caret for the dropdown; style as desired */}
                          {showProductsSubmenu ? (
                            <span
                              className="icon-container"
                              style={{ height: "20px", width: "20px" }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 15L12 8L19 15"
                                  stroke="#4A5568"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              className="icon-container"
                              style={{ height: "20px", width: "20px" }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 9L12 16L5 9"
                                  stroke="#4A5568"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )}
                        </li>
                      </div>
                      {showProductsSubmenu && (
                        <div className="products-submenu">
                          <NavLink to="products">Products List</NavLink>
                          <NavLink to="product-category">
                            Product Categories
                          </NavLink>
                          {/* Add as many links as required */}
                        </div>
                      )}
                      {/* <NavLink to="reports" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.25 21.25V18.75V21.25ZM15 21.25V16.25V21.25ZM18.75 21.25V13.75V21.25ZM21.25 26.25H8.75C7.36929 26.25 6.25 25.1307 6.25 23.75V6.25C6.25 4.86929 7.36929 3.75 8.75 3.75H15.7323C16.0638 3.75 16.3818 3.8817 16.6161 4.11611L23.3839 10.8839C23.6182 11.1183 23.75 11.4363 23.75 11.7678V23.75C23.75 25.1307 22.6307 26.25 21.25 26.25Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Reports
                                    </li>
                                </NavLink>
                                <NavLink to="promo" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.25 17.5L18.75 10L11.25 17.5ZM11.875 10.625H11.8875H11.875ZM18.125 16.875H18.1375H18.125ZM23.75 26.25V6.25C23.75 4.86929 22.6307 3.75 21.25 3.75H8.75C7.36929 3.75 6.25 4.86929 6.25 6.25V26.25L10.625 23.75L15 26.25L19.375 23.75L23.75 26.25ZM12.5 10.625C12.5 10.9702 12.2202 11.25 11.875 11.25C11.5298 11.25 11.25 10.9702 11.25 10.625C11.25 10.2798 11.5298 10 11.875 10C12.2202 10 12.5 10.2798 12.5 10.625ZM18.75 16.875C18.75 17.2201 18.4701 17.5 18.125 17.5C17.7799 17.5 17.5 17.2201 17.5 16.875C17.5 16.5299 17.7799 16.25 18.125 16.25C18.4701 16.25 18.75 16.5299 18.75 16.875Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Promo Codes
                                    </li>
                                </NavLink> */}
                      {/* <NavLink to="account-settings" >
                                    <li>
                                        <div className="icon-container">
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.9057 5.39663C13.4387 3.20112 16.5612 3.20112 17.0942 5.39663C17.4385 6.81491 19.0634 7.48797 20.3097 6.72856C22.2391 5.55298 24.447 7.76087 23.2715 9.69022C22.512 10.9366 23.1851 12.5615 24.6034 12.9057C26.7989 13.4387 26.7989 16.5612 24.6034 17.0942C23.1851 17.4385 22.512 19.0634 23.2715 20.3097C24.447 22.2391 22.2391 24.447 20.3097 23.2715C19.0634 22.512 17.4385 23.1851 17.0942 24.6034C16.5612 26.7989 13.4387 26.7989 12.9057 24.6034C12.5615 23.1851 10.9366 22.512 9.69023 23.2715C7.76087 24.447 5.55298 22.2391 6.72856 20.3097C7.48797 19.0634 6.81491 17.4385 5.39663 17.0942C3.20112 16.5612 3.20112 13.4387 5.39663 12.9057C6.81491 12.5615 7.48796 10.9366 6.72856 9.69022C5.55298 7.76087 7.76087 5.55298 9.69023 6.72856C10.9366 7.48796 12.5615 6.81491 12.9057 5.39663Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15Z" stroke="#7E7E7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        Account Settings
                                    </li>
                                </NavLink> */}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="dashboard-main" id="dashboard-main">
        <Outlet></Outlet>
      </main>
    </>
  );
}

function DashboardCusotmerLayout() {
  const [isOpen, setOpen] = useState(false);
  window.onresize = Resize;

  const [profile, setProfile] = useState();
  const [fetched, setFetch] = useState(false);

  // Color Change Of Link
  const [link, setLink] = useState("/admin");
  const location = useLocation();

  useEffect(() => {
    Resize();
    setLink(location.pathname);
    setOpen(false);
    const fetchProfile = async () => {
      try {
        const data = await CustomerInfoApi();
        setProfile(data); // Make sure you also set the fetched data
        setFetch(true);
      } catch (error) {}
    };
    fetchProfile();
  }, [location]);

  const [showProductsSubmenu, setShowProductsSubmenu] = useState(false); // Using React's useState for submenu toggle
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setShowProductsSubmenu(!showProductsSubmenu);
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="slidebar" id="sidebar">
        <div className="content">
          <a href="/">
            <div className="logo-container">
              <img src="/images/logo.png" alt="" />
            </div>
          </a>
          <div className="navbar">
            <nav>
              <ul>
                <NavLink
                  to="/customer-dashboard"
                  className={
                    link == "/customer-dashboard" ? "active" : "unactive"
                  }
                >
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6.25C5 5.55965 5.55965 5 6.25 5H23.75C24.4404 5 25 5.55965 25 6.25V8.75C25 9.44035 24.4404 10 23.75 10H6.25C5.55965 10 5 9.44035 5 8.75V6.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 16.25C5 15.5596 5.55965 15 6.25 15H13.75C14.4404 15 15 15.5596 15 16.25V23.75C15 24.4404 14.4404 25 13.75 25H6.25C5.55965 25 5 24.4404 5 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 16.25C20 15.5596 20.5596 15 21.25 15H23.75C24.4404 15 25 15.5596 25 16.25V23.75C25 24.4404 24.4404 25 23.75 25H21.25C20.5596 25 20 24.4404 20 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Dashboard
                  </li>
                </NavLink>
                <NavLink to="order">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 6.25H8.75C7.36929 6.25 6.25 7.36929 6.25 8.75V23.75C6.25 25.1307 7.36929 26.25 8.75 26.25H21.25C22.6307 26.25 23.75 25.1307 23.75 23.75V8.75C23.75 7.36929 22.6307 6.25 21.25 6.25H18.75M11.25 6.25C11.25 7.63071 12.3693 8.75 13.75 8.75H16.25C17.6307 8.75 18.75 7.63071 18.75 6.25M11.25 6.25C11.25 4.86929 12.3693 3.75 13.75 3.75H16.25C17.6307 3.75 18.75 4.86929 18.75 6.25M11.25 6.25H12.5M18.75 6.25H12.5M15 15H18.75M15 20H18.75M11.25 15H11.2625M11.25 20H11.2625M12.5 6.25V4.5H17.5V8H12.5V6.25Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Orders
                  </li>
                </NavLink>
                <NavLink to="wallet">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.875 10H13.75M19.375 10H13.75M15.625 21.25L11.875 17.5H13.125C15.1961 17.5 16.875 15.8211 16.875 13.75C16.6667 12.5 15.75 10 13.75 10M11.875 13.75H19.375M26.875 15C26.875 21.2133 21.8383 26.25 15.625 26.25C9.4118 26.25 4.375 21.2133 4.375 15C4.375 8.7868 9.4118 3.75 15.625 3.75C21.8383 3.75 26.875 8.7868 26.875 15Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Wallet
                  </li>
                </NavLink>
                <NavLink to="milestone">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 25H27.5V22.5C27.5 20.4289 25.8211 18.75 23.75 18.75C22.5555 18.75 21.4914 19.3085 20.8048 20.1786M21.25 25L20.8048 20.1786M21.25 25H8.75M21.25 25V22.5C21.25 21.6798 21.092 20.8963 20.8048 20.1786M20.8048 20.1786C19.8831 17.8763 17.6315 16.25 15 16.25C12.3685 16.25 10.1169 17.8763 9.19531 20.1786M8.75 25H2.5V22.5C2.5 20.4289 4.17894 18.75 6.25 18.75C7.44451 18.75 8.50857 19.3085 9.19531 20.1786M8.75 25L9.19531 20.1786M8.75 25V22.5C8.75 21.6798 8.90802 20.8963 9.19531 20.1786M18.75 8.75C18.75 10.8211 17.0711 12.5 15 12.5C12.9289 12.5 11.25 10.8211 11.25 8.75C11.25 6.67894 12.9289 5 15 5C17.0711 5 18.75 6.67894 18.75 8.75ZM26.25 12.5C26.25 13.8807 25.1307 15 23.75 15C22.3693 15 21.25 13.8807 21.25 12.5C21.25 11.1193 22.3693 10 23.75 10C25.1307 10 26.25 11.1193 26.25 12.5ZM8.75 12.5C8.75 13.8807 7.63071 15 6.25 15C4.86929 15 3.75 13.8807 3.75 12.5C3.75 11.1193 4.86929 10 6.25 10C7.63071 10 8.75 11.1193 8.75 12.5Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Milestone
                  </li>
                </NavLink>
                <NavLink to="account-settings">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9057 5.39663C13.4387 3.20112 16.5612 3.20112 17.0942 5.39663C17.4385 6.81491 19.0634 7.48797 20.3097 6.72856C22.2391 5.55298 24.447 7.76087 23.2715 9.69022C22.512 10.9366 23.1851 12.5615 24.6034 12.9057C26.7989 13.4387 26.7989 16.5612 24.6034 17.0942C23.1851 17.4385 22.512 19.0634 23.2715 20.3097C24.447 22.2391 22.2391 24.447 20.3097 23.2715C19.0634 22.512 17.4385 23.1851 17.0942 24.6034C16.5612 26.7989 13.4387 26.7989 12.9057 24.6034C12.5615 23.1851 10.9366 22.512 9.69023 23.2715C7.76087 24.447 5.55298 22.2391 6.72856 20.3097C7.48797 19.0634 6.81491 17.4385 5.39663 17.0942C3.20112 16.5612 3.20112 13.4387 5.39663 12.9057C6.81491 12.5615 7.48796 10.9366 6.72856 9.69022C5.55298 7.76087 7.76087 5.55298 9.69023 6.72856C10.9366 7.48796 12.5615 6.81491 12.9057 5.39663Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Account Settings
                  </li>
                </NavLink>
              </ul>
            </nav>
          </div>
          <LogoutButton />
        </div>
      </div>
      <div className="header" id="header">
        <div className="content">
          <div className="login-btn">
            <div className="icon-container">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 23.75H20M6.4013 22.2546C8.94084 20.8192 11.8747 20 15 20C18.1253 20 21.0591 20.8192 23.5987 22.2546H15H6.4013ZM18.75 12.5C18.75 14.5711 17.0711 16.25 15 16.25C12.9289 16.25 11.25 14.5711 11.25 12.5C11.25 10.4289 12.9289 8.75 15 8.75C17.0711 8.75 18.75 10.4289 18.75 12.5ZM26.25 15C26.25 21.2133 21.2133 26.25 15 26.25C8.7868 26.25 3.75 21.2133 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2133 3.75 26.25 8.7868 26.25 15Z"
                  stroke="#7E7E7E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 20.5H20.5L21.5 23.5L16 26L10.5 25.5V20.5Z"
                  fill="#7E7E7E"
                />
              </svg>
            </div>
            {fetched ? <>{profile.name}</> : <></>}
          </div>
          <div className="mobile-header">
            <div className="left">
              <a href="/">
                <div className="logo-container">
                  <img src="/images/logo.png" alt="" />
                </div>
              </a>
            </div>
            <div className="right">
              <Hamburger rounded toggled={isOpen} toggle={setOpen} />
              <div
                className={
                  isOpen ? "active mobile-list" : "inactive mobile-list"
                }
              >
                <a href="/">
                  <div className="logo-container">
                    <img src="/images/logo.png" alt="" />
                  </div>
                </a>

                <div className="navbar">
                  <nav>
                    <ul>
                      <NavLink
                        to="/customer-dashboard"
                        className={
                          link == "/customer-dashboard" ? "active" : "unactive"
                        }
                      >
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 6.25C5 5.55965 5.55965 5 6.25 5H23.75C24.4404 5 25 5.55965 25 6.25V8.75C25 9.44035 24.4404 10 23.75 10H6.25C5.55965 10 5 9.44035 5 8.75V6.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 16.25C5 15.5596 5.55965 15 6.25 15H13.75C14.4404 15 15 15.5596 15 16.25V23.75C15 24.4404 14.4404 25 13.75 25H6.25C5.55965 25 5 24.4404 5 23.75V16.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 16.25C20 15.5596 20.5596 15 21.25 15H23.75C24.4404 15 25 15.5596 25 16.25V23.75C25 24.4404 24.4404 25 23.75 25H21.25C20.5596 25 20 24.4404 20 23.75V16.25Z"
                                stroke="#4B9E99"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Dashboard
                        </li>
                      </NavLink>
                      <NavLink to="order">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.25 6.25H8.75C7.36929 6.25 6.25 7.36929 6.25 8.75V23.75C6.25 25.1307 7.36929 26.25 8.75 26.25H21.25C22.6307 26.25 23.75 25.1307 23.75 23.75V8.75C23.75 7.36929 22.6307 6.25 21.25 6.25H18.75M11.25 6.25C11.25 7.63071 12.3693 8.75 13.75 8.75H16.25C17.6307 8.75 18.75 7.63071 18.75 6.25M11.25 6.25C11.25 4.86929 12.3693 3.75 13.75 3.75H16.25C17.6307 3.75 18.75 4.86929 18.75 6.25M11.25 6.25H12.5M18.75 6.25H12.5M15 15H18.75M15 20H18.75M11.25 15H11.2625M11.25 20H11.2625M12.5 6.25V4.5H17.5V8H12.5V6.25Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Orders
                        </li>
                      </NavLink>
                      <NavLink to="wallet">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.875 10H13.75M19.375 10H13.75M15.625 21.25L11.875 17.5H13.125C15.1961 17.5 16.875 15.8211 16.875 13.75C16.6667 12.5 15.75 10 13.75 10M11.875 13.75H19.375M26.875 15C26.875 21.2133 21.8383 26.25 15.625 26.25C9.4118 26.25 4.375 21.2133 4.375 15C4.375 8.7868 9.4118 3.75 15.625 3.75C21.8383 3.75 26.875 8.7868 26.875 15Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Wallet
                        </li>
                      </NavLink>
                      <NavLink to="milestone">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.25 25H27.5V22.5C27.5 20.4289 25.8211 18.75 23.75 18.75C22.5555 18.75 21.4914 19.3085 20.8048 20.1786M21.25 25L20.8048 20.1786M21.25 25H8.75M21.25 25V22.5C21.25 21.6798 21.092 20.8963 20.8048 20.1786M20.8048 20.1786C19.8831 17.8763 17.6315 16.25 15 16.25C12.3685 16.25 10.1169 17.8763 9.19531 20.1786M8.75 25H2.5V22.5C2.5 20.4289 4.17894 18.75 6.25 18.75C7.44451 18.75 8.50857 19.3085 9.19531 20.1786M8.75 25L9.19531 20.1786M8.75 25V22.5C8.75 21.6798 8.90802 20.8963 9.19531 20.1786M18.75 8.75C18.75 10.8211 17.0711 12.5 15 12.5C12.9289 12.5 11.25 10.8211 11.25 8.75C11.25 6.67894 12.9289 5 15 5C17.0711 5 18.75 6.67894 18.75 8.75ZM26.25 12.5C26.25 13.8807 25.1307 15 23.75 15C22.3693 15 21.25 13.8807 21.25 12.5C21.25 11.1193 22.3693 10 23.75 10C25.1307 10 26.25 11.1193 26.25 12.5ZM8.75 12.5C8.75 13.8807 7.63071 15 6.25 15C4.86929 15 3.75 13.8807 3.75 12.5C3.75 11.1193 4.86929 10 6.25 10C7.63071 10 8.75 11.1193 8.75 12.5Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Milestone
                        </li>
                      </NavLink>
                      <NavLink to="account-settings">
                        <li>
                          <div className="icon-container">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.9057 5.39663C13.4387 3.20112 16.5612 3.20112 17.0942 5.39663C17.4385 6.81491 19.0634 7.48797 20.3097 6.72856C22.2391 5.55298 24.447 7.76087 23.2715 9.69022C22.512 10.9366 23.1851 12.5615 24.6034 12.9057C26.7989 13.4387 26.7989 16.5612 24.6034 17.0942C23.1851 17.4385 22.512 19.0634 23.2715 20.3097C24.447 22.2391 22.2391 24.447 20.3097 23.2715C19.0634 22.512 17.4385 23.1851 17.0942 24.6034C16.5612 26.7989 13.4387 26.7989 12.9057 24.6034C12.5615 23.1851 10.9366 22.512 9.69023 23.2715C7.76087 24.447 5.55298 22.2391 6.72856 20.3097C7.48797 19.0634 6.81491 17.4385 5.39663 17.0942C3.20112 16.5612 3.20112 13.4387 5.39663 12.9057C6.81491 12.5615 7.48796 10.9366 6.72856 9.69022C5.55298 7.76087 7.76087 5.55298 9.69023 6.72856C10.9366 7.48796 12.5615 6.81491 12.9057 5.39663Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15Z"
                                stroke="#7E7E7E"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          Account Settings
                        </li>
                      </NavLink>
                      <LogoutButton />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="dashboard-main" id="dashboard-main">
        <Outlet></Outlet>
      </main>
    </>
  );
}

function DashboardSchoolLayout() {
  const [isOpen, setOpen] = useState(false);

  window.onresize = Resize;
  useEffect(() => {
    Resize();
  }, []);

  // Color Change Of Link
  const [link, setLink] = useState("/customer-dashboard");
  const location = useLocation();

  useEffect(() => {
    setLink(location.pathname);
  }, [location]);

  return (
    <>
      <div className="slidebar" id="sidebar">
        <div className="content">
          <div className="logo-container">
            <img src="/images/logo.png" alt="" />
          </div>
          <div className="navbar">
            <nav>
              <ul>
                <NavLink
                  to="/school-dashboard"
                  className={
                    link == "/school-dashboard" ? "active" : "unactive"
                  }
                >
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 6.25C5 5.55965 5.55965 5 6.25 5H23.75C24.4404 5 25 5.55965 25 6.25V8.75C25 9.44035 24.4404 10 23.75 10H6.25C5.55965 10 5 9.44035 5 8.75V6.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5 16.25C5 15.5596 5.55965 15 6.25 15H13.75C14.4404 15 15 15.5596 15 16.25V23.75C15 24.4404 14.4404 25 13.75 25H6.25C5.55965 25 5 24.4404 5 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 16.25C20 15.5596 20.5596 15 21.25 15H23.75C24.4404 15 25 15.5596 25 16.25V23.75C25 24.4404 24.4404 25 23.75 25H21.25C20.5596 25 20 24.4404 20 23.75V16.25Z"
                          stroke="#4B9E99"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Dashboard
                  </li>
                </NavLink>

                <NavLink to="payouts">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.875 10H13.75M19.375 10H13.75M15.625 21.25L11.875 17.5H13.125C15.1961 17.5 16.875 15.8211 16.875 13.75C16.6667 12.5 15.75 10 13.75 10M11.875 13.75H19.375M26.875 15C26.875 21.2133 21.8383 26.25 15.625 26.25C9.4118 26.25 4.375 21.2133 4.375 15C4.375 8.7868 9.4118 3.75 15.625 3.75C21.8383 3.75 26.875 8.7868 26.875 15Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Payouts
                  </li>
                </NavLink>
                <NavLink to="student-list">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 25H27.5V22.5C27.5 20.4289 25.8211 18.75 23.75 18.75C22.5555 18.75 21.4914 19.3085 20.8048 20.1786M21.25 25L20.8048 20.1786M21.25 25H8.75M21.25 25V22.5C21.25 21.6798 21.092 20.8963 20.8048 20.1786M20.8048 20.1786C19.8831 17.8763 17.6315 16.25 15 16.25C12.3685 16.25 10.1169 17.8763 9.19531 20.1786M8.75 25H2.5V22.5C2.5 20.4289 4.17894 18.75 6.25 18.75C7.44451 18.75 8.50857 19.3085 9.19531 20.1786M8.75 25L9.19531 20.1786M8.75 25V22.5C8.75 21.6798 8.90802 20.8963 9.19531 20.1786M18.75 8.75C18.75 10.8211 17.0711 12.5 15 12.5C12.9289 12.5 11.25 10.8211 11.25 8.75C11.25 6.67894 12.9289 5 15 5C17.0711 5 18.75 6.67894 18.75 8.75ZM26.25 12.5C26.25 13.8807 25.1307 15 23.75 15C22.3693 15 21.25 13.8807 21.25 12.5C21.25 11.1193 22.3693 10 23.75 10C25.1307 10 26.25 11.1193 26.25 12.5ZM8.75 12.5C8.75 13.8807 7.63071 15 6.25 15C4.86929 15 3.75 13.8807 3.75 12.5C3.75 11.1193 4.86929 10 6.25 10C7.63071 10 8.75 11.1193 8.75 12.5Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Student List
                  </li>
                </NavLink>
                <NavLink to="account-settings">
                  <li>
                    <div className="icon-container">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9057 5.39663C13.4387 3.20112 16.5612 3.20112 17.0942 5.39663C17.4385 6.81491 19.0634 7.48797 20.3097 6.72856C22.2391 5.55298 24.447 7.76087 23.2715 9.69022C22.512 10.9366 23.1851 12.5615 24.6034 12.9057C26.7989 13.4387 26.7989 16.5612 24.6034 17.0942C23.1851 17.4385 22.512 19.0634 23.2715 20.3097C24.447 22.2391 22.2391 24.447 20.3097 23.2715C19.0634 22.512 17.4385 23.1851 17.0942 24.6034C16.5612 26.7989 13.4387 26.7989 12.9057 24.6034C12.5615 23.1851 10.9366 22.512 9.69023 23.2715C7.76087 24.447 5.55298 22.2391 6.72856 20.3097C7.48797 19.0634 6.81491 17.4385 5.39663 17.0942C3.20112 16.5612 3.20112 13.4387 5.39663 12.9057C6.81491 12.5615 7.48796 10.9366 6.72856 9.69022C5.55298 7.76087 7.76087 5.55298 9.69023 6.72856C10.9366 7.48796 12.5615 6.81491 12.9057 5.39663Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.75 15C18.75 17.0711 17.0711 18.75 15 18.75C12.9289 18.75 11.25 17.0711 11.25 15C11.25 12.9289 12.9289 11.25 15 11.25C17.0711 11.25 18.75 12.9289 18.75 15Z"
                          stroke="#7E7E7E"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    Account Settings
                  </li>
                </NavLink>
              </ul>
            </nav>
          </div>
          <LogoutButton />
        </div>
      </div>
      <div className="header" id="header">
        <div className="content">
          <div className="login-btn">
            <div className="icon-container">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 23.75H20M6.4013 22.2546C8.94084 20.8192 11.8747 20 15 20C18.1253 20 21.0591 20.8192 23.5987 22.2546H15H6.4013ZM18.75 12.5C18.75 14.5711 17.0711 16.25 15 16.25C12.9289 16.25 11.25 14.5711 11.25 12.5C11.25 10.4289 12.9289 8.75 15 8.75C17.0711 8.75 18.75 10.4289 18.75 12.5ZM26.25 15C26.25 21.2133 21.2133 26.25 15 26.25C8.7868 26.25 3.75 21.2133 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2133 3.75 26.25 8.7868 26.25 15Z"
                  stroke="#7E7E7E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 20.5H20.5L21.5 23.5L16 26L10.5 25.5V20.5Z"
                  fill="#7E7E7E"
                />
              </svg>
            </div>
            Welcome Vishal
          </div>
        </div>
      </div>
      <main className="dashboard-main" id="dashboard-main">
        <Outlet></Outlet>
      </main>
    </>
  );
}

export { DashboardLayout, DashboardCusotmerLayout, DashboardSchoolLayout };
