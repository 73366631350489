import React, {useEffect, useState } from 'react'

// Components
import SubscriptionCard from '../components/SubscriptionCard'

// API
import {SubscriptionAPI} from '../../globalAPIs/SubscriptionApi';


// CSS
import '../style/pages/pricing/pricing.css';


function Subscription() {
    const [pricing, setSubscription] = useState();
    
    useEffect(() => {
        const fetchSubscription =async ()=>{
            try{
                const data = await SubscriptionAPI();
                console.log(data)
                setSubscription(data.results)
            }catch(error){

            }
        }
        fetchSubscription();
    }, []);
  

    return (
        <>
            <section className='container subscription-container mt-0'>
                <div className="title">
                    <h2>
                        Our Affordable Pricing
                    </h2>
                </div>
                <div className="grid-container">
                    {pricing?(
                        <>
                            {pricing.map((e) => {
                                return (
                                    <SubscriptionCard
                                        icon={e.image}
                                        name={e.name}
                                        description={e.description}
                                        price={e.subscrption_fees}
                                        total={e.price}
                                        duration={e.total_month}
                                        tokens={e.total_gg_token}
                                        deposit={e.refundable_deposit}
                                        registeration={e.registration_fees}
                                        id={e.id}
                                        key={e.id}
                                        cta={'Subscribe'}
                                    />
                                );
                            })}
                        </>
                    ):(<></>)}
                    
                </div>
            </section>

        </>
    )
}

export default Subscription