import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { BASE_API_URL } from '../../../server';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner'
import { useAppContext } from '../../../web/context/UserContext';
import { EditCustomerApi } from '../../admin/api/CustomerApi';



// CSS

const minStartDate = dayjs().add(2, 'day');
const maxStartDate = dayjs().add(7, 'day');
const tomorrow = dayjs().add(2, 'day');

function RetainCard({ handleClose, setHasRetainedToday, ...props }) {
	const [loadingAPI, setLoadingAPI] = useState(false)

	const [date, setDate] = useState(dayjs(props.date).add(1, 'day'));
	const [days, setDays] = useState(props.days); // Assuming you're getting days as prop
	const [price, setPrice] = useState(props.price);
	const [cart_total, setCartTotal] = useState(props.cart_total);

	const { customer } = useAppContext();
	const { isSuperUser } = useAppContext();

	const todayDate = new Date().toISOString().split('T')[0];

	const updateCartItem = async (newDays, selectedDate) => {
		try {

		} catch (error) {
			console.error("Error updating cart item:", error);
		}
	};

	const updateDays = async (action) => {
		const allowedDays = [1, 3, 7, 14];
		const currentIndex = allowedDays.indexOf(days);
		let newIndex;
		if (action === 'add') {
			newIndex = (currentIndex + 1) % allowedDays.length;
		} else if (action === 'subtract') {
			newIndex = currentIndex - 1;
			if (newIndex < 0) {
				newIndex = allowedDays.length - 1;
			}
		} else {
			return;
		}
		const newDays = allowedDays[newIndex];
		await updateCartItem(newDays, date);
		await setDays(newDays);
		if (newDays === 1) {
			setPrice(props.price_1)
		}
		if (newDays === 3) {
			setPrice(props.price_3)
		}
		if (newDays === 7) {
			setPrice(props.price_7)
		}
		if (newDays === 14) {
			setPrice(props.price_14)
		}
	};

	const extendToy = async (id) => {
		if (price > props?.user?.profile?.gg_token + props?.user?.profile?.topup_token) {
			Swal.fire({
				icon: 'error',
				title: 'Insufficient balance',
				text: 'User does not have enough GG Token',
				showConfirmButton: false,
				timer: 1500
			})

			return;
		}
		setLoadingAPI(true);

		let bal =  props?.user?.profile?.gg_token - price
		let updated_toupToken;
		let updated_GGToken;
		if (bal < 0) {
			updated_toupToken =  props?.user?.profile?.topup_token + bal
			updated_GGToken = 0
		} else {
			updated_GGToken = bal
			updated_toupToken =  props?.user?.profile?.topup_token
		}

		const updatedtoken = {
			"gg_token": updated_GGToken,
			"topup_token": updated_toupToken
		}

		

		const apiEndpoint = `${BASE_API_URL}api/extend/` + id;
		try {
			const response = await axios.post(apiEndpoint,
				{
					ctm: id,
					days: days,
				}, {
				withCredentials: true
			});

			setHasRetainedToday(true);
			localStorage.setItem('lastRetainedDate', todayDate);

			await EditCustomerApi(updatedtoken, props?.user?.profile?.id)
			Swal.fire({
				icon: 'success',
				title: 'Order Confirmed',
				text: 'your order has been successfully placed',
				showConfirmButton: true,

			}).then(function () {

				if (customer) {
					window.location = "/customer-dashboard/order";  // This will redirect to the root ("/") path of your application
				}

				if (isSuperUser) {
					window.location = "/admin/order";  // This will redirect to the root ("/") path of your application
				}

				setLoadingAPI(false);
			});
			return response

		} catch (error) {
			console.log('***** ERROR in Fetching Order *****');
			console.log(error);
			console.log('***************************************');
			// Additional error handling as needed
			setLoadingAPI(false);
		}

	};

	console.log('retain order', props, price)

	return (
		<>
			<div className="mobile-cart-card" style={{ display: 'block' }}>
				<div className="cart-img-container">
					<div className="img-container">
						<img src={"https://api.growinggiraffe.com/" + props.img} alt="" />
					</div>

				</div>
				<div className="cart-name">
					{props.name}
				</div>
				<div className="date-counter-container">
					<div className="counter">
						<div className="button minus-button" onClick={() => updateDays('subtract')}>-</div>
						<div className="input">
							<div className='small-text'>Days</div>
							<input type="text" value={days} disabled />
						</div>
						<div className="button add-button" onClick={() => updateDays('add')}>+</div>
					</div>
				</div>
				<div className="price-container">
					<div className='small-text'>Total:</div>
					<div className="price">
						<div className="icon-container">
							<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_763_2153)">
									<path d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97717 15.5228 0.5 10 0.5C4.47717 0.5 0 4.97717 0 10.5C0 16.0228 4.47717 20.5 10 20.5Z" fill="#F6D738" />
									<path d="M10.0001 18.9874C14.6872 18.9874 18.4869 15.1877 18.4869 10.5005C18.4869 5.81336 14.6872 2.01367 10.0001 2.01367C5.31287 2.01367 1.51318 5.81336 1.51318 10.5005C1.51318 15.1877 5.31287 18.9874 10.0001 18.9874Z" fill="#F3CC30" />
									<path d="M14.4403 3.25586C15.3146 4.62006 15.7781 6.20696 15.7755 7.82727C15.7755 12.5145 11.9758 16.3141 7.28861 16.3141C5.72092 16.3165 4.18347 15.8826 2.84839 15.0609C3.61547 16.2618 4.67283 17.2501 5.92278 17.9344C7.17273 18.6188 8.575 18.9771 10 18.9763C14.6872 18.9763 18.4869 15.1766 18.4869 10.4894C18.4876 9.0382 18.1159 7.61112 17.4074 6.34463C16.6989 5.07814 15.6773 4.01464 14.4403 3.25586Z" fill="#EDBD31" />
									<path d="M2.99978 11.2758C2.99978 7.13825 6.35392 3.7841 10.4915 3.7841C11.6394 3.78316 12.7722 4.0465 13.802 4.55368C14.8318 5.06089 15.7311 5.79834 16.4301 6.70892C15.7736 5.56644 14.8273 4.61736 13.6867 3.95759C12.546 3.29778 11.2515 2.95063 9.93385 2.95117C5.79631 2.95117 2.44215 6.30533 2.44215 10.4429C2.4397 12.0952 2.98598 13.7015 3.99524 15.0097C3.3411 13.8742 2.99775 12.5863 2.99978 11.2758Z" fill="#F7DB5E" />
									<path d="M8.01573 9.43658C7.9265 9.28959 7.80313 9.17673 7.64565 9.09799C7.49343 9.01925 7.31233 8.97988 7.10234 8.97988C6.7139 8.97988 6.40682 9.10587 6.18108 9.35784C5.96061 9.60981 5.85038 9.94839 5.85038 10.3736C5.85038 10.8513 5.96849 11.2161 6.20471 11.4681C6.44619 11.7148 6.79526 11.8382 7.25195 11.8382C7.79264 11.8382 8.17847 11.5941 8.40943 11.1059H6.85825V9.99563H9.74014V11.4917C9.61942 11.7856 9.44093 12.0612 9.20471 12.3185C8.97375 12.5757 8.67976 12.7883 8.32282 12.9563C7.96587 13.119 7.55904 13.2004 7.10234 13.2004C6.5459 13.2004 6.05249 13.0822 5.62203 12.846C5.19683 12.6045 4.86612 12.2712 4.6299 11.846C4.39894 11.4156 4.28345 10.9248 4.28345 10.3736C4.28345 9.82766 4.39894 9.34209 4.6299 8.91689C4.86612 8.48644 5.19683 8.15311 5.62203 7.91689C6.04723 7.67541 6.53803 7.55469 7.09447 7.55469C7.79264 7.55469 8.37006 7.72266 8.82675 8.05862C9.28345 8.39459 9.56691 8.8539 9.67715 9.43658H8.01573ZM13.9751 9.43658C13.8858 9.28959 13.7625 9.17673 13.605 9.09799C13.4528 9.01925 13.2717 8.97988 13.0617 8.97988C12.6732 8.97988 12.3662 9.10587 12.1404 9.35784C11.92 9.60981 11.8097 9.94839 11.8097 10.3736C11.8097 10.8513 11.9278 11.2161 12.164 11.4681C12.4055 11.7148 12.7546 11.8382 13.2113 11.8382C13.752 11.8382 14.1378 11.5941 14.3688 11.1059H12.8176V9.99563H15.6995V11.4917C15.5788 11.7856 15.4003 12.0612 15.164 12.3185C14.9331 12.5757 14.6391 12.7883 14.2822 12.9563C13.9252 13.119 13.5184 13.2004 13.0617 13.2004C12.5052 13.2004 12.0118 13.0822 11.5814 12.846C11.1562 12.6045 10.8255 12.2712 10.5892 11.846C10.3583 11.4156 10.2428 10.9248 10.2428 10.3736C10.2428 9.82766 10.3583 9.34209 10.5892 8.91689C10.8255 8.48644 11.1562 8.15311 11.5814 7.91689C12.0066 7.67541 12.4974 7.55469 13.0538 7.55469C13.752 7.55469 14.3294 7.72266 14.7861 8.05862C15.2428 8.39459 15.5262 8.8539 15.6365 9.43658H13.9751Z" fill="#D49A34" />
									<path d="M7.73443 14.7019V15.0163H7.35546V16.123H6.96038V15.0163H6.58545V14.7019H7.73443ZM8.56192 16.1371C8.42888 16.1371 8.30658 16.1062 8.19506 16.0443C8.08353 15.9812 7.99484 15.8945 7.92898 15.7843C7.86447 15.6728 7.83222 15.5471 7.83222 15.4074C7.83222 15.2676 7.86447 15.1426 7.92898 15.0324C7.99484 14.9209 8.08353 14.8342 8.19506 14.7724C8.30658 14.7106 8.42888 14.6797 8.56192 14.6797C8.69632 14.6797 8.81858 14.7106 8.92879 14.7724C9.04032 14.8342 9.12835 14.9209 9.19285 15.0324C9.25735 15.1426 9.28961 15.2676 9.28961 15.4074C9.28961 15.5471 9.25735 15.6728 9.19285 15.7843C9.12835 15.8945 9.04032 15.9812 8.92879 16.0443C8.81726 16.1062 8.69496 16.1371 8.56192 16.1371ZM8.56192 15.7702C8.66271 15.7702 8.74198 15.7373 8.79978 15.6714C8.8589 15.6056 8.88847 15.5176 8.88847 15.4074C8.88847 15.2945 8.8589 15.2058 8.79978 15.1413C8.74198 15.0754 8.66271 15.0425 8.56192 15.0425C8.45978 15.0425 8.37984 15.0754 8.32205 15.1413C8.26425 15.2058 8.23537 15.2945 8.23537 15.4074C8.23537 15.5189 8.26425 15.6076 8.32205 15.6735C8.37984 15.738 8.45978 15.7702 8.56192 15.7702ZM10.3078 16.123L9.8623 15.5001V16.123H9.46721V14.7019H9.8623V15.3146L10.3037 14.7019H10.7593L10.2372 15.3912L10.7855 16.123H10.3078ZM11.3249 15.0183V15.2501H11.7785V15.5505H11.3249V15.8065H11.8389V16.123H10.9298V14.7019H11.8389V15.0183H11.3249ZM13.3504 16.123H12.9553L12.4292 15.3288V16.123H12.0341V14.7019H12.4292L12.9553 15.5061V14.7019H13.3504V16.123Z" fill="#D49A34" />
								</g>

							</svg>
						</div>
						{price}
					</div>
				</div>

			</div>
			<div className="button green" onClick={() => extendToy(props.id)}>
				Retain Now
			</div>
			{loadingAPI && <>
				<div className=' bg-black'>
					<ColorRing type="Puff" colors={['#4B9E99', '#4B9E99', '#4B9E99', '#4B9E99', '#4B9E99']} height={100} width={100} />
				</div>
			</>}
		</>
	)
}

export default RetainCard