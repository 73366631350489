import React, { useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Form
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';


// BOX
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'red' : 'white',
    ...theme.typography.body2,
    padding: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #E7E7E7',
    color: 'black',
    height: '100%',
}));

function AddSubcription() {
    const [imageUrl, setImageUrl] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };
    return (
        <>
            <div className="title-container">
                <h5>Add Order</h5>
            </div>

            {/* Order Add Form */}
            <Box sx={{ flecGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Item className="popular-box">
                            <form action="">
                                {/* Customer Details */}
                                <div className='name'>
                                    Subscription Details
                                </div>
                                <div className='form-group-container'>
                                    <div className='form-group'>
                                        <label htmlFor="upload-image" className='img-upload'>
                                            <div className="upload-btn">
                                                upload your image
                                                <div className="img-container">
                                                    {imageUrl && <img src={imageUrl} alt="Uploaded Image" height="300" />}
                                                </div>
                                            </div>
                                            <input
                                                id="upload-image"
                                                hidden
                                                accept="image/*"
                                                type="file"
                                                onChange={handleFileUpload}
                                            />

                                        </label>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className='form-group mt-20'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="plan Name" color="green" label="Plan Name" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="price" color="green" label="Price (in GG tokens)" variant="outlined" />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className='form-group mt-20'>
                                        <FormControl fullWidth color="green">
                                            <TextField key="duration" color="green" label="Duration(in Months)" variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth color="green">
                                            <TextField key="deposit" color="green" label="Refundable deposit" variant="outlined" />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="form-group-container">
                                    <div className='form-group mt-20'>
                                        <input type="submit" value="Add Subscription"  className='button green'/>                                     
                                    </div>
                                </div>
                            </form>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            {/* Order Add Form */}
        </>
    )
}

export default AddSubcription