import axios from "axios";
import { BASE_API_URL } from "../../../server";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export const CTMApi = async (values, id, days, date, newCost) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/customertoymapping/${id}/change/`;
  const adjustedDate = dayjs(date).add(5, "hour").add(30, "minute");

  try {
    const response = await axios.put(
      apiEndpoint,
      {
        desired_delivery_date: adjustedDate.toISOString().split("T")[0],
        days: days,
        paid_amount: newCost,
      },
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    console.log("***** ERROR in Fetching Age *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const EditStatusCTMApi = async (status, id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/customertoymapping/${id}/change/`;

  try {
    const response = await axios.put(
      apiEndpoint,
      {
        status: status,
      },
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    console.log("***** ERROR in Fetching Age *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AddCtmApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/customertoymapping/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    Swal.fire({
      title: "Opps!",
      text: "Your action was failed too much traffic on the server.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

export const EditCTMApi = async (id, status) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/customertoymapping/${id}/change/`;

  try {
    const response = await axios.put(
      apiEndpoint,
      {
        status: status,
      },
      { withCredentials: true }
    );
    return response;
  } catch (error) {
    console.log("***** ERROR in Fetching Age *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

//add new order product
export const AllProductApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/list/?page_size=1000000000000000000&depth=1`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const data = {
      current_page: 1,
      prev: response.data.links.previous,
      next: response.data.links.next,
      pages: response.data.links.pages,
      total_count: response.data.total_items,
      page_size: response.data.page_size,
    };
    const result = response.data.results.map((item) => ({
      id: item.id,
      product_img: `${BASE_API_URL}` + item.image,
      name: item.name,
      small_description: item.small_description,
      slug: item.slug,
      one: item.price_1,
      three: item.price_3,
      seven: item.price_7,
      fourteen: item.price_14,
      deposit: item.deposit,
      is_featured: item.is_featured,
      inventory: item.available_stock,
      renvenue_generated: "", // This field is not available in the API response
      number_of_days_booked: "", // This field is not available in the API response
      total_in_wishlist: item.total_wishlist,
      available_stock: item.available_stock,
      stock_status: item.available_stock > 0 ? true : false,
      order: item.order_by,
      sku: item.sku,
      stock: item.total_stock,
      categories: item.category, // You might need to adjust this based on the actual structure of the category field
    }));
    const ProductData = {
      result,
      data,
    };

    return ProductData;
  } catch (error) {
    console.log("***** ERROR in Fetching Products *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};
