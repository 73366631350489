import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../server'

const ProductListAPI = `${BASE_API_URL}api/product/`;



export const ProductAPI=async(url)=>{
 try {
        const response = await axios.get(ProductListAPI+'?'+url);
        const data = {
            current_page:1,
            prev:response.data.links.prev,
            next:response.data.links.next,
            pages:response.data.links.pages,
            total_count:response.data.total_count,
            page_size:response.data.page_size
        }
       
        const result = response.data.results.map(item => ({
            id: item.id,
            image: item.image,
            name: item.name,
            small_description: item.small_description,
            slug: item.slug,
            price:item.price,
            one: item.price_1,
            three: item.price_3,
            seven: item.price_7,
            fourteen: item.price_14,
            deposit: item.deposit,
            is_featured: item.is_featured,
            inventory: item.available_stock,
            renvenue_generated: '', // This field is not available in the API response
            number_of_days_booked: '', // This field is not available in the API response
            total_in_wishlist: item.total_wishlist,
            available_stock: item.available_stock ,
            is_available: item.is_available,
            total_stock: item.total_stock,
            order: item.order_by,
            sku: item.sku,
            categories: item.category, // You might need to adjust this based on the actual structure of the category field
            age:item.age,
            extra:item.extra,
            age_labels: item.age_data.map(age => age.label), // Extracting age labels

        }
        ))
        const ProductData ={
            result,
            data,
        } 
        
        return ProductData;
    } catch (error) {
        console.error('Error fetching product data:', error);
        throw error; // rethrow the error so you can catch it in the calling function
    }
}

export const PopularProductAPI=async()=>{
    try {
           const response = await axios.get(ProductListAPI+'?&is_featured=true&page_size=100');
           const data = {
               prev:response.data.previous,
               next:response.data.next,
           }
           const result = response.data.results.map(item => ({
               id: item.id,
               image: item.image,
               name: item.name,
               small_description: item.small_description,
               deposit: item.deposit,
               slug: item.slug,
               price:item.price,
               one: item.price_1,
               three: item.price_3,
               seven: item.price_7,
               fourteen: item.price_14,
               is_featured: item.is_featured,
               inventory: item.available_stock,
               renvenue_generated: '', // This field is not available in the API response
               number_of_days_booked: '', // This field is not available in the API response
               total_in_wishlist: item.total_wishlist,
               available_stock: item.available_stock ,
                is_available: item.is_available,
               order: item.order_by,
               sku: item.sku,
               total_stock: item.total_stock,
               categories: item.category, // You might need to adjust this based on the actual structure of the category field
               age:item.age,
               extra:item.extra,
               age_labels: item.age_data.map(age => age.label), // Extracting age labels
   
           }
           ))
           const ProductData ={
               result,
               data,
           } 
   
           return ProductData;
       } catch (error) {
           console.error('Error fetching product data:', error);
           throw error; // rethrow the error so you can catch it in the calling function
       }
}


export const ProductSearchAPI=async()=>{
    try{
        const response = await axios.get(`${BASE_API_URL}api/search-product/`);
        return response
    }catch(error){
        console.error('Error fetching product data:', error);
        throw error; // rethrow the error so you can catch it in the calling function

    }
}

export const ProductDetailAPI=async(id)=>{
 try {
        const response = await axios.get(ProductListAPI+id);
        return response.data;
    } catch (error) {
        console.error('Error fetching product data:', error);
        throw error; // rethrow the error so you can catch it in the calling function
    }
}