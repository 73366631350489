import axios from "axios";
import { BASE_API_URL } from "../../../server";

export const CustomerApi = async (url) => {
  const apiEndpoint =
    `${BASE_API_URL}api-admin/web/profile/list/?depth=5` + url;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const CustomerList = response.data;
    return CustomerList;
  } catch (error) {
    console.log("***** ERROR in Fetching School *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const EditCustomerApi = async (values, id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profile/${id}/change/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.put(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const AddCustomerApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profile/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const ProfileSubscription = async (url) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profilesubscription/list/?depth=5${url}`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const CustomerList = response.data;
    return CustomerList;
  } catch (error) {
    console.log("***** ERROR in Fetching School *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AddProfileSubscriptionApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profilesubscription/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        'Accept': "application/json",
        'Bypass-Subscription-Check': "true"
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const EditProfileSubscriptionApi = async (values, id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profilesubscription/${id}/change/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.put(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const AllCustomerApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/profile/list/?page_size=1000000000000000000&depth=3`;
  const combined_data = [];
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const CustomerList = response.data;
    return CustomerList;
  } catch (error) {
    console.log("***** ERROR in Fetching School *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AddCustomerPasswordApi = async (userId, values) => {
  const apiEndpoint = `${BASE_API_URL}api/force-change-password/${userId}`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};
