import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { BASE_API_URL } from '../../server';

import { useNavigate, Link } from 'react-router-dom';
import { useAppContext } from '../context/UserContext';



// Base URL

function useLoginApi() {
    const [csrfToken, setCsrfToken] = useState();
    const { UpdateLogin, updateSuperUser, updatecustomer, updateCsrf } = useAppContext();
    const navigate = useNavigate();

    const loginApiUrl = `${BASE_API_URL}account/login/`;
    const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;

    const fetchCsrfToken = async () => {
        try {
            const response = await axios.get(
                csrfTokenApiUrl,
                { withCredentials: true }
            );
            const token = response.data.csrftoken;
            setCsrfToken(token); // Store it in the state for any other uses
            return token; // Return it for immediate use
        } catch (error) {
            console.error('There was a problem fetching the CSRF token:', error);
        }
    };

    const login = async (values) => {
        // Fetch CSRF Token
        // const fetchedCsrfToken = await fetchCsrfToken();
        
        // Handle login
        try {
            const response = await axios.post(
                loginApiUrl,
                {
                    
                    username: values.email,
                    password: values.password,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                    },
                    withCredentials: true,
                }
            );
            console.log(response)
            const isSuperUser = response.data.is_superuser;
            // updateCsrf(fetchedCsrfToken)
            if (isSuperUser) {
                updateSuperUser(true);
                UpdateLogin(true);
                updatecustomer(false);
            }
            if (!isSuperUser && response.data.profile.user_type === "customer") {
                updatecustomer(true);
                UpdateLogin(true);
            }

        } catch (error) {
            console.log(error)
        }
    };

    return {
        login
    };
}

export default useLoginApi;
