import React, { useEffect, useState } from "react";

// CSS
import "./web/style/main.css";
// Router
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "@fontsource/poppins";

//Front Pages
import Layout from "./web/components/Layout";
import Index from "./web/pages/Index";
import AboutUs from "./web/pages/AboutUs";
import ToyStore from "./web/pages/ToyStore";
import ToyDetail from "./web/pages/ToyDetail";
import Pricing from "./web/pages/Pricing";
import Contact from "./web/pages/Contact";
import Cart from "./web/pages/Cart";
import Checkout from "./web/pages/Checkout";
import Login from "./web/pages/Login";
import Register from "./web/pages/Register";
import FaqPage from "./web/pages/Faq";
import ForgotPassword from "./web/pages/ForgotPassword";
import Blogs from "./web/pages/Blogs";
import BlogsDetail from "./web/pages/BlogsDetail";
import HouseRules from "./web/pages/HouseRules";
import ContactUs from "./web/pages/ContactUs";
import Returns from "./web/pages/Refund";
import HowitWorks from "./web/pages/HowitWorks";

// Layout
import {
  DashboardLayout,
  DashboardCusotmerLayout,
  DashboardSchoolLayout,
} from "./dashboard/admin/components/DashboardLayout";

// Admin Dashboard Pages
import Dashboard from "./dashboard/admin/pages/Dashboard";

import Order from "./dashboard/admin/pages/Order";
import AddOrder from "./dashboard/admin/pages/Order/AddOrder";
import Dispatch from "./dashboard/admin/pages/Dispatch";
import Pickup from "./dashboard/admin/pages/Pickup";

import Subscriptions from "./dashboard/admin/pages/Subscriptions";
import AddSubcription from "./dashboard/admin/pages/Subscription/AddSubcription";

import Customer from "./dashboard/admin/pages/Customer";
import AddCustomer from "./dashboard/admin/pages/Customer/AddCusomter";

import School from "./dashboard/admin/pages/School";
import AddSchool from "./dashboard/admin/pages/School/AddSchool";

import Product from "./dashboard/admin/pages/Product";
import AddProduct from "./dashboard/admin/pages/Product/AddProduct";
import EditProduct from "./dashboard/admin/pages/Product/EditProduct";
import ProductCategory from "./dashboard/admin/pages/Product/ProductCategory/ProductCategory";
import AddProductCategory from "./dashboard/admin/pages/Product/ProductCategory/AddProductCategory";

import Reports from "./dashboard/admin/pages/Reports";
import Promo from "./dashboard/admin/pages/Promo";
import AccountSettings from "./dashboard/admin/pages/AccountSettings";

// Customer Dashboard Pages
import CustomerDashboard from "./dashboard/customer/pages/CustomerDashboard";
import CustomerOrder from "./dashboard/customer/pages/CustomerOrder";
import CustomerWallet from "./dashboard/customer/pages/CustomerWallet";
import CustomerMilestone from "./dashboard/customer/pages/CustomerMilestone";
import CustomerAccount from "./dashboard/customer/pages/CustomerAccount";
import CustomerMilestoneInfo from "./dashboard/customer/pages/CustomerMilestoneInfo";

// School Dashboard Pages
import SchoolDashboard from "./dashboard/school/pages/SchoolDashboard";
import SchoolPayouts from "./dashboard/school/pages/SchoolPayouts";
import SchoolStudentList from "./dashboard/school/pages/SchoolStudentList";
import SchoolAccount from "./dashboard/school/pages/SchoolAccount";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useAppContext } from "./web/context/UserContext";
import ReactGA from 'react-ga';

import { CSSTransition, TransitionGroup } from "react-transition-group";
import EditCustomer from "./dashboard/admin/pages/Customer/EditCustomer";
import PopularToyStore from "./web/pages/PopularToys";

const { palette } = createTheme();
const theme = createTheme({
  list: {
    padding: 60,
  },
  typography: {
    fontFamily: "poppins",
  },
  palette: {
    // myAwesomeColor: palette.augmentColor({ color: purple }),
    // Use this code if you want to use an arbitrary color
    green: palette.augmentColor({
      color: {
        main: "#4B9E99",
      },
    }),
    yellow: palette.augmentColor({
      color: {
        main: "#F9D54A",
      },
    }),
  },
});

const trackingId = "G-F6ZB33QNP6"; // Replace with your tracking ID

function App() {
  
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(trackingId);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  const { isSuperUser } = useAppContext();
  const { customer } = useAppContext();
  const { school } = useAppContext();
  return (
    <>
      <ThemeProvider theme={theme}>
        <TransitionGroup>
          <Routes>
            {/* Login */}
            <Route
              path="/"
              element={
                isSuperUser ? (
                  <Navigate to="/admin" />
                ) : customer ? (
                  <Navigate to="/customer-dashboard" />
                ) : (
                  <>
                    <Navigate to="/login" />
                  </>
                )
              }
            />

            {/* Login */}

            {/* Register */}
            <Route path="/register" element={<Register />}></Route>
            {/* Register */}

            {/* Website */}
            <Route path="/" element={<Layout />}>
              <Route index element={<Index />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="toy-store" element={<ToyStore />} />
              <Route path="popular-toys" element={<PopularToyStore />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="toy-detail/:productSlug" element={<ToyDetail />} />
              <Route path="cart" element={<Cart />} />
              <Route path="checkout/:checkoutSlug" element={<Checkout />} />
              <Route path="contact" element={<Contact />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="blog-detail" element={<BlogsDetail />} />
              <Route path="house-rules" element={<HouseRules />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="return-refund-policy" element={<Returns />} />
              <Route path="how-it-works" element={<HowitWorks />} />
            </Route>
            {/* Website */}

            {/* Admin */}
            {!isSuperUser ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="forgot-passoword" element={<ForgotPassword />} />
                <Route path="/admin" element={<Navigate to="/" />}>
                  <Route index element={<Navigate to="/" />} />
                  <Route path="order" element={<Navigate to="/" />} />
                  <Route path="order/order-add" element={<Navigate to="/" />} />

                  <Route
                    path="subscriptions-plans"
                    element={<Navigate to="/" />}
                  />
                  <Route
                    path="subscriptions-plans/add-subscription"
                    element={<Navigate to="/" />}
                  />

                  <Route path="customers" element={<Navigate to="/" />} />
                  <Route
                    path="customers/add-customer"
                    element={<Navigate to="/" />}
                  />

                  <Route path="schools" element={<Navigate to="/" />} />
                  <Route
                    path="schools/add-schools"
                    element={<Navigate to="/" />}
                  />

                  <Route path="products" element={<Navigate to="/" />} />
                  <Route
                    path="product-category"
                    element={<Navigate to="/" />}
                  />
                  <Route
                    path="products/add-product"
                    element={<Navigate to="/" />}
                  />
                  <Route
                    path="products/add-product/:slug"
                    element={<Navigate to="/" />}
                  />
                  <Route
                    path="products/add-product-category"
                    element={<Navigate to="/" />}
                  />

                  <Route path="reports" element={<Navigate to="/" />} />
                  <Route path="promo" element={<Navigate to="/" />} />
                  <Route
                    path="account-settings"
                    element={<Navigate to="/" />}
                  />
                </Route>
              </>
            ) : (
              <>
                <Route path="/login" element={<Navigate to="/admin" />} />
                <Route path="/admin" element={<DashboardLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="dispatch" element={<Dispatch />} />
                  <Route path="pickups" element={<Pickup />} />
                  <Route path="order" element={<Order />} />
                  <Route path="order/order-add" element={<AddOrder />} />

                  <Route
                    path="subscriptions-plans"
                    element={<Subscriptions />}
                  />
                  <Route
                    path="subscriptions-plans/add-subscription"
                    element={<AddSubcription />}
                  />

                  <Route path="customers" element={<Customer />} />
                  <Route
                    path="customers/add-customer"
                    element={<AddCustomer />}
                  />
                  <Route
                    path="customers/edit-customer/:customerId/:userId"
                    element={<EditCustomer />}
                  />

                  <Route path="schools" element={<School />} />
                  <Route path="schools/add-schools" element={<AddSchool />} />

                  <Route path="products" element={<Product />} />
                  <Route
                    path="product-category"
                    element={<ProductCategory />}
                  />
                  <Route path="products/add-product" element={<AddProduct />} />
                  <Route
                    path="products/edit-product/:productSlug"
                    element={<EditProduct />}
                  />

                  <Route
                    path="products/add-product-category"
                    element={<AddProductCategory />}
                  />

                  <Route path="reports" element={<Reports />} />
                  <Route path="promo" element={<Promo />} />
                  <Route
                    path="account-settings"
                    element={<AccountSettings />}
                  />
                </Route>
              </>
            )}
            {/* Admin */}

            {/* Customer */}
            {customer ? (
              <>
                <Route
                  path="/customer-dashboard"
                  element={<DashboardCusotmerLayout />}
                >
                  <Route index element={<CustomerDashboard />} />
                  <Route path="order" element={<CustomerOrder />} />
                  <Route path="wallet" element={<CustomerWallet />} />
                  <Route path="milestone" element={<CustomerMilestone />} />
                  <Route
                    path="account-settings"
                    element={<CustomerAccount />}
                  />
                  <Route
                    path="milestone-detail/:childSlug"
                    element={<CustomerMilestoneInfo />}
                  />
                </Route>
              </>
            ) : (
              <>
                <Route path="/customer-dashboard" element={<Navigate to="/" />}>
                  <Route index element={<Navigate to="/" />} />
                  <Route path="order" element={<Navigate to="/" />} />
                  <Route path="wallet" element={<Navigate to="/" />} />
                  <Route path="milestone" element={<Navigate to="/" />} />
                  <Route
                    path="account-settings"
                    element={<Navigate to="/" />}
                  />
                </Route>
              </>
            )}
            {/* Customer */}

            {/* school */}
            {school ? (
              <>
                <Route
                  path="/customer-dashboard"
                  element={<DashboardCusotmerLayout />}
                >
                  <Route index element={<CustomerDashboard />} />
                  <Route path="order" element={<CustomerOrder />} />
                  <Route path="wallet" element={<CustomerWallet />} />
                  <Route path="milestone" element={<CustomerMilestone />} />
                  <Route
                    path="account-settings"
                    element={<CustomerAccount />}
                  />
                </Route>
              </>
            ) : (
              <>
                <Route path="/customer-dashboard" element={<Navigate to="/" />}>
                  <Route index element={<Navigate to="/" />} />
                  <Route path="order" element={<Navigate to="/" />} />
                  <Route path="wallet" element={<Navigate to="/" />} />
                  <Route path="milestone" element={<Navigate to="/" />} />
                  <Route
                    path="account-settings"
                    element={<Navigate to="/" />}
                  />
                </Route>
              </>
            )}
            {/* school */}

            {/* School Dashboard */}
            <Route path="/school-dashboard" element={<DashboardSchoolLayout />}>
              <Route index element={<SchoolDashboard />} />
              <Route path="payouts" element={<SchoolPayouts />} />
              <Route path="student-list" element={<SchoolStudentList />} />
              <Route path="account-settings" element={<SchoolAccount />} />
            </Route>
            {/* School Dashboard */}
          </Routes>
        </TransitionGroup>
      </ThemeProvider>
    </>
  );
}

export default App;
