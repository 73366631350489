import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";

// CSS
import "../style/pages/toydetail/toydetail.css";

// Components
import Card from "../components/Card";
import ClientSlider from "../components/ClientSlider";
import { CardButton } from "../components/Button";

// Form
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

// API
import {
  ProductAPI,
  ProductDetailAPI,
  PopularProductAPI,
} from "../../globalAPIs/ProductApi";
import { CustomerInfoApi } from "../../dashboard/customer/api/CustomerInfoApi";
import { FavouritesApi } from "../api/Favourites";
import Swal from "sweetalert2";

// Popular Slider Settings
var PopularSlidersettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow:4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        settings: "slick",
        arrows: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        settings: "slick",
        arrows: false,
      },
    },
  ],
};

function ToyStoreDetail() {
  // Product Jason
  const [Product, setProduct] = useState([]);
  const [ProductData, setProductdata] = useState([]);
  const [Profile, setProfile] = useState(null);
  const [isFav, setFav] = useState(false)

  const favClick = (async (product_id) => {
      try {
          const data = await FavouritesApi(product_id)
          console.log(data)
          setFav(isFav => !isFav);
          Swal.fire({
            icon: "success",
            text:data.message ,
            showConfirmButton: false,
          });
      } catch (error) {
          console.log(error.response.data)
      }
  })
  // Get Last Param
  const url = window.location.href;
  const lastSegment = url.split("/").pop();

  // Inital Cost
  const price7DataRef = useRef(null);

  const [toggle, setToggle] = useState("1");

  const [ProductImage, setProductImage] = useState();

  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  const toggleClass = (image) => {
    setSelectedImage(image);
  };

  // PriceState
  const [pricetoggle, setprice] = useState();
  const [selectedDay, setselectedDay] = useState(7);
  const PopularSlider = React.useRef(null); // Popular Slider Refrences
  useEffect(() => {
    (async () => {
      try {
        const Productdata = await ProductDetailAPI(lastSegment);

        const url = "category=" + Productdata.category;
        const Product = await ProductAPI(url);

        setProduct(Product.result);
        setProductdata(Productdata); // Make sure you also set the fetched data
        console.log(Product.result);
        console.log(Productdata);
        setprice(Productdata.price_7);
        setProductImage(Productdata.image);
        const imageArray = [
          Productdata.image,
          ...Productdata.multi_image_data.map(
            (item) => "https://api.growinggiraffe.com/" + item.image
          ),
        ];
        setImageList(imageArray);
        setSelectedImage(imageArray.length > 0 ? imageArray[0] : null);
        console.log(imageArray);
      } catch (error) {}
    })();
  }, [2]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await CustomerInfoApi();
        setProfile(data); // Make sure you also set the fetched data
      } catch (error) {}
    };
    fetchProfile();
  }, []);

  console.log("Product Itemmmmssss", ProductData, Profile);

  return (
    <>
      <section className="container toy-detail-container mt-0">
        <div className="left">
          <div className="main-img">
            {ProductData ? (
              <>
                <div className="toy-img" key={ProductData.id}>
                  {/* Your other JSX code here */}
                  <div className="img-container">
                    <img src={ProductImage} alt="" className="photo" />
                  </div>
                  <div className={isFav ? "icon-container filled" : "icon-container "} onClick={() => favClick(ProductData.id)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.75736 8.42405C3.41421 10.7672 3.41421 14.5662 5.75736 16.9094L16.0001 27.152L26.2427 16.9094C28.5857 14.5662 28.5857 10.7672 26.2427 8.42405C23.8995 6.0809 20.1005 6.0809 17.7573 8.42405L16.0001 10.1815L14.2427 8.42405C11.8995 6.0809 8.10051 6.0809 5.75736 8.42405Z" stroke="#F54C4C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="img-nav-container">
            {imageList.map((image, index) => (
              <div
                className={`img-nav ${
                  selectedImage === image ? "selected" : ""
                }`}
                onClick={() => {
                  toggleClass(image);
                  setProductImage(image);
                }}
                key={index}
              >
                <div className="img-container">
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          {ProductData.age_data ? (
            <>
              <div className="product-title">
                <h1>{ProductData.name}</h1>
                {/* {ProductData.is_available?(<>
                  <div style={{fontSize:"0.8rem", fontWeight:"500", color:"#34C83A", paddingBottom:"10px"}}>Only {ProductData.available_stock} left</div>
                </>):(<></>)} */}
              </div>
              <div className="product-detail-container">
                  {ProductData.extra.Age?(<>
                    <div className="product-detail">
                      <span className="detail">Age</span>
                      {ProductData.extra.Age}
                    </div>
                  </>):(<></>)}
                  {ProductData.extra["No Of Pieces"]?(<>
                    <div className="product-detail">
                      <span className="detail">No Of Pieces</span>
                      {ProductData.extra["No Of Pieces"]}
                    </div>
                  </>):(<></>)}
                {ProductData?.extra?.extra_details?.map((value, i) => (
                  <div className="product-detail">
                    <span className="detail">{value?.label}</span>
                    {value?.value}
                  </div>
                ))}

                <div className="product-detail">
                  <span className="detail">Description</span>

                  <div
                    className="detail-description"
                    dangerouslySetInnerHTML={{
                      __html: ProductData.small_description,
                    }}
                  />
                </div>
                <div className="product-detail">
                  <span className="detail">Days</span>

                  <div className="form-content">
                    <FormControl>
                      <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        defaultValue={ProductData.price_7}
                      >
                        <FormControlLabel
                          value={ProductData.price_1}
                          control={<Radio color="green" />}
                          onChange={() => {
                            setprice(ProductData.price_1);
                            setselectedDay(1);
                          }}
                          label={1}
                        />
                        <FormControlLabel
                          value={ProductData.price_3}
                          control={<Radio color="green" />}
                          onChange={() => {
                            setprice(ProductData.price_3);
                            setselectedDay(3);
                          }}
                          label={3}
                        />
                        <FormControlLabel
                          value={ProductData.price_7}
                          control={<Radio defaultChecked color="green" />}
                          onChange={() => {
                            setprice(ProductData.price_7);
                            setselectedDay(7);
                          }}
                          label={7}
                        />
                        <FormControlLabel
                          value={ProductData.price_14}
                          control={<Radio color="green" />}
                          onChange={() => {
                            setprice(ProductData.price_14);
                            setselectedDay(14);
                          }}
                          label={14}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <span className="detail mt-20">Rent</span>

                  <div className="product-description mt-20">

                    <div className="icon-container">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_601_4935)">
                          <path
                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z"
                            fill="#F6D738"
                          ></path>
                          <path
                            d="M8.00043 14.7895C11.7502 14.7895 14.7899 11.7497 14.7899 8C14.7899 4.25025 11.7502 1.2105 8.00043 1.2105C4.25069 1.2105 1.21094 4.25025 1.21094 8C1.21094 11.7497 4.25069 14.7895 8.00043 14.7895Z"
                            fill="#F3CC30"
                          ></path>
                          <path
                            d="M11.5518 2.20514C12.2513 3.2965 12.6221 4.56602 12.62 5.86227C12.62 9.61202 9.58024 12.6518 5.83049 12.6518C4.57635 12.6537 3.34639 12.3065 2.27832 11.6491C2.89199 12.6099 3.73787 13.4005 4.73783 13.948C5.73779 14.4955 6.85961 14.7821 7.99964 14.7815C11.7494 14.7815 14.7891 11.7417 14.7891 7.99198C14.7897 6.83101 14.4923 5.68934 13.9255 4.67615C13.3587 3.66296 12.5414 2.81217 11.5518 2.20514Z"
                            fill="#EDBD31"
                          ></path>
                          <path
                            d="M2.40022 8.62123C2.40022 5.31119 5.08352 2.62788 8.39358 2.62788C9.31193 2.62712 10.2181 2.83779 11.042 3.24354C11.8659 3.64931 12.5853 4.23927 13.1445 4.96773C12.6193 4.05374 11.8622 3.29449 10.9497 2.76666C10.0372 2.23882 9.00161 1.9611 7.94747 1.96153C4.63744 1.96153 1.95411 4.64486 1.95411 7.9549C1.95215 9.27672 2.38917 10.5618 3.19658 11.6084C2.67327 10.6999 2.39859 9.66962 2.40022 8.62123Z"
                            fill="#F7DB5E"
                          ></path>
                          <path
                            d="M6.41258 7.14961C6.3412 7.03201 6.24251 6.94173 6.11652 6.87874C5.99475 6.81575 5.84986 6.78425 5.68188 6.78425C5.37112 6.78425 5.12545 6.88504 4.94487 7.08662C4.76849 7.28819 4.6803 7.55906 4.6803 7.89921C4.6803 8.28138 4.77479 8.57323 4.96377 8.77481C5.15695 8.97217 5.43621 9.07087 5.80156 9.07087C6.23412 9.07087 6.54278 8.87559 6.72755 8.48504H5.4866V7.59685H7.79211V8.7937C7.69553 9.02886 7.55274 9.24934 7.36377 9.45512C7.179 9.6609 6.94381 9.83098 6.65825 9.96536C6.3727 10.0955 6.04723 10.1606 5.68188 10.1606C5.23672 10.1606 4.84199 10.0661 4.49762 9.87717C4.15747 9.68398 3.8929 9.41732 3.70392 9.07717C3.51915 8.7328 3.42676 8.34016 3.42676 7.89921C3.42676 7.46248 3.51915 7.07402 3.70392 6.73386C3.8929 6.38949 4.15747 6.12284 4.49762 5.93386C4.83778 5.74068 5.23042 5.6441 5.67558 5.6441C6.23412 5.6441 6.69605 5.77847 7.0614 6.04725C7.42676 6.31602 7.65353 6.68347 7.74172 7.14961H6.41258ZM11.1801 7.14961C11.1087 7.03201 11.01 6.94173 10.884 6.87874C10.7622 6.81575 10.6173 6.78425 10.4493 6.78425C10.1386 6.78425 9.89292 6.88504 9.71234 7.08662C9.53596 7.28819 9.44777 7.55906 9.44777 7.89921C9.44777 8.28138 9.54226 8.57323 9.73124 8.77481C9.92442 8.97217 10.2037 9.07087 10.569 9.07087C11.0016 9.07087 11.3102 8.87559 11.495 8.48504H10.2541V7.59685H12.5596V8.7937C12.463 9.02886 12.3202 9.24934 12.1312 9.45512C11.9465 9.6609 11.7113 9.83098 11.4257 9.96536C11.1402 10.0955 10.8147 10.1606 10.4493 10.1606C10.0042 10.1606 9.60946 10.0661 9.26509 9.87717C8.92494 9.68398 8.66037 9.41732 8.47139 9.07717C8.28663 8.7328 8.19423 8.34016 8.19423 7.89921C8.19423 7.46248 8.28663 7.07402 8.47139 6.73386C8.66037 6.38949 8.92494 6.12284 9.26509 5.93386C9.60525 5.74068 9.99789 5.6441 10.443 5.6441C11.0016 5.6441 11.4635 5.77847 11.8289 6.04725C12.1942 6.31602 12.421 6.68347 12.5092 7.14961H11.1801Z"
                            fill="#D49A34"
                          ></path>
                          <path
                            d="M6.18774 11.3608V11.6123H5.88457V12.4976H5.5685V11.6123H5.26855V11.3608H6.18774ZM6.84973 12.5089C6.7433 12.5089 6.64546 12.4842 6.55624 12.4347C6.46702 12.3842 6.39606 12.3149 6.34338 12.2267C6.29177 12.1375 6.26597 12.037 6.26597 11.9252C6.26597 11.8134 6.29177 11.7134 6.34338 11.6252C6.39606 11.536 6.46702 11.4667 6.55624 11.4172C6.64546 11.3677 6.7433 11.343 6.84973 11.343C6.95725 11.343 7.05506 11.3677 7.14323 11.4172C7.23245 11.4667 7.30287 11.536 7.35448 11.6252C7.40608 11.7134 7.43188 11.8134 7.43188 11.9252C7.43188 12.037 7.40608 12.1375 7.35448 12.2267C7.30287 12.3149 7.23245 12.3842 7.14323 12.4347C7.054 12.4842 6.95616 12.5089 6.84973 12.5089ZM6.84973 12.2154C6.93036 12.2154 6.99378 12.1891 7.04002 12.1364C7.08731 12.0837 7.11097 12.0133 7.11097 11.9252C7.11097 11.8349 7.08731 11.7639 7.04002 11.7123C6.99378 11.6596 6.93036 11.6333 6.84973 11.6333C6.76802 11.6333 6.70407 11.6596 6.65783 11.7123C6.6116 11.7639 6.58849 11.8349 6.58849 11.9252C6.58849 12.0144 6.6116 12.0853 6.65783 12.138C6.70407 12.1896 6.76802 12.2154 6.84973 12.2154ZM8.24642 12.4976L7.89003 11.9993V12.4976H7.57397V11.3608H7.89003V11.851L8.24319 11.3608H8.60764L8.18998 11.9123L8.62861 12.4976H8.24642ZM9.06013 11.6139V11.7994H9.42296V12.0397H9.06013V12.2445H9.47134V12.4976H8.74406V11.3608H9.47134V11.6139H9.06013ZM10.6805 12.4976H10.3645L9.94358 11.8623V12.4976H9.62751V11.3608H9.94358L10.3645 12.0042V11.3608H10.6805V12.4976Z"
                            fill="#D49A34"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_601_4935">
                            <rect width="16" height="16" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    {pricetoggle} GG Tokens
                  </div>

                  <span className="detail mt-20">Refundable Deposit (Only applicable for non-subscribed users)</span>

                  <div className="product-description mt-20">
                    <div className="icon-container">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_601_4935)">
                          <path
                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z"
                            fill="#F6D738"
                          ></path>
                          <path
                            d="M8.00043 14.7895C11.7502 14.7895 14.7899 11.7497 14.7899 8C14.7899 4.25025 11.7502 1.2105 8.00043 1.2105C4.25069 1.2105 1.21094 4.25025 1.21094 8C1.21094 11.7497 4.25069 14.7895 8.00043 14.7895Z"
                            fill="#F3CC30"
                          ></path>
                          <path
                            d="M11.5518 2.20514C12.2513 3.2965 12.6221 4.56602 12.62 5.86227C12.62 9.61202 9.58024 12.6518 5.83049 12.6518C4.57635 12.6537 3.34639 12.3065 2.27832 11.6491C2.89199 12.6099 3.73787 13.4005 4.73783 13.948C5.73779 14.4955 6.85961 14.7821 7.99964 14.7815C11.7494 14.7815 14.7891 11.7417 14.7891 7.99198C14.7897 6.83101 14.4923 5.68934 13.9255 4.67615C13.3587 3.66296 12.5414 2.81217 11.5518 2.20514Z"
                            fill="#EDBD31"
                          ></path>
                          <path
                            d="M2.40022 8.62123C2.40022 5.31119 5.08352 2.62788 8.39358 2.62788C9.31193 2.62712 10.2181 2.83779 11.042 3.24354C11.8659 3.64931 12.5853 4.23927 13.1445 4.96773C12.6193 4.05374 11.8622 3.29449 10.9497 2.76666C10.0372 2.23882 9.00161 1.9611 7.94747 1.96153C4.63744 1.96153 1.95411 4.64486 1.95411 7.9549C1.95215 9.27672 2.38917 10.5618 3.19658 11.6084C2.67327 10.6999 2.39859 9.66962 2.40022 8.62123Z"
                            fill="#F7DB5E"
                          ></path>
                          <path
                            d="M6.41258 7.14961C6.3412 7.03201 6.24251 6.94173 6.11652 6.87874C5.99475 6.81575 5.84986 6.78425 5.68188 6.78425C5.37112 6.78425 5.12545 6.88504 4.94487 7.08662C4.76849 7.28819 4.6803 7.55906 4.6803 7.89921C4.6803 8.28138 4.77479 8.57323 4.96377 8.77481C5.15695 8.97217 5.43621 9.07087 5.80156 9.07087C6.23412 9.07087 6.54278 8.87559 6.72755 8.48504H5.4866V7.59685H7.79211V8.7937C7.69553 9.02886 7.55274 9.24934 7.36377 9.45512C7.179 9.6609 6.94381 9.83098 6.65825 9.96536C6.3727 10.0955 6.04723 10.1606 5.68188 10.1606C5.23672 10.1606 4.84199 10.0661 4.49762 9.87717C4.15747 9.68398 3.8929 9.41732 3.70392 9.07717C3.51915 8.7328 3.42676 8.34016 3.42676 7.89921C3.42676 7.46248 3.51915 7.07402 3.70392 6.73386C3.8929 6.38949 4.15747 6.12284 4.49762 5.93386C4.83778 5.74068 5.23042 5.6441 5.67558 5.6441C6.23412 5.6441 6.69605 5.77847 7.0614 6.04725C7.42676 6.31602 7.65353 6.68347 7.74172 7.14961H6.41258ZM11.1801 7.14961C11.1087 7.03201 11.01 6.94173 10.884 6.87874C10.7622 6.81575 10.6173 6.78425 10.4493 6.78425C10.1386 6.78425 9.89292 6.88504 9.71234 7.08662C9.53596 7.28819 9.44777 7.55906 9.44777 7.89921C9.44777 8.28138 9.54226 8.57323 9.73124 8.77481C9.92442 8.97217 10.2037 9.07087 10.569 9.07087C11.0016 9.07087 11.3102 8.87559 11.495 8.48504H10.2541V7.59685H12.5596V8.7937C12.463 9.02886 12.3202 9.24934 12.1312 9.45512C11.9465 9.6609 11.7113 9.83098 11.4257 9.96536C11.1402 10.0955 10.8147 10.1606 10.4493 10.1606C10.0042 10.1606 9.60946 10.0661 9.26509 9.87717C8.92494 9.68398 8.66037 9.41732 8.47139 9.07717C8.28663 8.7328 8.19423 8.34016 8.19423 7.89921C8.19423 7.46248 8.28663 7.07402 8.47139 6.73386C8.66037 6.38949 8.92494 6.12284 9.26509 5.93386C9.60525 5.74068 9.99789 5.6441 10.443 5.6441C11.0016 5.6441 11.4635 5.77847 11.8289 6.04725C12.1942 6.31602 12.421 6.68347 12.5092 7.14961H11.1801Z"
                            fill="#D49A34"
                          ></path>
                          <path
                            d="M6.18774 11.3608V11.6123H5.88457V12.4976H5.5685V11.6123H5.26855V11.3608H6.18774ZM6.84973 12.5089C6.7433 12.5089 6.64546 12.4842 6.55624 12.4347C6.46702 12.3842 6.39606 12.3149 6.34338 12.2267C6.29177 12.1375 6.26597 12.037 6.26597 11.9252C6.26597 11.8134 6.29177 11.7134 6.34338 11.6252C6.39606 11.536 6.46702 11.4667 6.55624 11.4172C6.64546 11.3677 6.7433 11.343 6.84973 11.343C6.95725 11.343 7.05506 11.3677 7.14323 11.4172C7.23245 11.4667 7.30287 11.536 7.35448 11.6252C7.40608 11.7134 7.43188 11.8134 7.43188 11.9252C7.43188 12.037 7.40608 12.1375 7.35448 12.2267C7.30287 12.3149 7.23245 12.3842 7.14323 12.4347C7.054 12.4842 6.95616 12.5089 6.84973 12.5089ZM6.84973 12.2154C6.93036 12.2154 6.99378 12.1891 7.04002 12.1364C7.08731 12.0837 7.11097 12.0133 7.11097 11.9252C7.11097 11.8349 7.08731 11.7639 7.04002 11.7123C6.99378 11.6596 6.93036 11.6333 6.84973 11.6333C6.76802 11.6333 6.70407 11.6596 6.65783 11.7123C6.6116 11.7639 6.58849 11.8349 6.58849 11.9252C6.58849 12.0144 6.6116 12.0853 6.65783 12.138C6.70407 12.1896 6.76802 12.2154 6.84973 12.2154ZM8.24642 12.4976L7.89003 11.9993V12.4976H7.57397V11.3608H7.89003V11.851L8.24319 11.3608H8.60764L8.18998 11.9123L8.62861 12.4976H8.24642ZM9.06013 11.6139V11.7994H9.42296V12.0397H9.06013V12.2445H9.47134V12.4976H8.74406V11.3608H9.47134V11.6139H9.06013ZM10.6805 12.4976H10.3645L9.94358 11.8623V12.4976H9.62751V11.3608H9.94358L10.3645 12.0042V11.3608H10.6805V12.4976Z"
                            fill="#D49A34"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_601_4935">
                            <rect width="16" height="16" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    {ProductData.deposit} GG Tokens
                  </div>
                </div>
               
                <CardButton
                  status={ProductData.available_stock}
                  product={ProductData}
                  price={pricetoggle}
                  day={selectedDay}
                  profile={Profile}
                ></CardButton>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </section>

      {/* Popular Toys Card Container */}
      <section className="popular-card-container container">
        <div className="title">
          <h2>Related Toys</h2>
          <a href="/toy-store">Show All</a>
        </div>
        {Product && ProductData ? (
          <>
            <Slider
              ref={PopularSlider}
              {...PopularSlidersettings}
              className="popular-card-slider"
            >
              {Product.map((e) => {
                if (e.id !== ProductData.id) {
                  return (
                    <Card
                      key={e.slug}
                      img={e.image}
                      name={e.name}
                      price={e.price}
                      slug={e.slug}
                      product={e}
                      id={e.id}
                      short_description={e.small_description}
                      stock_status={e.available_stock}
                    />
                  );
                }
              })}
            </Slider>
          </>
        ) : (
          <></>
        )}

        <button
          className="arrow prev"
          onClick={() => PopularSlider?.current?.slickPrev()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
        <button
          className="arrow next"
          onClick={() => PopularSlider?.current?.slickNext()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
      </section>
      {/* Popular Toys Card Container */}

      {/* Client Testimonials Container */}
      {/* <section className="container client-section">
        <div className="subtitle">Client Testimonials</div>
        <div className="client-slider-container">
          <ClientSlider></ClientSlider>
        </div>
      </section> */}
      {/* Client Testimonials Container*/}
    </>
  );
}

export default ToyStoreDetail;
