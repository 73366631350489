import React, { createContext, useContext, useEffect, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {

  const [loggedIn, setLogin] = useState(
    localStorage.getItem('Logged') === 'true'
  );

  const UpdateLogin = (value) => {
    setLogin(value);
    localStorage.setItem('Logged', value.toString());
  };

  // Customer
  const [customer, setcustomer] = useState(
    localStorage.getItem('customer') === 'true'
  );

  const updatecustomer = (value) => {
    setcustomer(value);
    localStorage.setItem('customer', value.toString());
  };

   // school
   const [school, setschool] = useState(
    localStorage.getItem('school') === 'true'
  );

  const updateschool = (value) => {
    setschool(value);
    localStorage.setItem('school', value.toString());
  };

  // SuperAdmin
  const [isSuperUser, setIsSuperUser] = useState(
    localStorage.getItem('isSuperUser') === 'true'
  );

  const updateSuperUser = (value) => {
    setIsSuperUser(value);
    localStorage.setItem('isSuperUser', value.toString());
  };

  // CSRF
  const [csrf, setcsrf] = useState(
    localStorage.getItem('csrf') === ''
  );

  const updateCsrf = (value) => {
    setIsSuperUser(value);
    localStorage.setItem('csrf', value.toString());
  };

  // Cart Count
  const [cartCount, setCartcount] = useState(() => {
    const initialCount = localStorage.getItem('cartCount');
    return initialCount ? parseInt(initialCount, 10) : 0;
});

  const updateCartcount = (value) => {
    setCartcount(value);
    localStorage.setItem('cartCount', value.toString());
  };

  // Common Effect
  useEffect(() => {
    localStorage.setItem('isSuperUser', isSuperUser.toString());
    localStorage.setItem('customer', customer.toString());
  }, [isSuperUser, customer]);



  return (
    <AppContext.Provider value={{
      loggedIn,UpdateLogin,customer,setcustomer, cartCount,setCartcount,updateCartcount ,csrf,setcsrf,updateCsrf, isSuperUser, updateSuperUser,  updatecustomer 
      , school, setschool,updateschool
      }}>
      {children}
    </AppContext.Provider>
  );
};