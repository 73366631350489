import React from 'react'

function ContactUs() {
    return (
        <>
            <section class="contact-us-section container">
                <div class="title">Contact Us</div>
                <div class="contact-us-container">
                    <div class="left">
                        <div class="content">
                            
                            <div class="icon-text-container">
                                <a href="">
                                    <div class="icon-text">
                                        <div class="icon-container">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14.7141 13.8808C13.9335 14.6613 12.3013 16.2934 11.1781 17.4166C10.5272 18.0675 9.47306 18.0678 8.82215 17.4169C7.7186 16.3133 6.11797 14.7128 5.28594 13.8808C2.68244 11.2773 2.68244 7.05612 5.28594 4.45263C7.88943 1.84913 12.1106 1.84913 14.7141 4.45263C17.3175 7.05612 17.3175 11.2773 14.7141 13.8808Z"
                                                    stroke="#181818" stroke-linecap="round" stroke-linejoin="round" />
                                                <path
                                                    d="M12.5 9.16675C12.5 10.5475 11.3807 11.6667 10 11.6667C8.61925 11.6667 7.5 10.5475 7.5 9.16675C7.5 7.78604 8.61925 6.66675 10 6.66675C11.3807 6.66675 12.5 7.78604 12.5 9.16675Z"
                                                    stroke="#181818" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div class="text">
                                            B-11, Kasturchand Mill Compound, M.C Jawale Marg, Dadar Kabutarkhan, Senapati Bapat Marg, Dadar West, Mumbai, Maharashtra 400028
                                        </div>
                                    </div>
                                </a>
                                <a href="https://wa.me/09321146692/?text=Hello">
                                    <div class="icon-text">
                                        <div class="icon-container">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.1289 3.79297C14.4922 2.15234 12.3125 1.25 9.99609 1.25C5.21484 1.25 1.32422 5.14062 1.32422 9.92188C1.32422 11.4492 1.72266 12.9414 2.48047 14.2578L1.25 18.75L5.84766 17.543C7.11328 18.2344 8.53906 18.5977 9.99219 18.5977H9.99609C14.7734 18.5977 18.75 14.707 18.75 9.92578C18.75 7.60938 17.7656 5.43359 16.1289 3.79297ZM9.99609 17.1367C8.69922 17.1367 7.42969 16.7891 6.32422 16.1328L6.0625 15.9766L3.33594 16.6914L4.0625 14.0312L3.89062 13.7578C3.16797 12.6094 2.78906 11.2852 2.78906 9.92188C2.78906 5.94922 6.02344 2.71484 10 2.71484C11.9258 2.71484 13.7344 3.46484 15.0938 4.82812C16.4531 6.19141 17.2891 8 17.2852 9.92578C17.2852 13.9023 13.9688 17.1367 9.99609 17.1367ZM13.9492 11.7383C13.7344 11.6289 12.668 11.1055 12.4688 11.0352C12.2695 10.9609 12.125 10.9258 11.9805 11.1445C11.8359 11.3633 11.4219 11.8477 11.293 11.9961C11.168 12.1406 11.0391 12.1602 10.8242 12.0508C9.55078 11.4141 8.71484 10.9141 7.875 9.47266C7.65234 9.08984 8.09766 9.11719 8.51172 8.28906C8.58203 8.14453 8.54687 8.01953 8.49219 7.91016C8.4375 7.80078 8.00391 6.73438 7.82422 6.30078C7.64844 5.87891 7.46875 5.9375 7.33594 5.92969C7.21094 5.92188 7.06641 5.92188 6.92188 5.92188C6.77734 5.92188 6.54297 5.97656 6.34375 6.19141C6.14453 6.41016 5.58594 6.93359 5.58594 8C5.58594 9.06641 6.36328 10.0977 6.46875 10.2422C6.57812 10.3867 7.99609 12.5742 10.1719 13.5156C11.5469 14.1094 12.0859 14.1602 12.7734 14.0586C13.1914 13.9961 14.0547 13.5352 14.2344 13.0273C14.4141 12.5195 14.4141 12.0859 14.3594 11.9961C14.3086 11.8984 14.1641 11.8438 13.9492 11.7383Z"
                                                    fill="#444" />
                                            </svg>
                                        </div>
                                        <div class="text">
                                            09321146692
                                        </div>
                                    </div>
                                </a>
                                <a href="mailto:support@growinggiraffe.com">
                                    <div class="icon-text">
                                        <div class="icon-container">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M2.5 6.66675L9.0755 11.0504C9.63533 11.4237 10.3647 11.4237 10.9245 11.0504L17.5 6.66675H2.5ZM4.16667 15.8334H15.8333C16.7538 15.8334 17.5 15.0872 17.5 14.1667V5.83341C17.5 4.91294 16.7538 4.16675 15.8333 4.16675H4.16667C3.24619 4.16675 2.5 4.91294 2.5 5.83341V14.1667C2.5 15.0872 3.24619 15.8334 4.16667 15.8334Z"
                                                    stroke="#181818" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div class="text">
                                            support@growinggiraffe.com
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                    <div class="right">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15088.169102827356!2d72.8400931!3d19.0178587!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cec4752eaaab%3A0xeab62210eab2e9be!2sGrowingGiraffe!5e0!3m2!1sen!2sin!4v1697183702475!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs