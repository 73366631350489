import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "../style/components/toy-category-slider/toy-category-slider.css";

// APIs
import { ToyCategoryAPI } from "../../globalAPIs/ToyCategoryApi";

// Toy Category Slider settings
var ToyCategorySliderSettings = {
  slidesToShow: 8,
  slidesToScroll: 3,
  infinite: false,
  dots: false,
  settings: "slick",
  arrows: false,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        settings: "slick",
        arrows: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        settings: "slick",
        arrows: false,
      },
    },
  ],
};

// API DATA

function PopularToySlider({ PopularProduct }) {
  // Toy Category Slider Refrences
  const PopularToySlider = React.useRef(null);

  console.log("popular Products", PopularProduct);

  return (
    <>
      <div className="slider-wrapper">
        <Slider
          ref={PopularToySlider}
          {...ToyCategorySliderSettings}
          className="category-card-container"
        >
          {PopularProduct?.slice(0, 7)?.map(({ image, name, slug, id }) => {
            return (
              <a
                href={"/toy-detail/" + slug}
                key={id}
                className="category-card"
              >
                <div className="category-img">
                  <img src={image} alt="" />
                </div>
                <div className="category-name"> {name}</div>
              </a>
            );
          })}
        </Slider>
        <button
          className="arrow prev"
          onClick={() => PopularToySlider?.current?.slickPrev()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
        <button
          className="arrow next"
          onClick={() => PopularToySlider?.current?.slickNext()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
      </div>
    </>
  );
}

export default PopularToySlider;
