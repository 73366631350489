import React from 'react';

function Pagination({ onNext, onPrev, nextUrl, prevUrl }) {
	return (
		<div>
			{prevUrl &&
				<button onClick={onPrev}>
					Prev
				</button>}
			{nextUrl &&
				<button onClick={onNext}>
					Next
				</button>}
		</div>
	);
}

export default Pagination;