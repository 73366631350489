import axios from 'axios';
import { BASE_API_URL } from '../../../server'

export const CustomerOrderApi = async () => {
    const apiEndpoint = `${BASE_API_URL}api/my-rented-toy/`;
    try {
        const response      = await axios.get(apiEndpoint, { withCredentials: true });
        const OrderList     = response
        console.log('***************',response)
        return OrderList;

    } catch (error) {
        console.log('***** ERROR in Fetching Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};