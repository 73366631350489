import React, {useEffect, useState } from 'react'

import Faq from "react-faq-component";
import {FaqAPI} from '../../globalAPIs/FaqApi';

const styles={
    rowContentPaddingTop:'0px',
    rowContentPaddingBottom:'20px',
    rowContentPaddingLeft:'20px',
    rowContentPaddingRight	:'20px',

}
const config = {
    animate: true,
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};

function FaqPage() {
    const [Faqdata, setFAQ] = useState([]);

    useEffect(() => {
        const fetchFaq = async ()=>{
            try{
                const data = await FaqAPI();
                setFAQ(data.results)
            }catch(error){
                console.log(error)
            }
        }
        fetchFaq()

	}, [2]);

    const formattedResponses = Faqdata.map(response => ({
        title: response.question,
        content: response.answer
    }));
    
    // Now you have an array of formatted responses
    // You can use this array with the react-faq-component
    const formattedData = {
        rows: formattedResponses
    };

  return (  
        <>
            <div className="banner-container container">
                <div className="img-container">
                    <img src="/images/faq.png" alt="" />
                </div>
            </div>

            <section className='container faq-section'>
                <div className="right">
                    <Faq
                        data={formattedData}
                        config={config}
                        styles={styles}
                    />
                </div>
            </section>
        </>
    )
}

export default FaqPage