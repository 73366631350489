import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Components
import { SchoolTable } from '../components/Table/TableComponent';


// API
import { SchoolApi } from '../api/SchoolApi';



function School() {

	const [School, setSchool] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const data = await SchoolApi('');
				setSchool(data); // Make sure you also set the fetched data
			} catch (error) {

			}
		})();
	}, []);

	return (
		<>
			<div className="title-container">
				<h5>Schools</h5>
			</div>
			{School ? (<>
				{/* School Table */}
				<Box sx={{ flexGrow: 1, mt: 2 }}>

					<Grid container spacing={2}>
						<SchoolTable
							extrasmall={12} small={12} medium={12}
							cardtitle={'Schools'}
							data={School}
						>
						</SchoolTable>
					</Grid>
				</Box>
				{/* School Table */}
			</>) : (<></>)}

		</>
	)
}

export default School