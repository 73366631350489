import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

import '../style/components/client-slider/client-slider.css';
import { ClientTestimonialApi } from '../api/ClientTestimonial'

// Client Slider Settings
var ClientSliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    dots: false,
    settings: "slick",
    arrows: false,
    responsive: [
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                settings: "slick",
                arrows: false,
            }
        },
        {
            breakpoint: 850, // a unrealistically big number to cover up greatest screen resolution
            settings: {
                slidesToShow: 2,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                settings: "slick",
                arrows: false,
            }
        },


    ]
};

// Client API
const data = [
    { id: 1, name: "Vrijesh Rathod", message: "I specifically like the way they pack the set. Each piece was sorted as per building instruction and pack in seperate bags for ease of kids to build LEGO set. Amazing service for LEGO champs." },
    { id: 2, name: "Vrijesh Rathod", message: "I specifically like the way they pack the set. Each piece was sorted as per building instruction and pack in seperate bags for ease of kids to build LEGO set. Amazing service for LEGO champs." },
    { id: 3, name: "Vrijesh Rathod", message: "I specifically like the way they pack the set. Each piece was sorted as per building instruction and pack in seperate bags for ease of kids to build LEGO set. Amazing service for LEGO champs." },
]

function ClientSlider() {
    const [testimonial, setTestimonial] = useState()
    // Toy Category Slider Refrences
    const ClientSlider = React.useRef(null);
    useEffect(() => {
        const fetchTestimonial = async () => {
            try {
                const data = await ClientTestimonialApi();
                setTestimonial(data.results);
            } catch (error) {
                console.error("Error fetching testimonials data:", error);
            }
        };
        fetchTestimonial();
    }, [2]);

    return (
        <>
            <div className="client-slider-container">
                {testimonial ? (
                    <>
                        <Slider ref={ClientSlider} {...ClientSliderSettings} className="client-slider">
                            {testimonial.map((e) => {
                                return (
                                    <div className="client" key={e.id}>
                                        <div className="client-name">
                                            {e.name}
                                        </div>
                                        <p>
                                            {e.testimonial}
                                        </p>
                                    </div>
                                )
                            })}
                        </Slider>
                    </>) : (<></>)}

                <button className='arrow prev' onClick={() => ClientSlider?.current?.slickPrev()}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="23" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z" fill="#4B9E99" />
                    </svg>

                </button>
                <button className='arrow next' onClick={() => ClientSlider?.current?.slickNext()}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="23" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z" fill="#4B9E99" />
                    </svg>
                </button>
            </div>

        </>
    )
}

export default ClientSlider