import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";

import ToyCategorySlider from "../components/ToyCategorySlider";
import Card from "../components/Card";
import ClientSlider from "../components/ClientSlider";
import { Button } from "../components/Button";

import "../style/pages/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// API
import { ProductAPI, PopularProductAPI } from "../../globalAPIs/ProductApi";
import { FaqAPI } from "../../globalAPIs/FaqApi";
import PopularToySlider from "../components/PopularToySlider";

// Banner Slider settings
var BannerSlidersettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: "linear",
  arrows: false,
};

var PopularSlidersettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
    
  responsive: [
    {
        breakpoint: 850,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1 ,
            infinite: true,
            dots: false,
            settings: "slick",
            arrows: false,
        }
    },
   
    

]
};

const BannerData = [
  {
    key: 2,
    img: "images/banner-2.jpeg",
    href: "/toy-store",
  },
  {
    key: 3,
    img: "images/banner-3.jpeg",
    href: "/toy-store",
  },
  {
    key: 1,
    img: "images/banner-1.jpeg",
    href: "/toy-store",
  },
];

const MobileBannerData = [
  {
    key: 2,
    img: "images/mobile-1.svg",
    href: "/toy-store",
  },
  {
    key: 3,
    img: "images/mobile-2.svg",
    href: "/toy-store",
  },
];
const styles = {
  rowContentPaddingTop: "0px",
  rowContentPaddingBottom: "20px",
  rowContentPaddingLeft: "20px",
  rowContentPaddingRight: "20px",
};
const config = {
  animate: true,
  openOnload: 0,
  expandIcon: "+",
  collapseIcon: "-",
};

function Books(){
  var PopularSlidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
      
    responsive: [
      {
          breakpoint: 850,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1 ,
              infinite: true,
              dots: false,
              settings: "slick",
              arrows: false,
          }
      },
     
      
  
  ]
  };
  const PopularSlider = React.useRef(null); // Banner Slider Refrences

  const [PopularProduct, setPopularProduct] = useState([]);
  useEffect(() => {
    const fetchPopular = async () => {
      try {
        const data = await ProductAPI("&category=21&page_size=10");
        setPopularProduct(data.result);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPopular();
  }, []);

  return(
    <> 
      <section className="popular-card-container container">
      <div className="title">
        <h2>Books</h2>
        <a href="/toy-store?category=21">Show All</a>
      </div>
      <div className="popular-card-grid">
      <Slider
        ref={PopularSlider}
        {...PopularSlidersettings}
        className="banner-slider"
      >
          {PopularProduct.map((e) => {
            if (e.is_available) {
              return (
                <Card
                  
                  key={e.slug}
                  img={e.image}
                  name={e.name}
                  price={e.seven}
                  id={e.id}
                  slug={e.slug}
                  product={e}
                  short_description={e.small_description}
                  stock_status={e.available_stock}
                />
              );
            }
          })}
      </Slider>
      <button
        className="arrow prev"
        onClick={() => PopularSlider?.current?.slickPrev()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      <button
        className="arrow next"
        onClick={() => PopularSlider?.current?.slickNext()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      </div>
      </section>
    </>
  )
}

function Babies(){
  var PopularSlidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
      
    responsive: [
      {
          breakpoint: 850,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1 ,
              infinite: true,
              dots: false,
              settings: "slick",
              arrows: false,
          }
      },
     
      
  
  ]
  };
  const PopularSlider = React.useRef(null); // Banner Slider Refrences

  const [PopularProduct, setPopularProduct] = useState([]);
  useEffect(() => {
    
    const fetchPopular = async () => {
      try {
        const data = await ProductAPI("&age=16&page_size=10");
        setPopularProduct(data.result);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPopular();
  }, []);

  return(
    <> 
      <section className="popular-card-container container">
      <div className="title">
        <h2>For Your New Borns</h2>
        <a href="/toy-store?age=16">Show All</a>

      </div>
      <div className="popular-card-grid">
      <Slider
        ref={PopularSlider}
        {...PopularSlidersettings}
        className="banner-slider"
      >
          {PopularProduct.map((e) => {
            if (e.available_stock) {
              return (
                <Card
                  key={e.slug}
                  img={e.image}
                  name={e.name}
                  price={e.seven}
                  id={e.id}
                  slug={e.slug}
                  product={e}
                  short_description={e.small_description}
                  stock_status={e.available_stock}
                />
              );
            }
          })}
      </Slider>
      <button
        className="arrow prev"
        onClick={() => PopularSlider?.current?.slickPrev()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      <button
        className="arrow next"
        onClick={() => PopularSlider?.current?.slickNext()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      </div>
      </section>
    </>
  )
}

function BrainDevelopment(){
  var PopularSlidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
      
    responsive: [
      {
          breakpoint: 850,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1 ,
              infinite: true,
              dots: false,
              settings: "slick",
              arrows: false,
          }
      },
     
      
  
  ]
  };
  const PopularSlider = React.useRef(null); // Banner Slider Refrences

  const [PopularProduct, setPopularProduct] = useState([]);
  useEffect(() => {
    const fetchPopular = async () => {
      try {
        const data = await ProductAPI("&category=18&page_size=10");
        setPopularProduct(data.result);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPopular();
  }, []);

  return(
    <> 
      <section className="popular-card-container container">
      <div className="title">
        <h2>Brain Development</h2>
        <a href="/toy-store?category=18">Show All</a>

      </div>
      <div className="popular-card-grid">
      <Slider
        ref={PopularSlider}
        {...PopularSlidersettings}
        className="banner-slider"
      >
          {PopularProduct.map((e) => {
            if (e.available_stock) {
              return (
                <Card
                  key={e.slug}
                  img={e.image}
                  name={e.name}
                  price={e.seven}
                  id={e.id}
                  slug={e.slug}
                  product={e}
                  short_description={e.small_description}
                  stock_status={e.available_stock}
                />
              );
            }
          })}
      </Slider>
      <button
        className="arrow prev"
        onClick={() => PopularSlider?.current?.slickPrev()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      <button
        className="arrow next"
        onClick={() => PopularSlider?.current?.slickNext()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      </div>
      </section>
    </>
  )
}

function Creativity(){
  var PopularSlidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
      
    responsive: [
      {
          breakpoint: 850,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1 ,
              infinite: true,
              dots: false,
              settings: "slick",
              arrows: false,
          }
      },
     
      
  
  ]
  };
  const PopularSlider = React.useRef(null); // Banner Slider Refrences

  const [PopularProduct, setPopularProduct] = useState([]);
  useEffect(() => {
    const fetchPopular = async () => {
      try {
        const data = await ProductAPI("&category=19&page_size=10");
        setPopularProduct(data.result);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPopular();
  }, []);

  return(
    <> 
      <section className="popular-card-container container">
      <div className="title">
        <h2>Creativity & Imagination</h2>
        <a href="/toy-store?category=19">Show All</a>

      </div>
      <div className="popular-card-grid">
      <Slider
        ref={PopularSlider}
        {...PopularSlidersettings}
        className="banner-slider"
      >
          {PopularProduct.map((e) => {
            if (e.available_stock) {
              return (
                <Card
                  key={e.slug}
                  img={e.image}
                  name={e.name}
                  price={e.seven}
                  id={e.id}
                  slug={e.slug}
                  product={e}
                  short_description={e.small_description}
                  stock_status={e.available_stock}
                />
              );
            }
          })}
      </Slider>
      <button
        className="arrow prev"
        onClick={() => PopularSlider?.current?.slickPrev()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      <button
        className="arrow next"
        onClick={() => PopularSlider?.current?.slickNext()}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="23" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
            fill="#4B9E99"
          />
        </svg>
      </button>
      </div>
      </section>
    </>
  )
}

function Index() {
  const [Product, setProduct] = useState([]);
  const [PopularProduct, setPopularProduct] = useState([]);
  const [Faqdata, setFAQ] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await ProductAPI();
        setProduct(data.result); // Make sure you also set the fetched data
      } catch (error) {}
    };

    const fetchPopular = async () => {
      try {
        const data = await PopularProductAPI();
        setPopularProduct(data.result);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchFaq = async () => {
      try {
        const data = await FaqAPI();
        setFAQ(data.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
    fetchFaq();
    fetchPopular();
  }, [2]);

  const formattedResponses = Faqdata.slice(0, 4).map((response) => ({
    title: response.question,
    content: response.answer,
  }));

  // Now you have an array of formatted responses
  // You can use this array with the react-faq-component
  const formattedData = {
    rows: formattedResponses,
  };
  const BannerSlider = React.useRef(null); // Banner Slider Refrences
  const PopularSlider = React.useRef(null); // Banner Slider Refrences

  return (
    <>
      {/* Banner Container */}
      {/* <section className="banner-section container ">
        <div className="left">
            <span>Rent * Return * Repeat</span>
            <h1>
              Toys for every age group of kids
            </h1>
            <p>
              Elevate experiences for all ages, starting with a rental service for educational toys, games, and books for kids aged 1 to 8.
            </p>
            <a href="/toy-store">
                <div className="button yellow">
                  View Catalogue
                </div>
            </a>
        </div>
        <div className="right">
          <div className="img-container">
            <img src="/images/background-7.png" alt="" />
          </div>
        </div>
      </section> */}

      <section className="container about-us-container">
        <div className="title">
			{/* <h2>
	          How it Works
			</h2> */}
        </div>
        <div className="about-us-section">
          <div className="left">
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/signup.png" alt="" />
				</div>
				<div className="name">
					Sign up
				</div>
				<p>
					Create your account and log in.
				</p>
			</div>
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/cubes.png" alt="" />
				</div>
				<div className="name">
				Select
				</div>
				<p>
				Choose the products you want to rent and add to cart
				</p>
			</div>
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/checkout.png" alt="" />
				</div>
				<div className="name">
				Submit
				</div>
				<p>
				Follow the checkout process and your order is confirmed once you complete the payment 
				</p>
			</div>
          </div>
          <div className="right">
            <div className="img-container" style={{padding:"0px 80px"}}>
				      <img src="/images/1.svg" alt="" />
			      </div>
          </div>
		  <div className="star" style={{zIndex:"1"}}>
			  <img src="/images/star.png" alt="" />
		  </div>
        </div>
      </section>
      {/* Banner Container */}

      {/* Banner Container */}
      <section className="mobile-banner banner-section  mt-0">
        <Slider
          ref={MobileBannerData}
          {...BannerSlidersettings}
          className="banner-slider"
        >
          {MobileBannerData.map(({ img, href, key }) => {
            return (
              <a href={href} key={key}>
                <div className="img-container">
                  <img src={img} alt="" />
                </div>
              </a>
            );
          })}
        </Slider>
      </section>
      {/* Banner Container */}

      {/* Category Slider Container */}
      <section className="toy-categories-slider container">
        <div className="title">
          <h2>Skills</h2>
          <a href="/toy-store">Show All</a>
        </div>
        <ToyCategorySlider></ToyCategorySlider>
      </section>
      {/* Category Slider Container */}

      {/* Popular Toys Card Container */}
      <section className="popular-card-container container">
        <div className="title">
          <h2>Popular Toys</h2>
          <a href="/popular-toys">Show All</a>
        </div>
        <div className="popular-card-grid">
        <Slider
          ref={PopularSlider}
          {...PopularSlidersettings}
          className="banner-slider"
        >
            {PopularProduct.map((e) => {
              if (e.available_stock) {
                return (
                  <Card
                    key={e.slug}
                    img={e.image}
                    name={e.name}
                    price={e.seven}
                    id={e.id}
                    slug={e.slug}
                    product={e}
                    short_description={e.small_description}
                    stock_status={e.available_stock}
                  />
                );
              }
            })}
        </Slider>
        <button
          className="arrow prev"
          onClick={() => PopularSlider?.current?.slickPrev()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM26.1213 17.1213C27.2928 15.9497 27.2928 14.0503 26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787L12.8787 21.8787C11.7071 23.0503 11.7071 24.9498 12.8787 26.1213L21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213C27.2928 33.9498 27.2928 32.0502 26.1213 30.8787L22.2426 27H33C34.6569 27 36 25.6569 36 24C36 22.3432 34.6569 21 33 21H22.2426L26.1213 17.1213Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
        <button
          className="arrow next"
          onClick={() => PopularSlider?.current?.slickNext()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23" fill="white" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7452 37.2549 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2549 10.7452 48 24 48ZM35.1213 21.8787L26.1213 12.8787C24.9498 11.7071 23.0503 11.7071 21.8787 12.8787C20.7071 14.0503 20.7071 15.9497 21.8787 17.1213L25.7574 21H15C13.3432 21 12 22.3431 12 24C12 25.6569 13.3432 27 15 27H25.7574L21.8787 30.8787C20.7071 32.0502 20.7071 33.9498 21.8787 35.1213C23.0503 36.2928 24.9498 36.2928 26.1213 35.1213L35.1213 26.1213C36.2928 24.9498 36.2928 23.0503 35.1213 21.8787Z"
              fill="#4B9E99"
            />
          </svg>
        </button>
        </div>
      </section>
      {/* Popular Toys Card Container */}

      <Books></Books>
      <BrainDevelopment></BrainDevelopment>
      <Creativity></Creativity>
      <Babies></Babies>
      {/* About Us Container */}
      {/* <section className="container about-us-container">
        <div className="title">
			<h2>
	          How it Works
			</h2>
        </div>
        <div className="about-us-section">
          <div className="left">
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/signup.png" alt="" />
				</div>
				<div className="name">
					Sign up
				</div>
				<p>
					Create your account and log in.
				</p>
			</div>
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/cubes.png" alt="" />
				</div>
				<div className="name">
				Select
				</div>
				<p>
				Choose the products you want to rent and add to cart
				</p>
			</div>
			<div className="icon-container">
				<div className="img-container">
					<img src="/images/checkout.png" alt="" />
				</div>
				<div className="name">
				Submit
				</div>
				<p>
				Follow the checkout process and your order is confirmed once you complete the payment 
				</p>
			</div>
          </div>
          <div className="right">
            <div className="img-container" style={{padding:"0px 80px"}}>
				      <img src="/images/1.svg" alt="" />
			      </div>
          </div>
		  <div className="star" style={{zIndex:"1"}}>
			  <img src="/images/star.png" alt="" />
		  </div>
        </div>
      </section> */}

      <section className="banner-section container ">
        <div className="left">
            <span>Rent * Return * Repeat</span>
            <h1>
              Toys for every age group of kids
            </h1>
            <p>
              Elevate experiences for all ages, starting with a rental service for educational toys, games, and books for kids aged 1 to 8.
            </p>
            <a href="/toy-store">
                <div className="button yellow">
                  View Catalogue
                </div>
            </a>
        </div>
        <div className="right">
          <div className="img-container">
            <img src="/images/background-7.png" alt="" />
          </div>
        </div>
      </section>
      {/* About Us Container */}

	  

      {/* Work with the best Container */}
      <section className="container best-section">
        <div className="title">
          <h2>
            Always with the best
          </h2>
        </div>
        <div className="best-container">
          <div className="left">
            <h1>
              We work <br /> with the Best
            </h1>
          </div>
          <div className="right">
            <div className="top">
              <div className="title">Schools We Work with</div>
              <div className="marquee-container">
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/1.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/2.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/3.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/4.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/5.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/6.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/7.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/8.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/9.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/10.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/11.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/12.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/13.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/1.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/2.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/3.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/4.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/5.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/6.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/7.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/8.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/9.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/10.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/11.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/12.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/schools/13.webp" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="top down">
              <div className="marquee-container">
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/1.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/2.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/3.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/4.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/5.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/6.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/7.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/8.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/9.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/10.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/11.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/12.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/13.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/14.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/15.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/16.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/17.webp" alt="" />
                  </div>
                </div>

                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/18.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/19.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/20.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/21.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/22.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/23.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/24.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/25.webp" alt="" />
                  </div>
                </div>
                <div className="box-1">
                  <div className="logo-container">
                    <img src="images/brands/26.webp" alt="" />
                  </div>
                </div>
              </div>
              <div className="title">Brands We Work With</div>
            </div>
          </div>
        </div>
      </section>
      {/* Work with the best Container */}

      {/* Client Testimonials Container */}
      <section className="container client-section">
        <div className="title">
          <h2>
            Client Testimonials
          </h2>
        </div>
        <div className="client-slider-container">
          <ClientSlider></ClientSlider>
        </div>
      </section>
      {/* Client Testimonials Container*/}

      {/* FAQ Container */}
      <section className="container faq-section">
        <div className="left">
          <h2>
            Frequently <br /> Asked <br />
            Question
          </h2>
          <h4>Don’t worry, we have answers</h4>
        </div>
        <div className="right">
          <Faq data={formattedData} config={config} styles={styles} />
          <Link to={"faq"}>
            <div className="button yellow">View all FAQs</div>
          </Link>
        </div>
      </section>
      {/* FAQ Container */}
    </>
  );
}

export default Index;
