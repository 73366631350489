import React, { useState, useEffect } from "react";
import "../style/components/button/button.css";

import { useAppContext } from "../context/UserContext";
import Swal from "sweetalert2";
import { areRenderContextsEqual } from "@mui/x-data-grid/hooks/features/virtualization/useGridVirtualScroller";

// APIs
import { AddtoCartApi, CartUpdateApi } from "../api/CartInfo";
import { AddFavouritesApi, FavouritesApi } from "../api/Favourites";

function CardButton(props) {
  const { customer, isSuperUser, updateCartcount, cartCount } = useAppContext();

  const addToCart = async (product) => {
    if (customer || isSuperUser) {
      // Call add to cart Api
      console.log(product);
      try {
        const days = props?.day === undefined ? 7 : props.day;
        const response = await AddtoCartApi(product, props?.day);
        console.log(response);
        const editCart = await CartUpdateApi(
          response?.data?.id,
          response?.data?.item_details?.id,
          days,
          response?.data?.desired_delivery_date
        );

        console.log("Add to cart Responseee", response, editCart);
        if (response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Cart",
            text: "Added Successfully",
            showConfirmButton: false,
          });
          const newCount = cartCount + 1;
          updateCartcount(newCount);
        } else {
          Swal.fire({
            icon: "error",
            text: '"You cannot add more of this product in your cart.',
            showConfirmButton: false,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You cannot add more of this product in your cart.",

          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to Login!",
        footer: '<a href="/login">Login</a>',
        showConfirmButton: false,
      });
    }
  };

  const QucikCheckout = async (product) => {
    if (customer || isSuperUser) {
      // Call add to cart Api
      console.log(product);
      try {
        const days = props?.day === undefined ? 7 : props.day;
        const response = await AddtoCartApi(product);
        const editCart = await CartUpdateApi(
          response?.data?.id,
          response?.data?.item_details?.id,
          days,
          response?.data?.desired_delivery_date
        );
        console.log(response);
        const newCount = cartCount + 1;
        updateCartcount(newCount);
        window.location.href = "/cart";
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
          footer: '<a href="/login">Login</a>',
          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to Login!",
        footer: '<a href="/login">Login</a>',
        showConfirmButton: false,
      });
    }
  };

  const addToWishlist = async (product, id) => {
    if (customer || isSuperUser) {
      // Call add to cart Api
      try {
        const response = await AddFavouritesApi(product, id);

        console.log("Add to cart Responseee", response);
        if (response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Wishlist",
            text: "Added Successfully",
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: '"You cannot add more of this product in your wishlist.',
            showConfirmButton: false,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You cannot add more of this product in your cart.",

          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to Login!",
        footer: '<a href="/login">Login</a>',
        showConfirmButton: false,
      });
    }
  };
  console.log("propsss", props?.profile);

  return (
    <>
      {props.status ? (
        <>
          <div className="btn-container">
            {/* <button
              onClick={() => QucikCheckout(props.product)}
              className="rent-now"
            >
              <span>Rent Now</span>
            </button> */}
            <button
              onClick={() => addToCart(props.product)}
              className="add-to-cart"
            >
              <span>Add to Cart</span>
            </button>
            {/* <button
              onClick={() =>
                addToWishlist(
                  props.product?.id,
                  props?.profile?.user_details?.id
                )
              }
              className="button green"
            >
              <span>Add to Wishlist</span>
            </button> */}
            {/* <a
              href={
                "https://wa.me/+919321146692?text=I%20want%20" +
                props.product.name
              }
              className="whatsapp button"
            >
              <div className="icon-container">
                <img src="/images/whatsapp.svg" alt="" />
              </div>
              <span>Enquire now</span>
            </a> */}
          </div>
        </>
      ) : (
        <>
          <div className="btn-container">
            <div className="out-of-stock">
              <span>A Kid is Playing with toy</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function Button(props) {
  return (
    <a href={props.link}>
      <div className={props.color + "button"}>{props.text}</div>
    </a>
  );
}

function Buynow(props) {
  const [show, setshow] = useState(props.show);
  const { customer, isSuperUser, updateCartcount, cartCount } = useAppContext();

  const removeFav = async (product_id) => {
    try {
      const data = await FavouritesApi(product_id);
      window.location.reload();
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const QucikCheckout = async (product) => {
    if (customer || isSuperUser) {
      // Call add to cart Api
      const repsonse = await AddtoCartApi(product);
      if (repsonse) {
        window.location.href = "cart";
        updateCartcount(cartCount + 1);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to Login!",
        footer: '<a href="/login">Login</a>',
        showConfirmButton: false,
      });
    }
  };
  return (
    <>
      <div className="btn-container" style={{ justifyContent: "flex-end" }}>
        {show ? (
          <>
            <div
              onClick={() => QucikCheckout(props.product)}
              className="rent-now"
            >
              <span>Rent now</span>
            </div>
          </>
        ) : (
          <></>
        )}
        <div onClick={() => removeFav(props.id)} className="icon-container">
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="21.8"
              height="23"
              rx="1.5"
              fill="#B72126"
              fillOpacity="0.13"
              stroke="#B72126"
            />
            <path
              d="M7.40039 9.5H15.4004M14.9004 9.5L14.4667 15.5712C14.4293 16.0945 13.9939 16.5 13.4693 16.5H9.33151C8.80687 16.5 8.37143 16.0945 8.33405 15.5712L7.90039 9.5H14.9004ZM10.4004 11.5V14.5V11.5ZM12.4004 11.5V14.5V11.5ZM12.9004 9.5V8C12.9004 7.72386 12.6765 7.5 12.4004 7.5H10.4004C10.1243 7.5 9.90039 7.72386 9.90039 8V9.5H12.9004Z"
              stroke="#B72126"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export { CardButton, Button, Buynow };
