import React, { useState,useEffect } from 'react'

import Slider from "react-slick";
import '../style/components/toy-category-slider/toy-category-slider.css';

// APIs
import {ToyCategoryAPI} from '../../globalAPIs/ToyCategoryApi';


// Toy Category Slider settings
var ToyCategorySliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 3,
    infinite: false ,
    dots: false,
    settings: "unslick",
    arrows: false,
    responsive: [
        {
            breakpoint: 850,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false,
                settings: "slick",
                arrows: false,
            }
        },
        {
            breakpoint: 1200, 
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                settings: "slick",
                arrows: false,
            }
        },
        

    ]
};

// API DATA


function ToyCategorySlider() {

    const [ToyCategoryData, setProductCategories] = useState([]);


    useEffect(() => {
        
        const fetchToyCategory = async () => {
            try {
                const data = await ToyCategoryAPI();
                setProductCategories(data.results)
            } catch (error) {
                console.error("Error fetching cart data:", error);
            }
        };
        fetchToyCategory();
    }, []);

    // Toy Category Slider Refrences
    const ToyCategorySlider = React.useRef(null);

    return (
        <>  

            <div className="slider-wrapper">
                <Slider ref={ToyCategorySlider} {...ToyCategorySliderSettings} className='category-card-container' >
                    {ToyCategoryData.map(({ image, label, href, id }) => {
                        return (
                                <a href={'/toy-store?category='+id} key={id} className='category-card'>
                                    <div className="category-img">
                                        <img src={image} alt="" />
                                    </div>
                                    <div className="category-name"> {label}</div>
                                </a>
                        )
                    })}
                </Slider>
                
            </div>

        </>
    )
}

export default ToyCategorySlider