import axios from 'axios';
import {BASE_API_URL} from '../server'

export const ToyCategoryAPI = async () => {
    const apiEndpoint = `${BASE_API_URL}api/toy-category/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const data = response.data
        return data;

    } catch (error) {
        console.log('***** ERROR in ToyCategoryAPI Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

export const ToySubCategoryAPI = async () => {
    const apiEndpoint = `${BASE_API_URL}api/product/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const data = response.data.filter_options.sub_categories
        return data;

    } catch (error) {
        console.log('***** ERROR in ToyCategoryAPI Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};