import React from 'react'

function NoData() {
  return (
    <div className='coming-soon'>
        <div className="img-container">
            <img src="/images/no-data.png" alt="" />
        </div>
    </div>
  )
}

export default NoData