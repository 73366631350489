import React from 'react'

// CSS
import '../style/pages/about/about.css';

function AboutUs() {
    return (
        <>
            <section className='container about-us-banner-section '>
                <div className="left">
                    <h1>
                        <span className='black'>we are</span><br />
                        <span className='green-color'>Growing</span><br />
                        <span className='yellow-color'>Giraffe</span>
                    </h1>
                </div>
                <div className="right">
                    <div className="img-container">
                        <img src="/images/about-banner.svg" alt="" />
                    </div>
                </div>
            </section>
            <section className='container about-us'>
                <div className="left">
                    <div className="img-container">
                        <img src="/images/about.png" alt="" />
                    </div>
                </div>
                <div className="right">
                    <div className="title">
                        About US
                    </div>
                    <p>
                        GrowingGiraffe is a toy library where you can subscribe and have access to toys and books listed in our catalogue. Each item listed on our website is deeply researched on how to engage, stimulate and entertain children in age -appropriate ways.
                        We offer toys/books which have originated from 8 different countries.
                        UK, USA, Italy, Germany, Australia and Thailand to name a few.
                        Toys listed in our catalogue cost upto Rs. 10000, you can rent them by subscribing to any of our plan.
                        Each Child is different and thus we have a personalized skill tracker to help you keep a tab on your child's progress while they have their fun growing up.
                        While we are child centric, we are also pocket friendly.
                    </p>
                </div>
            </section>
            <section className='container why-us'>
                <div className="box-container">
                    <div className="title">
                        Why Us
                    </div>
                    <div className="box">
                        <p>
                            Children come into this world like blank slates; curious and hungry to absorb whatever they see, learn whatever they experience and write their own life stories. Toys provide a focal point to the children and spark their imagination and creativity. Through toys, a child explores a lot of abstract concepts, develops their gross and fine motor skills and gains cognitive understanding in a gradual play method. The freedom and flexibility that a toy offers to children in their early years forms a strong base for all their future learning.A toy a day keeps sadness away!
                        </p>
                    </div>
                </div>
                <div className="box-container">
                    <div className="title">
                        Why play?
                    </div>
                    <div className="box">
                        <p>
                            Children have a full time occupation. It’s called PLAY!
                            Play is pivotal to a child's development. Playing helps children develop their creativity and imagination and boosts their brain development. Children learn to interact socially. When play is allowed to be child driven, children practice decision-making skills, move at their own pace, discover their own areas of interest. Just by playing, a child can conquer their fears, gain confidence, learn life skills like sharing and caring, develop emotional intelligence and of course, build concepts.
                            PLAY IS POWERFUL!
                        </p>
                    </div>
                </div>
                <div className="box-container">
                    <div className="title">
                        Why Books?
                    </div>
                    <div className="box">
                        <p>
                            It is proven that the earlier you start reading to children, the earlier they start talking and reading. Reading daily also helps create an emotional bond between the parent and the child. Each book carries in itself a different world of its own. And all that information provides the child with the background knowledge about the world they live in. It helps them make sense of all that they see, hear and feel.
                            There is more treasure in books than in all the pirate’s loot on treasure island. ~~ Walt Disney
                        </p>
                    </div>
                </div>
            </section>
            <section className='container our-story'>
                <div className="title">
                    <h3>Our Story</h3>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2020
                        </div>
                        <div className="img-container">
                            <img src="/images/vishal.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Vishal Zaveri - The Trailblazing Initiator
                        </div>
                        <p>
                            Meet Vishal Zaveri, a seasoned Sales and Operations expert. In 2020, he welcomed a radiant baby girl into his life. As he worked from the cozy confines of his home office, he couldn't help but notice how his daughter's once-beloved toys transformed into forgotten relics. That's when it hit him like a bolt from the blue: What happens to these unused toys? Thus, the first puzzle piece fell into place—the concept of toy rentals.
                        </p>
                    </div>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2020-2021
                        </div>
                        <div className="img-container">
                            <img src="/images/mitesh.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Mitesh Khajanchi - The Logistics Maestro
                        </div>
                        <p>
                            Vishal's quest led him to Mitesh Khajanchi, a Chartered Accountant with a wealth of experience in
                            distribution spanning over six decades. Together, they embarked on a brainstorming journey that
                            resulted in a brilliant solution—an online platform that simplifies the process of selecting and delivering
                            top-notch toys and books to parents' doorsteps
                        </p>
                    </div>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2021
                        </div>
                        <div className="img-container">
                            <img src="/images/vinit.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Vinit Bafna - The Champion of Sustainability
                        </div>
                        <p>
                            As the team expanded, Vinit Bafna stepped in, armed with eight years of experience in the textile
                            industry. Sustainability became his guiding star, inspired by his three-year-old daughter. For Vinit,
                            Growing Giraffe had to be more than just a business; it had to lead the way towards a more sustainable
                            future.
                        </p>
                    </div>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2022
                        </div>
                        <div className="img-container">
                            <img src="/images/shreya.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Shreya Jhaveri - The Curator of Learning
                        </div>
                        <p>
                            Shreya Jhaveri, a passionate educationist, left her legal practice to follow her heart. Her mission was to
                            blend fun and education seamlessly. She became the compass guiding parents to the perfect solution,
                            helping them choose toys that fostered holistic child development.
                        </p>
                    </div>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2022-2023
                        </div>
                        <div className="img-container">
                            <img src="/images/unnati.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Unnati Jhaveri - The Creative Alchemist
                        </div>
                        <p>
                            Unnati Jhaveri, another educationist on the team, added her own brand of magic. Her expertise in
                            phonics and creative product design elevated Growing Giraffe's offerings to a new level. Unnati didn't
                            just solve problems with toys; she also had a knack for turning living spaces into creative havens. Her
                            contribution felt like discovering a missing puzzle piece.
                        </p>
                    </div>
                </div>
                <div className="team">
                    <div className="left">
                        <div className="year">
                            Year 2023 (Present)
                        </div>
                        <div className="img-container">
                            <img src="/images/nidhi.svg" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="name">
                            Nidhi Shah - The Kid Whisperer
                        </div>
                        <p>
                            Nidhi Shah, a mother with two passions—playing with kids and cooking delicious meals—completed the
                            puzzle. Her expertise in nurturing children through toys made her a master at product design for
                            Growing Giraffe. Moreover, she was the social connector, bridging the team with the outside world.
                            In the end, this captivating story of Growing Giraffe Toy Library came together with each team member
                            contributing their unique solution to the quest. Together, they created a treasure trove of joy for the
                            people of Mumbai, offering a solution to a common problem—making childhood dreams come true, one
                            rented toy at a time
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs