import React, { useEffect, useRef, useState } from "react";
import {
  AddProfileSubscriptionApi,
  CustomerApi,
  EditCustomerApi,
  EditProfileSubscriptionApi,
  ProfileSubscription,
} from "../../api/CustomerApi";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { stations } from "../../../../web/pages/Cart";
import { SchoolApi } from "../../api/SchoolApi";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { OrderApi } from "../../api/OrderApi";
import { OrderTable } from "../../components/Table/TableComponent";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));

const EditCustomer = () => {
  const theme = useTheme();
  const tableRef = useRef();
  const excelExportRef = useRef(null);

  const style2 = {
    // Default width
    [theme.breakpoints.up("sm")]: {
      width: 300, // Width on small devices and up
    },
    [theme.breakpoints.up("md")]: {
      width: 400, // Width on medium devices and up
    },
    [theme.breakpoints.up("lg")]: {
      width: 500, // Width on large devices and up
    },
    p: 4,
  };

  const { customerId } = useParams();
  const { userId } = useParams();
  const [subscriptionData, setsubscriptionData] = useState(null);
  const [customerData, setcustomerData] = useState(null);

  const [openGGTokenModal, setopenGGTokenModal] = useState(false);
  const [openGGTopUPTokenModal, setopenGGTopUpTokenModal] = useState(false);
  const [value, setValue] = useState(0);
  const [comments, setComments] = useState("");
  const [tokenLiesure, setTokenLiesure] = useState({
    selectedTokens: "",
    comment: "",
    createdDate: "",
  });

  const [customerDetail, setcustomerDetail] = useState(null);
  const [subscriptionDetail, setsubscriptionDetail] = useState(null);

  const [School, setSchool] = useState(null);
  const [orderHistory, setorderHistory] = useState(null)

  // State to hold the milestone children
  const [children, setChildren] = useState(null);

  // Function to handle adding a new child
  const addChild = (e) => {
    e.preventDefault();
    if (!children) {
      // If there are no children yet, add the first child
      setChildren([{ child_name: "", age: "", id: uuidv4(), milestone: null }]);
    } else {
      // Otherwise, add a new child to the existing array
      setChildren([
        ...children,
        { child_name: "", age: "", id: uuidv4(), milestone: null },
      ]);
    }
  };

  // Function to handle changes to a child's name and age
  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const usersType = ["school", "customer"];
  const subscription = ["3 Months", "6 Months", "12 Months"];

  useEffect(() => {
    (async () => {
      try {
        const data = await SchoolApi('?page_size=1000');
        setSchool(data); // Make sure you also set the fetched data
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await OrderApi(`user=${customerId}&page_size=100000`);
        console.log(data)
        setorderHistory(data?.data?.results); // Make sure you also set the fetched data
      } catch (error) { }
    })();
  }, [customerId]);

  console.log('order History', orderHistory)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setcustomerDetail((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubChange = (e) => {
    const { name, value } = e.target;
    setsubscriptionDetail((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const incrementValue = () => {
    setValue((prevValue) => prevValue + 100);
  };

  const increment500Value = () => {
    setValue((prevValue) => prevValue + 500);
  };

  const handleAddToken = () => {
    setcustomerDetail((prevData) => ({
      ...prevData,
      gg_token: prevData.gg_token + parseInt(value),
    }));

    // Store the transaction details
    setTokenLiesure({
      selectedTokens: `+${parseInt(value)}`,
      comment: comments,
      createdDate: new Date().toISOString().split("T")[0], // Get today's date in YYYY-MM-DD format
    });

    setValue(0);
    setopenGGTokenModal(false);
  };

  const handleSubtractToken = () => {
    setcustomerDetail((prevData) => ({
      ...prevData,
      gg_token: prevData.gg_token - parseInt(value),
    }));

    // Store the transaction details
    setTokenLiesure({
      selectedTokens: `-${parseInt(value)}`,
      comment: comments,
      createdDate: new Date().toISOString().split("T")[0], // Get today's date in YYYY-MM-DD format
    });

    setValue(0);
    setopenGGTokenModal(false);
  };

  const handleAddTopupToken = () => {
    setcustomerDetail((prevData) => ({
      ...prevData,
      topup_token: prevData.topup_token + parseInt(value),
    }));

    // Store the transaction details
    setTokenLiesure({
      selectedTokens: `+${parseInt(value)}`,
      comment: comments,
      createdDate: new Date().toISOString().split("T")[0], // Get today's date in YYYY-MM-DD format
    });

    setValue(0);
    setopenGGTopUpTokenModal(false);
  };

  const handleSubtractTopupToken = () => {
    setcustomerDetail((prevData) => ({
      ...prevData,
      topup_token: prevData.topup_token - parseInt(value),
    }));

    // Store the transaction details
    setTokenLiesure({
      selectedTokens: `-${parseInt(value)}`,
      comment: comments,
      createdDate: new Date().toISOString().split("T")[0], // Get today's date in YYYY-MM-DD format
    });

    setValue(0);
    setopenGGTopUpTokenModal(false);
  };

  //   const [customerSubscriptionDetail, setcustomerSubscriptionDetail] = useState({

  //   })

  useEffect(() => {
    (async () => {
      try {
        const subData = await ProfileSubscription(`&user=${customerId}`);
        const fetchedCustomerData = await CustomerApi(`&user=${customerId}`);
        const order = await OrderApi(`&user=${customerId}`);
        console.log(fetchedCustomerData)
        setsubscriptionData(subData?.results[0]);
        setcustomerData(fetchedCustomerData?.results[0]);
        setChildren(fetchedCustomerData?.results[0]?.milestone?.child);

        // Set customerDetail only if customerData is fetched
        if (fetchedCustomerData?.results[0]) {
          setcustomerDetail({
            name: fetchedCustomerData?.results[0]?.name,
            gg_token: fetchedCustomerData?.results[0]?.gg_token,
            topup_token: fetchedCustomerData?.results[0]?.topup_token,
            address_1: fetchedCustomerData?.results[0]?.address_1 + fetchedCustomerData?.results[0]?.address_2,
            city: fetchedCustomerData?.results[0]?.city,
            station: "",
            school_name: fetchedCustomerData?.results[0]?.school_name,
            user: fetchedCustomerData?.results[0]?.user?.id,  
            user_type: fetchedCustomerData?.results[0]?.user_type,
            mobile_number: fetchedCustomerData?.results[0]?.mobile_number,
            milestone: fetchedCustomerData?.results[0]?.milestone,
          });
        }

        if (subData?.results[0]) {
          setsubscriptionDetail({
            subscription: subData?.results[0]?.subscription?.id,
            start_date: subData?.results[0]?.start_date,
            end_date: subData?.results[0]?.end_date,
            is_comission_paid: subData?.results[0]?.is_comission_paid,
            is_deposit_refunded: subData?.results[0]?.is_deposit_refunded,
            user: fetchedCustomerData?.results[0]?.user?.id,
          });
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(customerData)
    const existingTokenLeisureArray =
      customerDetail?.milestone?.token_leisure || [];

    // Append the new object to the existing array
    const updatedTokenLeisureArray = [
      ...existingTokenLeisureArray,
      tokenLiesure,
    ];

    //milestone object
    const milestone = {
      child: children,
      token_leisure: updatedTokenLeisureArray,
    };
    const milestoneJson = JSON.stringify(milestone);
    // Create FormData object
    const formData = new FormData();
    const SubscriptionForm = new FormData();

    for (const key in customerDetail) {
      if (key === "school_name") {
        // If the key is "school_name", check if it exists before accessing its id property
        formData.append(key, customerDetail[key]?.id || ""); // Provide a default value if customerDetail[key] is null
      } else if (key === "milestone") {
        formData.append(key, milestoneJson);
      } else {
        // For other keys, append the original value
        formData.append(key, customerDetail[key]);
      }
    }

    for (const key in subscriptionDetail) {
      // For other keys, append the original value
      SubscriptionForm.append(key, subscriptionDetail[key]);
    }

    try {
      // Make the API request using axios
      const response = await EditCustomerApi(formData, customerData?.id);
      if (subscriptionData) {
        await EditProfileSubscriptionApi(
          SubscriptionForm,
          subscriptionData?.id
        );
      } else {
        await AddProfileSubscriptionApi(SubscriptionForm);
      }

      // Log the response from the API
      console.log("Product API Response:", response);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error posting data:", error);
    } finally {
      setcustomerDetail(null);
      window.location.href = "/admin/customers";
    }
  };

  // const formik = useFormik({
  //   initialValues: {
  //     name: customerData?.name,
  //     gg_token: customerData?.gg_token+customerData?.topup_token,
  //     address_1: customerData?.address_1,
  //     address_2: customerData?.address_2,
  //     city: customerData?.city,
  //     station: "",
  //     school_name: customerData?.school_name,
  //     user: customerData?.user?.id,
  //     user_type: customerData?.user_type,
  //     mobile_number: customerData?.mobile_number,
  //     milestone: customerData?.milestone,

  //     subscription: subscriptionData?.subscription?.id,
  //     start_date: subscriptionData?.start_date,
  //     end_date: subscriptionData?.end_date,
  //     is_comission_paid: subscriptionData?.is_comission_paid,
  //     is_deposit_refunded: subscriptionData?.is_deposit_refunded,
  //   },
  //   // validationSchema: validationSchema,

  //   onSubmit: async (values) => { },
  // });

  console.log("edit Customerrrrrr", customerData, customerDetail);

  return (
    <div>
      <div className="title-container">
        <h5>Edit Customer</h5>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Item className="popular-box">
              {customerData ? (
                <form className="product-add">
                  <div className="name">Customer Details</div>
                  <div className="form-group-container">
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          name="name"
                          value={customerDetail?.name}
                          onChange={handleChange}
                          required
                          label="Name"
                          color="green"
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="gg_token"
                          value={customerDetail?.gg_token}
                          // onChange={handleChange}
                          name="gg_token"
                          required
                          label="GG Tokens"
                          color="green"
                        />
                      </FormControl>
                      <div
                        className="icon-container"
                        onClick={() => setopenGGTokenModal(true)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="1.5"
                            fill="#4B9E99"
                            fillOpacity="0.12"
                            stroke="#4B9E99"
                          />
                          <path
                            d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                            fill="#4B9E99"
                          />
                          <path
                            d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                            fill="#4B9E99"
                          />
                        </svg>
                      </div>
                      
                    </div>
                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          id="topup_token"
                          value={customerDetail?.topup_token}
                          // onChange={handleChange}
                          name="topup_token"
                          required
                          label="Topup Token"
                          color="green"
                        />
                      </FormControl>

                      <div
                        className="icon-container"
                        onClick={() => setopenGGTopUpTokenModal(true)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="1.5"
                            fill="#4B9E99"
                            fillOpacity="0.12"
                            stroke="#4B9E99"
                          />
                          <path
                            d="M14.8687 6.86853C15.4935 6.24369 16.5066 6.24369 17.1314 6.86853C17.7563 7.49336 17.7563 8.50643 17.1314 9.13127L16.4971 9.76559L14.2344 7.50284L14.8687 6.86853Z"
                            fill="#4B9E99"
                          />
                          <path
                            d="M13.1034 8.63428L6.40039 15.3372V17.6H8.66313L15.3661 10.897L13.1034 8.63428Z"
                            fill="#4B9E99"
                          />
                        </svg>
                      </div>
                    </div>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="Address 1"
                        name="address_1"
                        value={customerDetail?.address_1}
                        onChange={handleChange}
                        required
                        label="Address "
                        color="green"
                      />
                    </FormControl>
                    

                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          name="mobile_number"
                          value={customerDetail?.mobile_number}
                          onChange={handleChange}
                          required
                          label="Mobile Number"
                          color="green"
                        />
                      </FormControl>
                    </div>
                    {/* <Autocomplete
                      name="selectedStation"
                      value={customerDetail?.station}
                      onChange={(event, newValue) => {
                        // Handle the selection change here
                        setcustomerDetail((prev) => ({
                          ...prev,
                          station: newValue,
                        })); // Set the selected user value
                      }}
                      options={stations}
                      renderInput={(params) => (
                        <TextField
                          color="green"
                          {...params}
                          label="Select Station"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                    /> */}

                    <Autocomplete
                      name="selectedSchool"
                      value={
                        customerDetail?.school_name
                          ? {
                            name: customerDetail?.school_name.name,
                            id: customerDetail?.school_name.id,
                          }
                          : null
                      }
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        // Handle the selection change here
                        setcustomerDetail((prev) => ({
                          ...prev,
                          school_name: newValue,
                        })); // Set the selected user value
                      }}
                      options={School}
                      renderInput={(params) => (
                        <TextField
                          color="green"
                          {...params}
                          label="Select School"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <TextField
                          fullWidth
                          name="city"
                          value={customerDetail?.city}
                          onChange={handleChange}
                          required
                          label="City"
                          color="green"
                        />
                      </FormControl>
                      <Autocomplete
                        name="userType"
                        fullWidth
                        value={customerDetail?.user_type}
                        onChange={(event, newValue) => {
                          // Handle the selection change here
                          setcustomerDetail((prev) => ({
                            ...prev,
                            user_type: newValue,
                          })); // Set the selected user value
                        }}
                        options={usersType}
                        renderInput={(params) => (
                          <TextField
                            color="green"
                            {...params}
                            label="Select User Type"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* //Mile Stone Childrens */}
                  <div>
                    {children &&
                      children.map((child, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "10px 0",
                          }}
                        >
                          <TextField
                            label={`Child ${index + 1} Name`}
                            value={child.child_name}
                            color="green"
                            fullWidth
                            onChange={(e) =>
                              handleChildChange(
                                index,
                                "child_name",
                                e.target.value
                              )
                            }
                          />

                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              id="demo-simple-select-label"
                              color="green"
                            >
                              Age
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              color="green"
                              onChange={(e) =>
                                handleChildChange(index, "age", e.target.value)
                              }
                              label="Age"
                              value={child.age}
                            >
                              <MenuItem key="1-2" value="1-2">
                                1-2
                              </MenuItem>
                              <MenuItem key="2-4" value="2-4">
                                2-4
                              </MenuItem>
                              <MenuItem key="4-6" value="4-6">
                                4-6
                              </MenuItem>
                              <MenuItem key="6-8" value="6-8">
                                6-8
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ))}
                    <button
                      className="button yellow my-20"
                      onClick={(e) => addChild(e)}
                    >
                      Add Child
                    </button>
                  </div>

                  {/* //Subscription */}
                  {/* <div className="form-group">
                    <FormControl fullWidth>
                      <InputLabel color="green" name="Select User Type" id="select-subscription-label">
                        Select Subscription
                      </InputLabel>
                      <Select
                        labelId="select-subscription-label"
                        id="select-subscription"
                        name="Select Subscription"
                        label="Select Subscription"
                        color="green"
                        value={subscriptionDetail?.subscription}
                        onChange={(e) =>
                          setsubscriptionDetail((prev) => ({
                            ...prev,
                            subscription: e.target.value,
                          }))
                        }
                      >
                        <MenuItem color="green" value={4}>3 months</MenuItem>
                        <MenuItem color="green" value={2}>6 months</MenuItem>
                        <MenuItem color="green" value={3}>12 months</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="form-group my-20">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        name="start_date"
                        value={subscriptionDetail?.start_date}
                        onChange={handleSubChange}
                        required
                        type="date"
                        label="Start Date"
                        color="green"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        value={subscriptionDetail?.end_date}
                        onChange={handleSubChange}
                        name="end_date"
                        required
                        type="date"
                        label="End Date"
                        color="green"
                        InputLabelProps={{ shrink: true }}

                      />
                    </FormControl>
                  </div>

                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subscriptionDetail?.is_comission_paid}
                          onChange={(e) =>
                            setsubscriptionDetail((prev) => ({
                              ...prev,
                              is_comission_paid: e.target.checked,
                            }))
                          }
                          name="is_comission_paid"
                        />
                      }
                      label="Is Commission Paid?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subscriptionDetail?.is_deposit_refunded}
                          onChange={(e) =>
                            setsubscriptionDetail((prev) => ({
                              ...prev,
                              is_deposit_refunded: e.target.checked,
                            }))
                          }
                          name="is_deposit_refunded"
                        />
                      }
                      label="Is Deposit Refunded?"
                    />
                  </div> */}

                  <button
                    type="submit"
                    className="button green"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Edit Customer
                  </button>
                </form>
              ) : (
                <></>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
      {/* //Order History */}
      {orderHistory?.length > 0 && (
      <DownloadTableExcel
        ref={excelExportRef}
        filename={`${customerData?.name} Orders`}
        sheet="users"
        currentTableRef={tableRef.current}
      >
        <button id="export-excel-btn" className="button yellow mt-25">
          Export Orders to Excel
        </button>
      </DownloadTableExcel>
      )}
        <Grid container sx={{ marginTop: '10px'}} spacing={2}>
          <Grid item xs={12} sm={10} md={10}>
            <Item className="popular-box">

              <div
                className="table-container"
                // style={{ display: "none" }}
                ref={tableRef}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "Order ID",
                        "Customer Name",
                        "Toy Name",
                        "Sku",
                        "Order Date",
                        "Delivery Date",
                        "Days",
                        "Order Status",
                        "Total",
                      ].map((headCell, index) => (
                        <TableCell key={index}>{headCell}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderHistory?.map((item) => {
                      // Parse the API date string into a Date object
                      const createdAtDate = new Date(item?.created_at);

                      // Extract year, month, and day from the Date object
                      const year = createdAtDate.getFullYear();
                      const month = String(createdAtDate.getMonth() + 1).padStart(
                        2,
                        "0"
                      ); // Add 1 to month because it's zero-based
                      const day = String(createdAtDate.getDate()).padStart(2, "0");

                      // Create the formatted date string "yyyy-mm-dd"
                      const formattedCreatedAt = `${year}-${month}-${day}`;

                      return (
                        <TableRow key={item?.id}>
                          <TableCell>{item?.id}</TableCell>
                          {item?.user.first_name ? (
                            <>
                              <TableCell>
                                {item?.user?.first_name + " " + item?.user?.last_name}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item?.profile?.name}</TableCell>
                            </>
                          )}
                          <TableCell>{item?.item?.name}</TableCell>
                          <TableCell>{item?.item?.sku}</TableCell>
                          <TableCell>{formattedCreatedAt}</TableCell>

                          {item?.desired_delivery_date === null ? (
                            <>
                              <TableCell>none</TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item?.desired_delivery_date}</TableCell>
                            </>
                          )}
                          <TableCell>{item?.days}</TableCell>
                          <TableCell>{item?.status}</TableCell>
                          <TableCell>{item.paid_amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>


      {customerData?.milestone?.token_leisure && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Item className="popular-box my-25">
                <div className="card-title-container">
                  <div className="card-title">Token Liesure</div>
                  <div className="button-flex"></div>
                </div>

                <Table>
                  <TableHead>
                    <TableRow>
                      {["Date", "Tokens", "Comment"].map((headCell, index) => (
                        <TableCell key={index}>{headCell}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerData?.milestone?.token_leisure?.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell>{item?.createdDate}</TableCell>

                        <TableCell>{item?.selectedTokens}</TableCell>
                        <TableCell>{item?.comment}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Item>
            </Grid>
          </Grid>
        </Box>
      )}

      <Drawer
        anchor="right"
        open={openGGTokenModal}
        onClose={() => {
          setopenGGTokenModal(false);
          setValue(0);
        }}
        PaperProps={{
          sx: { style2 },
        }}
      >
        <Box sx={style2} role="presentation">
          <>
            <div className="title-container">
              <h5>Edit Top up Tokens</h5>
            </div>
            <div className="my-25">
              <TextField
                type="number"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              <div className="my-20">
                <button onClick={incrementValue} className="button green mr-10">
                  Add 100
                </button>
                <button onClick={increment500Value} className="button green">
                  Add 500
                </button>
              </div>
              <TextField
                type="text"
                label="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
              <div className="my-20">
                <button onClick={handleAddToken} className="button green mr-10">
                  Add Tokens
                </button>
                <button onClick={handleSubtractToken} className="button yellow">
                  Subtract Tokens
                </button>
              </div>
            </div>
          </>
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={openGGTopUPTokenModal}
        onClose={() => {
          setopenGGTopUpTokenModal(false);
          setValue(0);
        }}
        PaperProps={{
          sx: { style2 },
        }}
      >
        <Box sx={style2} role="presentation">
          <>
            <div className="title-container">
              <h5>Edit Top up Tokens</h5>
            </div>
            <div className="my-25">
              <TextField
                type="number"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              <div className="my-20">
                <button onClick={incrementValue} className="button green mr-10">
                  Add 100
                </button>
                <button onClick={increment500Value} className="button green">
                  Add 500
                </button>
              </div>
              <TextField
                type="text"
                label="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
              <div className="my-20">
                <button onClick={handleAddTopupToken} className="button green mr-10">
                  Add Tokens
                </button>
                <button onClick={handleSubtractTopupToken} className="button yellow">
                  Subtract Tokens
                </button>
              </div>
            </div>
          </>
        </Box>
      </Drawer>
    </div>
  );
};

export default EditCustomer;
