import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Modal,
  Grid,
  Paper,
  styled,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  Drawer,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { NavLink, useLocation } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { CustomerInfoApi, CustomerQuestionsApi } from "../api/CustomerInfoApi";
import { BASE_API_URL } from "../../../server";
import { ToyCategoryAPI } from "../../../globalAPIs/ToyCategoryApi";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  outline: "none",
};

function CustomerMilestone() {
  const theme = useTheme();
  const { state } = useLocation();

  const [open, setOpen] = React.useState(false);
  const [childlist, setChildList] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [answers, setAnswers] = useState({}); // To store user's answers
  const [skillPoints, setSkillPoints] = useState({}); // To track skill points
  const [customerInfo, setCustomerInfo] = useState(null);
  const [productCategories, setProductCategories] = useState(null);

  const style2 = {
    // Default width
    [theme.breakpoints.up("sm")]: {
      width: 300, // Width on small devices and up
    },
    [theme.breakpoints.up("md")]: {
      width: 400, // Width on medium devices and up
    },
    [theme.breakpoints.up("lg")]: {
      width: 500, // Width on large devices and up
    },
    p: 4,
  };

  useEffect(() => {
    const fetchchild = async () => {
      try {
        const data = await CustomerInfoApi();

        setCustomerInfo(data); // Make sure you also set the fetched data
      } catch (error) {
        setCustomerInfo(0);
      }
    };
    fetchchild();
  }, []);

  // Fetch questions from the backend or define them here
  // ...

  // Function to handle answer selection
  const handleAnswer = (questionId, newSkillName, newPoints, id, ans) => {
    // Update answers state
    setAnswers((prevAnswers) => {
      const previousAnswer = prevAnswers[questionId];

      // Update skill points
      setSkillPoints((prevSkillPoints) => {
        let updatedSkillPoints = { ...prevSkillPoints };

        // Deduct points of the previous answer, if any
        if (previousAnswer) {
          const { skillName, points } = previousAnswer;
          updatedSkillPoints[skillName] = Math.max(
            0,
            (updatedSkillPoints[skillName] || 0) - points
          );
        }

        // Add points for the new answer
        updatedSkillPoints[newSkillName] =
          (updatedSkillPoints[newSkillName] || 0) + newPoints;

        return updatedSkillPoints;
      });

      return {
        ...prevAnswers,
        [questionId]: {
          skillName: newSkillName,
          points: newPoints,
          id: id,
          answer: ans,
        },
      };
    });
  };

  useEffect(() => {
    const fetchchild = async () => {
      try {
        const data = await CustomerQuestionsApi();

        // Extracting start and end ages from the ageString
        const [startAge, endAge] = state?.age?.split("-").map(Number);

        // Filter questions based on the age range
        const filteredQuestions = data.filter((question) => {
          // Check if any age in the question's age array falls within the specified range
          return question.age.some((age) => age == startAge || age == endAge);
        });

        setQuestions(filteredQuestions); // Make sure you also set the fetched data
        console.log(data);
      } catch (error) {
        setQuestions(0);
      }
    };
    fetchchild();
  }, []);

  const handleOpen = (item) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      child_name: `${state?.child_name}`,
      age: `${state?.age}`,
      id: `${state?.id}`,
      milestone: skillPoints,
      // ... other form fields
    },

    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        // Retrieve existing milestone array from selectedCustomer or initialize as an empty array if it doesn't exist yet
        const existingMilestone = customerInfo?.milestone?.child || [];

        // Find the index of the child to be updated
        const childIndex = existingMilestone.findIndex(
          (child) => child.id === values.id
        );

        // If child with the provided ID is found, update it; otherwise, do nothing
        if (childIndex !== -1) {
          // Update the child at the found index with the new values
          existingMilestone[childIndex] = values;

          // Make the PUT request with the updated milestone
          const response = await axios.put(
            `${BASE_API_URL}api/profile/`,
            {
              milestone: { child: existingMilestone },
            },
            {
              headers: {
                Accept: "application/json",
              },
              withCredentials: true,
            }
          );

          // Update the customerInfo state with the response data
          setCustomerInfo({ ...response.data });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          showConfirmButton: false,
          showDenyButton: true,
        });
      } finally {
        handleClose();
        window.location.href = "/customer-dashboard/milestone";
      }
    },
  });

  const deleteChild = () => {
    Swal.fire({
      title: "Are you sure you want to delete Vrijesh?",
      text: "All the data of Vrijesh will be lost",
      showConfirmButton: false,
      showDenyButton: true,
      denyButtonText: `Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        Swal.fire("Deleted!", "", "success");
      }
    });
  };

  const openReEvaluate = () => {
    Swal.fire({
      title: `Are you sure you want to Re-evaluate this child?`,
      text: `All the previous data of this child will be lost`,
      showConfirmButton: false,
      showDenyButton: true,
      denyButtonText: `Yes`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        handleOpen();
      }
    });
  };

  // Function to calculate total skill points for "Yes" answers
  function calculateTotalSkillPoints(skillName) {
    let totalSkillPoints = 0;

    questions?.forEach((question) => {
      question?.answers?.forEach((answer) => {
        if (
          (answer.answer === "Yes" || answer.answer === "All") &&
          answer.skill.name === skillName
        ) {
          totalSkillPoints += answer.skill_point;
        }
      });
    });
    return totalSkillPoints;
  }

  useEffect(() => {
    const fetchToyCategory = async () => {
      try {
        const data = await ToyCategoryAPI();
        setProductCategories(data.results);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchToyCategory();
  }, []);

  const getProductId = (name) => {
    const category = productCategories?.find((cat) => cat?.label === name);
    return category ? category.id : null;
  };

  return (
    <>
      <div className="title-container">
        <h5>{state?.child_name}- MilteStone</h5>
      </div>

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid item spacing={2}>
          <Item className="popular-box">
            {state?.milestone ? (
              <>
                <div className="card-title-container">
                  <div className="card-title">Milestone Table</div>
                  <div
                    className="button green add-new-child"
                    onClick={() => {
                      state?.milestone ? openReEvaluate() : handleOpen();
                    }}
                  >
                    Reevaluate
                  </div>
                </div>
                <div className="table-container">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {["Sr no", "Category Name", "Progess", "Action"].map(
                          (headCell, index) => (
                            <TableCell key={index}>{headCell}</TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(state?.milestone).map(
                        ([key, value], index) => {
                          const calcValue = (
                            (value / calculateTotalSkillPoints(`${key}`)) *
                            100
                          ).toFixed(0);

                          console.log(
                            "---------",
                            key,
                            `${value} / ${calculateTotalSkillPoints(`${key}`)}`
                          );
                          console.log(state);
                          return (
                            <TableRow key={1}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{key}</TableCell>
                              <TableCell
                                sx={{ cursor: "pointer", width: "20%" }}
                              >
                                <Tooltip
                                  title={`${calcValue}%`}
                                  arrow
                                  placement="top"
                                >
                                  <div>
                                    <ProgressBar
                                      className="progress-bar"
                                      bgColor={"#F94A4A"}
                                      completed={calcValue}
                                      labelSize="10px"
                                      labelAlignment="center"
                                      customLabel={`${calcValue}%`}
                                    />
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <div
                                  className="flex-container"
                                  style={{ justifyContent: "end" }}
                                >
                                  <NavLink
                                    to={`/toy-store?category=${getProductId(
                                      key
                                    )}`}
                                  >
                                    <div className="button yellow">
                                      View Recommendation
                                    </div>
                                  </NavLink>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div
                  className="button green add-new-child"
                  onClick={() => {
                    state?.milestone ? openReEvaluate() : handleOpen();
                  }}
                >
                  Evaluate
                </div>
              </>
            )}
          </Item>
        </Grid>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { style2 },
        }}
      >
        <Box sx={style2} role="presentation">
          <>
            <div className="title-container">
              <h5>Questions</h5>
            </div>

            <form onSubmit={formik.handleSubmit}>
              {questions?.map((que, index) => (
                <div style={{ margin: "20px 0" }}>
                  <p>
                    {index + 1} - {que?.question}
                  </p>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <RadioGroup>
                      {que?.answers?.map((ans, i) => {
                        // console.log(
                        //   answers[index]?.points,
                        //   "===========",
                        //   ans.skill_point
                        // );
                        return (
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  answers[index]?.points === ans.skill_point &&
                                  answers[index]?.answer === ans.answer
                                }
                                onChange={() =>
                                  handleAnswer(
                                    index,
                                    ans.skill.name,
                                    ans.skill_point,
                                    ans.sub_category.id,
                                    ans.answer
                                  )
                                }
                                name={`question_${index}`} // Unique name for each question
                                value={ans.skill.name} // Value for the radio button
                              />
                            }
                            label={ans?.answer}
                          />
                        );
                      })}
                    </RadioGroup>
                  </div>
                </div>
              ))}

              <button className="button green">Submit Answers</button>
            </form>
          </>
        </Box>
      </Drawer>
    </>
  );
}

export default CustomerMilestone;
