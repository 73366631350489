import axios from 'axios';
import { BASE_API_URL } from '../../../server'


export const SchoolApi = async (params) => {
    const apiEndpoint = `${BASE_API_URL}api-admin/web/school/list/${params}`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const SchoolList = response.data.results
        return SchoolList;

    } catch (error) {
        console.log('***** ERROR in Fetching School *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};

