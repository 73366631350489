import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { CardButton } from "../components/Button"

import Card from '../components/Card';
import Filter from '../components/Filter';

import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import '../style/pages/store/store.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import {  animateScroll as scroll, scrollSpy } from 'react-scroll';

// Components
import Pagination from '../../dashboard/admin/components/Pagination/Pagination';

// API
import { ProductAPI, PopularProductAPI } from '../../globalAPIs/ProductApi'
import { ConstructionOutlined } from '@mui/icons-material';


// StoreBanner Settings
var StoreBannerSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	cssEase: 'linear',
	arrows: false
};
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 300,
	bgcolor: 'background.paper',
	border: 'none',
	p: 2,
	maxHeight: '700px',
	overflow: "scroll"
};

function ToyStore() {

	let location = useLocation();
	let query = new URLSearchParams(location.search);
	// API
	const [Product, setProduct] = useState([]);
	const [PopularProduct, setPopularProduct] = useState([]);

	const [nextUrl, setNextUrl] = useState(null);
	const [prevUrl, setPrevUrl] = useState(null);

	const[day,setDays] =useState("seven")
	const [pages, setPages] = useState([])

	const [loading, setLoading] = useState(true);
	const StoreSlider = React.useRef(null);// Banner Slider Refrences
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		let query = new URLSearchParams(location.search);
		let name = query.get('name') || '';
		let category = query.get('category');
		category = category !== null ? category : ''; // Ensure category is a string
		let sub_category = query.get('sub_category');
		sub_category = sub_category !== null ? sub_category : '';

		let queryParams = [];

		if (name) {
			queryParams.push(`name=${name}`);
		}
		if (category && category.length > 0) {
			queryParams.push(`category=${category}`);
		}
		if (sub_category && sub_category.length > 0) {
			queryParams.push(`sub_category=${sub_category}`);
		}

		const fetchPopular = async () => {
			try {
				const data = await PopularProductAPI();
				setPopularProduct(data.result)
				console.log(data)
			} catch (error) {
				console.log(error)
			}
		}
		(async () => {
			try {
				const data = await ProductAPI(queryParams);
				let next;
				let prev;
				
				setProduct(data.result); // Make sure you also set the fetched data
				console.log(data)
				try {
					next = data.data.next.replace("http:", "https:");
				} catch {
					next = data.data.next;
				}
				try {
					prev = data.data.prev.replace("http:", "https:");
				} catch {
					prev = data.data.prev;
				}
				handleClose()

				// Assuming `data` is your response object with necessary pagination details
				let currentPage = data.data.current_page;
				let pages = data.data.pages;
				let prevPageUrl = data.data.prev; // Assuming this gives the direct link to the previous page
				let nextPageUrl = data.data.next; // Assuming this gives the direct link to the next page
				let paginationLinks = [];

				// Add the previous page link if it's not the first page
				// if (currentPage > 1) {
				// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
				// }

				// Add up to three previous page links
				for (let i = currentPage - 2; i < currentPage; i++) {
					if (i > 0) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the current page link
				paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

				// Add up to three next page links
				for (let i = currentPage + 1; i <= currentPage + 3; i++) {
					if (i <= pages.length) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the next page link if it's not the last page
				// if (currentPage < pages.length) {
				// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
				// }

				console.log(paginationLinks);

				// Now set the state or render the pagination
				setPages(paginationLinks);
				setNextUrl(nextPageUrl);
				setPrevUrl(prevPageUrl);
			} catch (error) {

			}
		})();
		fetchPopular()
	}, [location]);

	const handleNext = async () => {

		if (nextUrl) {
			console.log(nextUrl)
			try {
				const response = await axios.get(nextUrl, { withCredentials: true });
				const data = response.data;
				console.log(data)
				const result = response.data.results.map(item => ({
					id: item.id,
					image: item.image,
					name: item.name,
					small_description: item.small_description,
					slug: item.slug,
					price: item.price,
					one: item.price_7,
					three: item.price_3,
					seven: item.price_7,
					fourteen: item.price_14,
					is_featured: item.is_featured,
					inventory: item.available_stock,
					renvenue_generated: '', // This field is not available in the API response
					number_of_days_booked: '', // This field is not available in the API response
					total_in_wishlist: item.total_wishlist,
					available_stock: item.available_stock > 0 ? true : false,
					order: item.order_by,
					sku: item.sku,
					stock: item.total_stock,

					categories: item.category // You might need to adjust this based on the actual structure of the category field
				}
				))
				let currentPage = data.current_page;
				let pages = data.links.pages;
				let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
				let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
				let paginationLinks = [];

				// Add the previous page link if it's not the first page
				// if (currentPage > 1) {
				// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
				// }

				// Add up to three previous page links
				for (let i = currentPage - 2; i < currentPage; i++) {
					if (i > 0) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the current page link
				paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

				// Add up to three next page links
				for (let i = currentPage + 1; i <= currentPage + 3; i++) {
					if (i <= pages.length) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the next page link if it's not the last page
				// if (currentPage < pages.length) {
				// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
				// }

				setProduct(result)
				// Now set the state or render the pagination
				setPages(paginationLinks);
				setNextUrl(nextPageUrl);
				setPrevUrl(prevPageUrl);
				scroll.scrollToTop();

			} catch (error) {
				console.error("Error fetching the next page:", error);
			}
		}
	};

	const handlePrev = async () => {

		if (prevUrl) {
			try {
				const response = await axios.get(prevUrl, { withCredentials: true });
				const data = response.data;
				console.log(data)
				const result = response.data.results.map(item => ({
					id: item.id,
					image: item.image,
					name: item.name,
					small_description: item.small_description,
					slug: item.slug,
					price: item.price,
					one: item.price_7,
					three: item.price_3,
					seven: item.price_7,
					fourteen: item.price_14,
					is_featured: item.is_featured,
					inventory: item.available_stock,
					renvenue_generated: '', // This field is not available in the API response
					number_of_days_booked: '', // This field is not available in the API response
					total_in_wishlist: item.total_wishlist,
					available_stock: item.available_stock > 0 ? true : false,
					order: item.order_by,
					sku: item.sku,
					stock: item.total_stock,
					total_stock: item.total_stock,

					categories: item.category // You might need to adjust this based on the actual structure of the category field
				}
				))
				let currentPage = data.current_page;
				let pages = data.links.pages;
				let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
				let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
				let paginationLinks = [];

				// // Add the previous page link if it's not the first page
				// if (currentPage > 1) {
				// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
				// }

				// Add up to three previous page links
				for (let i = currentPage - 2; i < currentPage; i++) {
					if (i > 0) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the current page link
				paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

				// Add up to three next page links
				for (let i = currentPage + 1; i <= currentPage + 3; i++) {
					if (i <= pages.length) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the next page link if it's not the last page
				// if (currentPage < pages.length) {
				// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
				// }

				console.log(paginationLinks);

				setProduct(result)
				// Now set the state or render the pagination
				setPages(paginationLinks);
				setNextUrl(nextPageUrl);
				setPrevUrl(prevPageUrl);
				scroll.scrollToTop();


			} catch (error) {
				console.error("Error fetching the previous page:", error);
			}
		}
	};

	const handlePage = async (e) => {
		try {
			const response = await axios.get(e, { withCredentials: true });
			const data = response.data;
			console.log(data)
			const result = response.data.results.map(item => ({
				id: item.id,
				image: item.image,
				name: item.name,
				small_description: item.small_description,
				slug: item.slug,
				price: item.price,
				one: item.price_7,
				three: item.price_3,
				seven: item.price_7,
				fourteen: item.price_14,
				is_featured: item.is_featured,
				inventory: item.available_stock,
				renvenue_generated: '', // This field is not available in the API response
				number_of_days_booked: '', // This field is not available in the API response
				total_in_wishlist: item.total_wishlist,
				available_stock: item.available_stock > 0 ? true : false,
				order: item.order_by,
				sku: item.sku,
				stock: item.total_stock,
				categories: item.category // You might need to adjust this based on the actual structure of the category field
			}
			))
			let currentPage = data.current_page;
			let pages = data.links.pages;
			let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
			let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
			let paginationLinks = [];

			// Add the previous page link if it's not the first page
			// if (currentPage > 1) {
			// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
			// }

			// Add up to three previous page links
			for (let i = currentPage - 2; i < currentPage; i++) {
				if (i > 0) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the current page link
			paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

			// Add up to three next page links
			for (let i = currentPage + 1; i <= currentPage + 3; i++) {
				if (i <= pages.length) {
					paginationLinks.push({ page: i, url: pages[i - 1] });
				}
			}

			// Add the next page link if it's not the last page
			// if (currentPage < pages.length) {
			// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
			// }

			console.log(paginationLinks);

			setProduct(result)
			// Now set the state or render the pagination
			setPages(paginationLinks);
			setNextUrl(nextPageUrl);
			setPrevUrl(prevPageUrl);
			scroll.scrollToTop();


		} catch (error) {
			console.error("Error fetching the previous page:", error);
		}
	}

	const handleFilterSubmit = async (url,days) => {
		try {
				setDays(days)
				console.log(days)
				const response = await axios.get(url, { withCredentials: true });
				const data = response.data;
				console.log(data)
				const result = response.data.results.map(item => ({
					id: item.id,
					image: item.image,
					name: item.name,
					small_description: item.small_description,
					slug: item.slug,
					price: item.price,
					one: item.price_7,
					three: item.price_3,
					seven: item.price_7,
					fourteen: item.price_14,
					is_featured: item.is_featured,
					inventory: item.available_stock,
					renvenue_generated: '', // This field is not available in the API response
					number_of_days_booked: '', // This field is not available in the API response
					total_in_wishlist: item.total_wishlist,
					available_stock: item.available_stock ,
					is_availabel: item.is_availabel	 ,
					order: item.order_by,
					sku: item.sku,
					stock: item.total_stock,
					categories: item.category // You might need to adjust this based on the actual structure of the category field
				}
				))
				let currentPage = data.current_page;
				let pages = data.links.pages;
				let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
				let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
				let paginationLinks = [];

				// // Add the previous page link if it's not the first page
				// if (currentPage > 1) {
				// 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
				// }

				// Add up to three previous page links
				for (let i = currentPage - 2; i < currentPage; i++) {
					if (i > 0) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the current page link
				paginationLinks.push({ page: currentPage, url: pages[currentPage - 1], current: true });

				// Add up to three next page links
				for (let i = currentPage + 1; i <= currentPage + 3; i++) {
					if (i <= pages.length) {
						paginationLinks.push({ page: i, url: pages[i - 1] });
					}
				}

				// Add the next page link if it's not the last page
				// if (currentPage < pages.length) {
				// 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
				// }

				console.log(paginationLinks);

				setProduct(result)
				// Now set the state or render the pagination
				setPages(paginationLinks);
				setNextUrl(nextPageUrl);
				setPrevUrl(prevPageUrl);
				scroll.scrollToTop();
				handleClose()

		} catch (error) {
			console.error("Error fetching filtered products:", error);
		}
	}



	return (
		<>

			<div className="title-banner">
				Toy Catalogue
			</div>

			{/* Store Container */}
			<section className='store-container container'>
				<div className="mobile-filter-btn button green" onClick={handleOpen} style={{ width: '100%', textAlign: 'center', justifyContent: 'center' }}>
					Filter
				</div>
				<div className="store-container">
					<div className="left">
						<Filter onFilterSubmit={handleFilterSubmit} onReset={handleFilterSubmit}></Filter>
					</div>
					<div className="right">
						<div className="toy-grid">
							{Product ? (<>
								{Product.map((e) => {
									return (
										<Card
											key={e.id}
											img={e.image}
											name={e.name}
											price={e[day]}
											slug={e.slug}
											product={e}
											id={e.id}
											short_description={e.small_description}
											stock_status={e.available_stock}
											
										/>
									);
								})}
							</>)
								: (
									<>

									</>)}
						</div>
					</div>
				</div>

			</section>
			<section className='container pagination-container mt-25'>
				<div className={prevUrl?"prev button green":"prev-none"} onClick={handlePrev}>
					Prev
				</div>
				{pages ? (<>
					{pages.map((e, index,url) => {
						return (
							<>
								<div className={e.current?'current-page pages':' pages'} data-attr={e.url}   onClick={() => handlePage(e.url)}>
									{e.page}
								</div>
							</>
						)
					})}
				</>) : (<></>)}
				<div className={nextUrl?"next button green":"prev-none"} onClick={handleNext}>
					Next
				</div>
			</section>
			{/* Store Container */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Filter onFilterSubmit={handleFilterSubmit} onReset={handleFilterSubmit}></Filter>
				</Box>
			</Modal>

		</>

	)
}

export default ToyStore