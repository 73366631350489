import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, styled, FormControl, TextField, Autocomplete, Select, MenuItem, InputLabel } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import Swal from 'sweetalert2';
// API
import { CustomerInfoApi } from '../api/CustomerInfoApi';
import { BASE_API_URL } from '../../../server';


const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf


const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
        console.error('There was a problem fetching the CSRF token:', error);
    }
};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'red' : 'white',
    ...theme.typography.body2,
    padding: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #E7E7E7',
    color: 'black',
    height: '100%',
}));

function CustomerAccount() {
    const [profile, setProfile] = useState();
    const [fetched, setFetch] = useState(false)
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await CustomerInfoApi();
                setProfile(data); // Make sure you also set the fetched data
                setFetch(true)
            } catch (error) {
            }
        }
        fetchProfile()
    }, [])




    const formik = useFormik({
        initialValues: {
            name: fetched ? profile.name : "",
            email: fetched ? profile.user_details.email : "",
            telephone: fetched ? profile.mobile_number : "",
            username: fetched ? profile.user_details.username : "",
            points: fetched ? profile.gg_token : "",
            address1: fetched ? profile.address_1 : "",
            address2: fetched ? profile.address_2 : "",
            city: fetched ? profile.city : "",
            postal_code: fetched ? profile.postal_code : "",
            school_name: fetched ? profile.school : "",
            // ... other form fields
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().required('Required'),
            telephone: Yup.string().required('Required'),
            points: Yup.string().required('Required'),
            address1: Yup.string().required('Required'),
            address2: Yup.string().required('Required'),
            // ... other validations
        }),
        onSubmit: async (values) => {
            // await fetchCsrfToken()
            try {
                const response = await axios.post(
                    `${BASE_API_URL}api/profile/`,
                    {
                        "profile_image": null,
                        "name": values.name,
                        "address_1": values.address1,
                        "address_2": values.address2,
                        "postal_code": values.postal_code,
                        "city": values.city,
                        "region_state": "",
                        "country": "India",
                        "user_type": "customer",
                        "mobile_number": values.telephone,
                        "email_confirmed": false
                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                        },
                        withCredentials: true,
                    }
                );
    
                if (response.status >= 200 && response.status < 300) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Your action was successful.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error("There was an error adding the product:", error);
            }
        },
    });
    if (!profile) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <div className="title-container">
                <h5>Account Details</h5>
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>

                        <Item className="popular-box">
                            {fetched ? (<>
                                <form onSubmit={formik.handleSubmit}>
                                    {/* ... other form fields ... */}

                                    {/* Personal Details */}
                                    <div className='name'>Personal Details</div>
                                    <div className='form-group-container'>
                                        <div className='form-group'>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='form-group'>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                    disabled
                                                />
                                            </FormControl>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="telephone"
                                                    value={formik.values.telephone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Telephone"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"

                                                />
                                            </FormControl>
                                        </div>
                                        <div className="form-group">
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="username"
                                                    value={formik.values.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Username"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                    disabled
                                                />
                                            </FormControl>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="points"
                                                    value={formik.values.points}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Points"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                        {/* ... other form fields ... */}
                                    </div>

                                    <div className='name'>Address</div>
                                    <div className="form-group-container">
                                        <div className="form-group">
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="address1"
                                                    value={formik.values.address1}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Flat, House no, Building, Company, Apartment"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                    multiline
                                                />
                                            </FormControl>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="address2"
                                                    value={formik.values.address2}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Area, Street, Sector, Village"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                    multiline
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="form-group">
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="postal_code"
                                                    value={formik.values.postal_code}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="Post Code"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                />
                                            </FormControl>
                                            <FormControl fullWidth color="green">
                                                <TextField
                                                    name="city"
                                                    value={formik.values.city}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    label="City"
                                                    variant="outlined"
                                                    fullWidth
                                                    color="green"
                                                />
                                            </FormControl>
                                        </div>

                                    </div>

                                    <div className="name">School Details</div>
                                    <div className="form-group-container">
                                        <div className="form-group">
                                            <Tooltip title="You can change this when you buy a subscription">
                                                <FormControl fullWidth color="green">
                                                    <TextField
                                                        name="school_name"
                                                        value={formik.values.school}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        label="School Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        color="green"
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Tooltip>
                                        </div>

                                        <div className="form-group">
                                            <button className='button green' type="submit">Submit</button>
                                        </div>
                                    </div>

                                </form>
                            </>) : (<></>)}

                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>

    )
}

export default CustomerAccount