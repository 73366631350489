import { BASE_API_URL } from '../../server';
import axios from 'axios';
import Login from './LoginApi';


// Fetch CSRF Token API
const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf


const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
        const response = await axios.get(
            csrfTokenApiUrl,
            { withCredentials: true }
        );
        csrf = response.data.csrftoken
        return () => {
            abortController.abort()
        }
    } catch (error) {
        console.error('There was a problem fetching the CSRF token:', error);
    }
};

export const RegisterApi = async (values) => {
    const apiEndpoint = `${BASE_API_URL}account/register/`;
    // await fetchCsrfToken()
    try {
        console.log(values)
        const response = await axios.post(apiEndpoint, 
            {
                username: values.email,
                password: values.password,
                email: values.email,
                profile: {
                    profile_image: null,
                    name: values.name,
                    address_1: null,
                    address_2: null,
                    postal_code: null,
                    city: null,
                    region_state: null,
                    country: null,
                    user_type: "customer",
                    mobile_number: null,
                }
            },
                {
                    headers: {
                        'Accept': 'application/json',
                        
                        
                    },
                    withCredentials: true,
        });
        const CartList = response.data
        
        return CartList;

    } catch (error) {
        console.log('***** ERROR in register  *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};