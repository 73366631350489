import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Components
import { DoughnutOrderChart } from "../components/DashboardComponents/DashboardComponents";
import {
  OrderTable,
  DispatchSummary,
  PickUpSummary,
} from "../components/Table/TableComponent";
import Pagination from "../components/Pagination/Pagination";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";

// API
import { OrderApi, DispatchApi } from "../api/OrderApi";
import { ConstructionOutlined } from "@mui/icons-material";
import { debounce } from "lodash";
import RetainCard from "../../customer/components/RetainCard";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CTMDrawer, CTMnewORder } from "../components/CTMDrawer";
import { AllCustomerApi } from "../api/CustomerApi";
import { AllProductApi } from "../api/CTMApi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Swal from "sweetalert2";
import { utils, writeFile } from "xlsx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  outline: "none",
};

function OrderTableContainer() {
  const theme = useTheme();
  const style2 = {
    // Default width
    [theme.breakpoints.up("sm")]: {
      width: 300, // Width on small devices and up
    },
    [theme.breakpoints.up("md")]: {
      width: 400, // Width on medium devices and up
    },
    [theme.breakpoints.up("lg")]: {
      width: 500, // Width on large devices and up
    },
    p: 4,
  };

  const tableRef = useRef();
  const dateTableRef = useRef();
  const excelExportRef = useRef(null);

  const exportToExcel = () => {
    const table = dateTableRef.current;
    const worksheet = utils.table_to_sheet(table);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Orders');

    const filename = `Orders table ${formattedFromDate} - ${formattedToDate}.xlsx`;

    writeFile(workbook, filename);
  };

  const [Order, setOrder] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [pages, setPages] = useState([]);

  const [date, setDate] = useState(dayjs());
  const [searchParam, setSearchParam] = useState("");

  const [product, setProduct] = useState();
  const [open, setOpen] = React.useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [Customer, setCustomer] = useState([]);
  const [productData, setProductData] = useState([]);

  //Popover States
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  //multiple selection State
  const [selectedOrders, setSelectedOrders] = useState([]);

  //all order data state for Excel Sheet
  const [allOrders, setAllOrders] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(""); // To keep track of the next page URLu
  const [totalItems, settotalItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");

  const [date_orders, setdate_orders] = useState([]);

  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const formattedFromDate = fromDate ? fromDate.format("YYYY-MM-DD") : "";
  const formattedToDate = toDate ? toDate.add(1, 'day').format("YYYY-MM-DD") : "";

  const handleStatusChange = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? "" : status));
    handlePopoverClose();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleNext = async () => {
    try {
      const response = await axios.get(nextUrl, { withCredentials: true });
      const data = response.data;
      console.log("", data);
      setOrder(data.results);
      settotalItems(data?.total_items);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }
      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch {
      console.log("here is an error");
    }
  };
  const handlePrev = async () => {
    try {
      const response = await axios.get(prevUrl, { withCredentials: true });
      const data = response.data;
      let prev;
      let next;
      setOrder(data.results);
      settotalItems(data?.total_items);

      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch { }
  };
  const handlePage = async (e) => {
    try {
      const response = await axios.get(e, { withCredentials: true });
      const data = response.data;
      setOrder(data.results);
      settotalItems(data?.total_items);
      let currentPage = data.current_page;
      let pages = data.links.pages;
      let prevPageUrl = data.links.previous; // Assuming this gives the direct link to the previous page
      let nextPageUrl = data.links.next; // Assuming this gives the direct link to the next page
      let paginationLinks = [];

      // Add the previous page link if it's not the first page
      // if (currentPage > 1) {
      // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
      // }

      // Add up to three previous page links
      for (let i = currentPage - 2; i < currentPage; i++) {
        if (i > 0) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the current page link
      paginationLinks.push({
        page: currentPage,
        url: pages[currentPage - 1],
        current: true,
      });

      // Add up to three next page links
      for (let i = currentPage + 1; i <= currentPage + 3; i++) {
        if (i <= pages.length) {
          paginationLinks.push({ page: i, url: pages[i - 1] });
        }
      }

      // Add the next page link if it's not the last page
      // if (currentPage < pages.length) {
      // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
      // }

      console.log(paginationLinks);

      // Now set the state or render the pagination
      setPages(paginationLinks);
      setNextUrl(nextPageUrl);
      setPrevUrl(prevPageUrl);
    } catch (error) {
      console.error("Error fetching the previous page:", error);
    }
  };

  const handleOpen = (item) => {
    console.log(item);
    setProduct(item);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Function to open the drawer with product details
  const openDrawerWithProduct = (product) => {
    setSelectedProduct(product);
    setIsDrawerOpen(true);
    console.log(product);
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    setProgressMessage(
      "Please do not change tabs while we are fetching orders..."
    );

    // Open a SweetAlert dialog with a custom HTML content
    const swal = Swal.mixin({
      customClass: {
        container: "my-swal-container",
        popup: "my-swal-popup",
        title: "my-swal-title",
        content: "my-swal-content",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    swal.fire({
      title: "Fetching Orders",
      html: '<p id="swal-content"></p>',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        document.getElementById("swal-content").innerText = progressMessage;
      },
    });
    let queryParams = [];
    // Check if there is a search parameter and append it to queryParams
    if (searchParam) {
      queryParams.push("search=" + encodeURIComponent(searchParam));
    }

    // Check if a status is selected and append it to queryParams
    if (selectedStatus) {
      queryParams.push("status=" + selectedStatus);
    }

    // Join the queryParams array into a single string for the API call
    const queryString = queryParams.join("&");

    let currentPageUrl = "depth=2&page_size=100&" + queryString; // Initial API endpoint
    let hasMorePages = true;

    let totalOrders = []; // Define totalOrders array outside the loop

    console.log("totalItems", allOrders, totalItems);
    while (hasMorePages && allOrders?.length < totalItems) {
      // Update progress message

      try {
        const res = await OrderApi(currentPageUrl);
        const newOrders = res?.data?.results || [];
        setAllOrders((prevOrders) => [...prevOrders, ...newOrders]);
        totalOrders.push(...newOrders); // Push all new orders into totalOrders array

        document.getElementById(
          "swal-content"
        ).innerText = `Please do not Close tab or refresh the page while we are generating orders.\n\nProcessed ${totalOrders.length} of ${totalItems} items.`;

        // Check if there is a next page and update the nextPageUrl state
        if (res?.data?.links?.next) {
          setNextPageUrl(res.data?.links.next);
          currentPageUrl = res.data?.links.next;
        } else {
          hasMorePages = false;
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        hasMorePages = false; // Stop on error
        document.getElementById("swal-content").innerText =
          "An error occurred while fetching orders.";
      }
    }

    setIsLoading(false);
    swal.close(); // Close the SweetAlert dialog
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Orders fetched successfully. Ready to export.",
      confirmButtonText: "OK",
    }).then(() => {
      // Automatically trigger the export after the SweetAlert is closed
      const exportButton = document.getElementById("export-excel-btn");
      if (exportButton) {
        exportButton.click();
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        // Initialize an array to hold query parameters
        let queryParams = [];

        // Check if there is a search parameter and append it to queryParams
        if (searchParam) {
          queryParams.push("search=" + encodeURIComponent(searchParam));
        }

        // Check if a status is selected and append it to queryParams
        if (selectedStatus) {
          queryParams.push("status=" + selectedStatus);
        }

        if (formattedFromDate) {
          queryParams.push("created_at_from=" + formattedFromDate);
        }

        if (formattedToDate) {
          queryParams.push("created_at_to=" + formattedToDate);
        }

        // Join the queryParams array into a single string for the API call
        const queryString = queryParams.join("&");
        console.log(queryString)
        // Make an A  PI call with the query string if it exists, otherwise make a default call
        let data = queryString ? await OrderApi(queryString) : await OrderApi();

        console.log("dsadfac", data);

        let next;
        let prev;
        setOrder(data.data.results); // Make sure you also set the fetched data
        settotalItems(data?.data?.total_items);
        try {
          next = data.data.links.next.replace("http:", "https:");
        } catch {
          next = data.data.links.next;
        }
        try {
          prev = data.data.links.previous.replace("http:", "https:");
        } catch {
          prev = data.data.links.previous;
        }
        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) { }
    })();
  }, [date, selectedStatus, formattedToDate]);

  useEffect(() => {
    const fetchAllDispatch = async () => {
      setIsLoading(true);
      const url =
        "created_at_from=" +
        formattedFromDate +
        "&created_at_to=" +
        formattedToDate +
        "&status=" +
        selectedStatus +
        "&page_size=10000";
      try {
        const res = await OrderApi(url);
        setdate_orders(res?.data?.results);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllDispatch();
  }, [formattedToDate, selectedStatus]);

  useEffect(() => {
    (async () => {
      try {
        const data = await AllCustomerApi();

        setCustomer(data.results);
      } catch (error) {
        console.log(error);
      }
    })([]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await AllProductApi();

        setProductData(data.result);
      } catch (error) {
        console.log(error);
      }
    })([]);
  }, []);

  const handleSearch = async () => {
    (async () => {
      try {
        // Initialize an array to hold query parameters
        let queryParams = [];

        // Check if there is a search parameter and append it to queryParams
        if (searchParam) {
          queryParams.push("search=" + encodeURIComponent(searchParam));
        }

        // Check if a status is selected and append it to queryParams
        if (selectedStatus) {
          queryParams.push("status=" + selectedStatus);
        }

        // Join the queryParams array into a single string for the API call
        const queryString = queryParams.join("&");

        // Make an API call with the query string if it exists, otherwise make a default call
        let data = queryString ? await OrderApi(queryString) : await OrderApi();

        console.log("dsadfac", data);

        let next;
        let prev;
        setOrder(data.data.results); // Make sure you also set the fetched data
        settotalItems(data?.data?.total_items);
        try {
          next = data.data.links.next.replace("http:", "https:");
        } catch {
          next = data.data.links.next;
        }
        try {
          prev = data.data.links.previous.replace("http:", "https:");
        } catch {
          prev = data.data.links.previous;
        }
        // Assuming `data` is your response object with necessary pagination details
        let currentPage = data.data.current_page;
        let pages = data.data.links.pages;
        let prevPageUrl = data.data.links.previous; // Assuming this gives the direct link to the previous page
        let nextPageUrl = data.data.links.next; // Assuming this gives the direct link to the next page
        let paginationLinks = [];

        // Add the previous page link if it's not the first page
        // if (currentPage > 1) {
        // 	paginationLinks.push({ page: currentPage - 1, url: pages[currentPage - 2] });
        // }

        // Add up to three previous page links
        for (let i = currentPage - 2; i < currentPage; i++) {
          if (i > 0) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the current page link
        paginationLinks.push({
          page: currentPage,
          url: pages[currentPage - 1],
          current: true,
        });

        // Add up to three next page links
        for (let i = currentPage + 1; i <= currentPage + 3; i++) {
          if (i <= pages.length) {
            paginationLinks.push({ page: i, url: pages[i - 1] });
          }
        }

        // Add the next page link if it's not the last page
        // if (currentPage < pages.length) {
        // 	paginationLinks.push({ page: currentPage + 1, url: nextPageUrl });
        // }

        console.log(paginationLinks);

        // Now set the state or render the pagination
        setPages(paginationLinks);
        setNextUrl(nextPageUrl);
        setPrevUrl(prevPageUrl);
      } catch (error) { }
    })();
  };

  useEffect(() => {
    if (searchParam === "") {
      handleSearch();
    }
  }, [searchParam]);

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
    // Reset the toDate if it's before the new fromDate
    if (toDate && dayjs(newValue).isAfter(dayjs(toDate))) {
      setToDate(null);
    }
  };

  const handleToDateChange = (newValue) => {
    setToDate(newValue);
  };

  console.log("all ordersss", date_orders, dateTableRef);

  return (
    <>
      <div className="title-container flex-container">
        <h5>Order</h5>
        <div className="search" style={{ display: "flex", gap: "10px" }}>
          {/* // if all orders are fetched show Excel button */}

          {isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <>
              {allOrders?.length == totalItems ? (
                <DownloadTableExcel
                  ref={excelExportRef}
                  filename="Order Table"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button id="export-excel-btn" className="button yellow">
                    Export Excel
                  </button>
                </DownloadTableExcel>
              ) : (
                <>
                  {Order && (
                    <button
                      className="button yellow"
                      onClick={() => fetchOrders()}
                    >
                      Download Full Report
                    </button>
                  )}
                </>
              )}
            </>
          )}
          <TextField
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            id="outlined-basic"
            color="green"
            label="Search"
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default action to avoid form submission if it's part of a form
                handleSearch();
              }
            }}
          />
          <button className="green button" onClick={handleSearch}>
            Search
          </button>
          <button className="green button" onClick={handlePopoverOpen}>
            Filter
          </button>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus === "Confirmed"}
                    onChange={() => handleStatusChange("Confirmed")}
                    name="confirmed"
                  />
                }
                label="Confirmed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus === "Delivered"}
                    onChange={() => handleStatusChange("Delivered")}
                    name="delivered"
                  />
                }
                label="Delivered"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus === "Pickup"}
                    onChange={() => handleStatusChange("Pickup")}
                    name="canceled"
                  />
                }
                label="Pickup"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus === "Closed"}
                    onChange={() => handleStatusChange("Closed")}
                    name="canceled"
                  />
                }
                label="Closed"
              />
            </div>
          </Popover>
        </div>
      </div>
      <div className="title-container flex-container mt-25">
        <button
          className="button green"
          onClick={() => openDrawerWithProduct()}
        >
          Add new Order
        </button>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={fromDate}
              color="green"
              onChange={handleFromDateChange}
              renderInput={(params) => (
                <TextField
                  label="From Date"
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: { color: "green" }, // Change the input text color to green
                  }}
                  color="green"
                />
              )}
              // Use format to change the displayed date format
              format="YYYY-MM-DD"
            />

            <DatePicker
              label="To Date"
              value={toDate}
              color="green"
              onChange={handleToDateChange}
              renderInput={(params) => <TextField {...params} />}
              format="YYYY-MM-DD"
              // Disable dates before the fromDate
              shouldDisableDate={(date) =>
                dayjs(date).isBefore(dayjs(fromDate), "day")
              }
              
            />
          </LocalizationProvider>

          {isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            // <DownloadTableExcel
            //   filename={`Orders table ${formattedFromDate} - ${formattedToDate}`}
            //   sheet="users"
            //   currentTableRef={dateTableRef.current}
            // >
            //   <button className="button yellow">Export Excel</button>
            // </DownloadTableExcel>

            <button className="button yellow" onClick={exportToExcel}>
              Export Excel
            </button>
          )}
        </div>
      </div>
      {/* Order Table */}
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2}>
          {Order ? (
            <>
              <OrderTable
                extrasmall={12}
                small={12}
                medium={12}
                color={"green "}
                text={"Order Table"}
                cardtitle={"Order Table"}
                data={Order}
                handleOpen={handleOpen}
                openDrawerWithProduct={openDrawerWithProduct}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              ></OrderTable>
            </>
          ) : (
            <></>
          )}
          <section className="container pagination-container mt-25">
            <div
              className={prevUrl ? "prev button green" : "prev-none"}
              onClick={handlePrev}
            >
              Prev
            </div>
            {pages ? (
              <>
                {pages.map((e, index, url) => {
                  return (
                    <>
                      <div
                        className={e.current ? "current-page pages" : " pages"}
                        data-attr={e.url}
                        onClick={() => handlePage(e.url)}
                      >
                        {e.page}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
            <div
              className={nextUrl ? "next button green" : "prev-none"}
              onClick={handleNext}
            >
              Next
            </div>
          </section>
        </Grid>
      </Box>
      {/* Order Table */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          {product ? (
            <>
              <>
                <RetainCard
                  img={product?.item?.image}
                  name={product?.item?.name}
                  price={product?.item?.price}
                  price_1={product?.item?.price_1}
                  price_3={product?.item?.price_3}
                  price_7={product?.item?.price_7}
                  price_14={product?.item?.price_14}
                  key={product?.item?.id}
                  product_id={product?.item?.id}
                  days={1}
                  date={product?.end_date}
                  id={product?.id}
                  onClose={handleClose}
                  user={product}
                />
              </>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        PaperProps={{
          sx: { style2 },
        }}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={style2} role="presentation">
          {selectedProduct ? (
            <>
              <CTMDrawer
                propsDetail={selectedProduct}
                Order={Order}
                setOrder={setOrder}
                setIsDrawerOpen={setIsDrawerOpen}
                setSelectedOrders={setSelectedOrders}
              />
            </>
          ) : (
            <>
              <CTMnewORder
                customer={Customer}
                product={productData}
              ></CTMnewORder>
            </>
          )}
        </Box>
      </Drawer>

      {/* Order Table for Excel */}
      <div
        className="table-container"
        style={{ display: "none" }}
        ref={tableRef}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Order ID",
                "Customer Name",
                "Toy Name",
                "Sku",
                "Order Date",
                "Delivery Date",
                "Days",
                "Order Status",
                "Total",
              ].map((headCell, index) => (
                <TableCell key={index}>{headCell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allOrders?.map((item) => {
              // Parse the API date string into a Date object
              const createdAtDate = new Date(item?.created_at);

              // Extract year, month, and day from the Date object
              const year = createdAtDate.getFullYear();
              const month = String(createdAtDate.getMonth() + 1).padStart(
                2,
                "0"
              ); // Add 1 to month because it's zero-based
              const day = String(createdAtDate.getDate()).padStart(2, "0");

              // Create the formatted date string "yyyy-mm-dd"
              const formattedCreatedAt = `${year}-${month}-${day}`;

              return (
                <TableRow key={item?.id}>
                  <TableCell>{item?.id}</TableCell>
                  {item?.user.first_name ? (
                    <>
                      <TableCell>
                        {item?.user?.first_name + " " + item?.user?.last_name}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{item?.profile?.name}</TableCell>
                    </>
                  )}
                  <TableCell>{item?.item?.name}</TableCell>
                  <TableCell>{item?.item?.sku}</TableCell>
                  <TableCell>{formattedCreatedAt}</TableCell>

                  {item?.desired_delivery_date === null ? (
                    <>
                      <TableCell>none</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{item?.desired_delivery_date}</TableCell>
                    </>
                  )}
                  <TableCell>{item?.days}</TableCell>
                  <TableCell>{item?.status}</TableCell>
                  <TableCell>{item.paid_amount}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {/* order table for date */}
      <div
        className="table-container"
        style={{ display: "none" }}
        ref={dateTableRef}
      >
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Order ID",
                "Customer Name",
                "Toy Name",
                "Sku",
                "Order Date",
                "Delivery Date",
                "Days",
                "Order Status",
                "Total",
              ].map((headCell, index) => (
                <TableCell key={index}>{headCell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {date_orders?.map((item) => {
              // Parse the API date string into a Date object
              const createdAtDate = new Date(item?.created_at);

              // Extract year, month, and day from the Date object
              const year = createdAtDate.getFullYear();
              const month = String(createdAtDate.getMonth() + 1).padStart(
                2,
                "0"
              ); // Add 1 to month because it's zero-based
              const day = String(createdAtDate.getDate()).padStart(2, "0");

              // Create the formatted date string "yyyy-mm-dd"
              const formattedCreatedAt = `${year}-${month}-${day}`;

              return (
                <TableRow key={item?.id}>
                  <TableCell>{item?.id}</TableCell>
                  {item?.user.first_name ? (
                    <>
                      <TableCell>
                        {item?.user?.first_name + " " + item?.user?.last_name}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{item?.profile?.name}</TableCell>
                    </>
                  )}
                  <TableCell>{item?.item?.name}</TableCell>
                  <TableCell>{item?.item?.sku}</TableCell>
                  <TableCell>{formattedCreatedAt}</TableCell>

                  {item?.desired_delivery_date === null ? (
                    <>
                      <TableCell>none</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{item?.desired_delivery_date}</TableCell>
                    </>
                  )}
                  <TableCell>{item?.days}</TableCell>
                  <TableCell>{item?.status}</TableCell>
                  <TableCell>{item.paid_amount}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

function Order() {
  return (
    <>
      <OrderTableContainer></OrderTableContainer>
      {/* <DispatchTableContainer /> */}
    </>
  );
}

export default Order;
