import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Components
import { ProductCategoryTable } from '../../../components/Table/TableComponent'

// API
import { ProductCategoryApi } from '../../../api/ProductApi';




function ProductCategory() {
	const [ProductCategoryData, setProductCategory] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const data = await ProductCategoryApi();
				console.log(data)
				setProductCategory(data); // Make sure you also set the fetched data
			} catch (error) {

			}
		})();
	}, []);

	return (
		<>
			<div className="title-container">
				<h5>Product Categories</h5>
			</div>

			{ProductCategoryData ? (
				<>
					{/* Product Categories */}
					<Box sx={{ flexGrow: 1, mt: 2 }}>

						<Grid container spacing={2}>
							<ProductCategoryTable
								extrasmall={12} small={12} medium={12} color={'green '}
								cardtitle={'Product Category'}
								data={ProductCategoryData}
							>
							</ProductCategoryTable>
						</Grid>
					</Box>
					{/* Product Categories */}
				</>
			)
				: (<></>)}

		</>

	)
}

export default ProductCategory