import axios from 'axios';
import { BASE_API_URL } from '../../server'


export const BlogListApi = async () => {
    const apiEndpoint = `${BASE_API_URL}api/blog/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const BlogList = response.data
        return BlogList;

    } catch (error) {
        console.log('***** ERROR in Fetching Blog *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};