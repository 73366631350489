import React, { useEffect, useState } from 'react'


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// Form
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import axios from "axios";
import Swal from 'sweetalert2';

import { BASE_API_URL } from '../../../../../server';

// BOX
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'red' : 'white',
    ...theme.typography.body2,
    padding: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    border: '1px solid #E7E7E7',
    color: 'black',
    height: '100%',
}));


function AddProductCategory() {
    const [imageUrl, setImageUrl] = useState("");
    const [name, setName] = useState("");

    const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
    const [csrfToken, setCsrfToken] = useState();
    // CSRF TOKEN
    const fetchCsrfToken = async () => {
        const abortController = new AbortController();
        try {
            const response = await axios.get(
                csrfTokenApiUrl,
                { withCredentials: true }
            );
            await setCsrfToken(response.data.csrftoken);
            return () => {
                abortController.abort()
            }
        } catch (error) {
            console.error('There was a problem fetching the CSRF token:', error);
        }
    };

    useEffect(() => {
        // fetchCsrfToken();
    }, []); 

    const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setImageUrl(reader.result);
		};

		reader.readAsDataURL(file);
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        // Add the image if it exists
        if (imageUrl) {
            const blob = await fetch(imageUrl).then(r => r.blob());
            formData.append('image', blob, 'filename.jpg'); // Make sure 'image' is the correct field name expected by the server
        }
        // Add other product details
        formData.append('label', name);

        try {
            const response = await axios.post(
                `${BASE_API_URL}api-admin/web/toycategory/add/`,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        
                       
                    },
                    withCredentials: true,
                }
            );

            if (response.status >= 200 && response.status < 300) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your action was successful.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(function(){
                    window.location = "/admin/product-category";
                });
                
            }
        } catch (error) {
            console.error("There was an error adding the product:", error);
        }
    };

    return (
        <>
            <div className="title-container">
                <h5>Add Product</h5>
            </div>
            {/* Order Add Form */}
            <Box sx={{ flecGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Item className='popular-box'>
                            <form onSubmit={handleSubmit}>
                                <div className='name'>
                                    Product  Category
                                </div>
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label htmlFor="upload-image" className='img-upload'>
                                            <div className="upload-btn">
                                                upload your image
                                                <div className="img-container">
                                                    {imageUrl && <img src={imageUrl} alt="Uploaded Image" height="300" />}
                                                </div>
                                            </div>
                                            <input
                                                id="upload-image"
                                                hidden
                                                accept="image/*"
                                                type="file"
                                                onChange={handleFileUpload}
                                            />

                                        </label>

                                    </div>
                                    <div className="form-group">
                                        <FormControl fullWidth color="green">
                                            <TextField 
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            key="Category_name" type='text'  color="green" label="Category Name" variant="outlined" />
                                        </FormControl>
                                    </div>

                                </div>
                                <div className="form-group-container">
                                    <div className='form-group mt-20'>
                                        <input type="submit" value="Add Category" className='button green' />
                                    </div>
                                </div>
                            </form>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default AddProductCategory