import React from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


// Components
import { CounterBox } from '../../admin/components/DashboardComponents/DashboardComponents';
import { SchoolStudentTable } from '../../admin/components/Table/TableComponent';
import '../../admin/style/components/DashboardComponents/DashboardComponents.css'

// API
// import wishlist from '../../customer/api/CustomerWishlist';

function SchoolDashboard() {
	return (
		<>
			<div className="title-container">
				<h5>Dashboard</h5>
			</div>
			<Box sx={{ flexGrow: 1 , mt: 2}}>
				<Grid container spacing={2}>
					<CounterBox extrasmall={12} small={6}  medium={4} title={'Total Earnings'} count={'6500'}></CounterBox>
					<CounterBox extrasmall={12} small={6}  medium={4} title={'Total Student Enrolled'} count={'200'} ></CounterBox>
					<CounterBox extrasmall={12} small={6}  medium={4} title={'Pending Payment'} count={'1000'} ></CounterBox>
				</Grid>
			</Box>
			<Box sx={{flexGrow:1, mt: 2}}>
				<Grid container spacing={2}>
					<SchoolStudentTable
						extrasmall={12} small={12} medium={12} color={'green '} text={'Order Table'}
						cardtitle={'Student Table'}
						// data={wishlist}
					>

					</SchoolStudentTable>
				</Grid>
			</Box>
		</>
	)
}

export default SchoolDashboard