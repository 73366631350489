import React from 'react'
import { useNavigate,Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <>

        <div className=" login-container">
                <div className="left">
                    <div className="logo-container">
                        <img src="/images/logo-2.png" alt="" />
                    </div>
                    <div className="img-container">
                        <img src="/images/login.png" alt="" />
                    </div>
                </div>
                <div className="right">

                    <div className='form'>
                        <div className="logo-container">
                            <img src="/images/logo-2.png" alt="" />
                        </div>
                        <div className="title-container">
                            <h3 style={{ lineHeight: '20px', marginBottom: '5px' }}>Welcome Back</h3>
                            <span style={{ color: '#616161' }}>Dont have a account? <Link to="/register" style={{ color: '#4B9E99' }} >Sigup</Link></span>
                        </div>

                    </div>
                </div>
            </div>
    </>
  )
}

export default ForgotPassword