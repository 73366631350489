import React, { useState, useEffect } from "react";

import axios from "axios";

import { BASE_API_URL } from "../../server";

import { useNavigate, Link } from "react-router-dom";
import { useAppContext } from "../context/UserContext";

// Form
import { TextField, FormControl } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// CSS
import "../style/pages/login/login.css";

// Base URL

function Login() {
  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const [error, setError] = useState("");
  const [csrfToken, setCsrfToken] = useState();

  const { UpdateLogin, updateSuperUser, updatecustomer, updateCsrf } =
    useAppContext();

  const navigate = useNavigate();

  const loginApiUrl = `${BASE_API_URL}account/login/`;
  const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;

  // Fetch CSRF Token API
  const fetchCsrfToken = async () => {
    const abortController = new AbortController();
    try {
      const response = await axios.get(csrfTokenApiUrl, {
        withCredentials: true,
      });
      await setCsrfToken(response.data.csrftoken);
      return () => {
        abortController.abort();
      };
    } catch (error) {
      console.error("There was a problem fetching the CSRF token:", error);
    }
  };

  // this is called after Clicked on submit
  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        loginApiUrl,
        {
          username: values.username,
          password: values.password,
        },
        {
          headers: {
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );
      const isSuperUser = response.data.is_superuser;
      // updateCsrf(csrfToken)
      if (isSuperUser) {
        updateSuperUser(true);
        UpdateLogin(true);
        updatecustomer(false);
        navigate("/admin");
      }
      if (!isSuperUser && response.data.profile.user_type === "customer") {
        updatecustomer(true);
        UpdateLogin(true);
        navigate("/");
      }
      if (!isSuperUser && response.data.profile.user_type === "school") {
        updatecustomer(true);
        UpdateLogin(true);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      handleNonFieldErrors(error);
    }
  };

  // Handel Console errors
  const handleNonFieldErrors = (error) => {
    if (error.response && error.response.data && error.response.data.error) {
      setError(error.response.data.error);
    }
  };

  // Calling the fetch CSRF
  useEffect(() => {
    // fetchCsrfToken();
  }, []);

  return (
    <>
      <div className=" login-container">
        <div className="left">
          <div className="logo-container">
            <img src="/images/logo-2.png" alt="" />
          </div>
          <div className="img-container">
            <img src="/images/login.png" alt="" />
          </div>
        </div>
        <div className="right">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form">
                  <div className="form-group-container">
                    <div className="logo-container">
                      <img src="/images/logo-2.png" alt="" />
                    </div>
                    <div className="title-container flex-direction">
                      <h3 style={{ lineHeight: "20px", marginBottom: "5px" }}>
                        Welcome Back
                      </h3>
                      <span style={{ color: "#616161" }}>
                        Dont have a account?{" "}
                        <Link to="/register" style={{ color: "#4B9E99" }}>
                          Signup
                        </Link>
                      </span>
                    </div>

                    {error ? <div className="error">{error}</div> : <></>}

                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <Field
                          type="text"
                          name="username"
                          className="username"
                          label="Username"
                          variant="outlined"
                          as={TextField}
                          color="green"
                        />
                        {touched.username && errors.username && (
                          <div className="error-text">{errors.username}</div>
                        )}
                      </FormControl>
                    </div>

                    <div className="form-group">
                      <FormControl fullWidth color="green">
                        <Field
                          type="password"
                          name="password"
                          label="Password"
                          variant="outlined"
                          as={TextField}
                          color="green"
                        />
                        {touched.password && errors.password && (
                          <div className="error-text">{errors.password}</div>
                        )}
                      </FormControl>
                    </div>

                    {/* Your other form elements */}

                    <div className="form-group">
                      <button
                        type="submit"
                        className="button green"
                        style={{ width: "100%" }}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="title-container">
                      <span style={{ color: "#616161" }}>
                        <a
                          href={
                            `${BASE_API_URL}` + "my-account/password/reset/"
                          }
                          style={{ color: "#4B9E99" }}
                        >
                          Forgot Password
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Login;
