import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Formik } from "formik";
import * as Yup from "yup";
import CartCard from "../components/CartCard";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";

// Form
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";

import RazorpayWidget from "../pages/RazorPayWidget";
import { ProductAPI, PopularProductAPI } from "../../globalAPIs/ProductApi";

// API
import {
  RetriveCartApi,
  CartDeleteApi,
  RedeemCartApi,
  TopupApi,
} from "../api/CartInfo";
import { FetchProfileApi, ProfileEdit } from "../../globalAPIs/ProfileApi";
// CSS
import "../style/pages/cart/cart.css";

import { useAppContext } from "../context/UserContext";
import Card from "../components/Card";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const stations = [
  "CST (Chhatrapati Shivaji Terminus)",
  "Masjid Bunder",
  "Sandhurst Road",
  "Byculla",
  "Chinchpokli",
  "Currey Road",
  "Parel",
  "Dadar",
  "Matunga",
  "Sion",
  "Kurla",
  "Vidhyavihar",
  "Ghatkopar",
  "Vikhroli",
  "Kanjurmarg",
  "Bhandup",
  "Nahur",
  "Mulund",
  "Thane",
  "Kalva",
  "Mumbra",
  "Diva",
  "Kopar",
  "Dombivli",
  "Thakurli",
  "Kalyan",
  "Vithalwadi",
  "Ulhasnagar",
  "Ambernath",
  "Badlapur",
  "Vangani",
  "Shelu",
  "Neral",
  "Bhivpuri Road",
  "Karjat",
  "Palasdari",
  "Kelavli",
  "Dolavli",
  "Lowjee",
  "Khopoli",
  "Dockyard Road",
  "Reay Road",
  "Cotton Green",
  "Sewri",
  "Wadala Road",
  "Guru Tegh Bahadur Nagar (GTBN)",
  "Chunabhatti",
  "Tilak Nagar",
  "Chembur",
  "Govandi",
  "Mankhurd",
  "Churchgate",
  "Marine Lines",
  "Charni Road",
  "Grant Road",
  "Mumbai Central",
  "Mahalaxmi",
  "Lower Parel",
  "Elphinstone Road",
  "Matunga Road",
  "Mahim",
  "Bandra",
  "Khar Road",
  "Santacruz",
  "Vile Parle",
  "Andheri",
  "Jogeshwari",
  "Goregaon",
  "Malad",
  "Kandivali",
  "Borivali",
  "Dahisar",
  "Mira Road",
  "Bhayandar",
  "Naigaon",
  "Vasai Road",
  "Nala Sopara",
  "Virar",
];

function Cart() {
  const { cartCount, updateCartcount, customer, isSuperUser, loggedIn } =
    useAppContext();

  const [PopularProduct, setPopularProduct] = useState([]);
  const [Cart, setCartData] = useState([]);
  const [CartItems, setCartItems] = useState([]);
  const [CartTotal, setCartTotal] = useState();
  const [CartTotal2, setCartTotal2] = useState();
  const [loading, setLoading] = useState(true); // New state to track loading status
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    // school: null,   // added
    div: "",
    standard: "",
    promo_code: ""
  });
  const [fetch, setfetch] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [razorpay_orderid, setRazorpayOrderId] = useState([]);
  const [razorpay_amount, setRazorpayAmount] = useState();
  const [orderid, setOrderId] = useState([]);
  const [setted, setValue] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [selectedStation, setSelectedStation] = useState(null);
  const [isActive, setActive] = useState(true)
  const [openCoupon, setOpenCoupon] = useState(false)

  const [loadingAPI, setLoadingAPI] = useState(false);

  const handleStationChange = (event, newValue) => {
    setSelectedStation(newValue);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number should be 10 digits")
      .required("Required"),
    address1: Yup.string().required("Required"),
    address2: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    // school: Yup.number().required('Required'),
    pincode: Yup.string()
      .matches(/^[0-9]{6}$/, "Pincode should be 6 digits")
      .required("Required"),
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const data = await FetchProfileApi();
        if(data.gg_token <= 0  ){
          setActive(false)
        }
        if (loggedIn) {
          setFormData((prevData) => ({
            ...prevData, // spread the previous formData to keep other fields
            name: data.name || "",
            email: data.user_details.email || "",
            phoneNumber: data.mobile_number || "",
            address1: data.address_1 || "",
            address2: data.address_2 || "",
            city: data.city || "",
            pincode: data.postal_code || "",
            password: data.password || "",
            connfirm_password: data.password || "",
          }));
        }
        setIsLoaded(true);
      } catch (error) {
        setIsLoaded(true);
      }
    };
    const fetchCartData = async () => {
      try {
        const data = await RetriveCartApi();
        console.log(data);
        const total = data.cart_items.reduce(
          (acc, item) => acc + item.cart_item_total,
          0
        );
        const total2 = data.cart_items.reduce(
          (acc, item) => acc + item.cart_item_total +item.item_details.deposit,
          0
        );
        setCartData(data);
        setCartItems(data.cart_items);
        setCartTotal(total); // Update the CartTotal state
        setCartTotal2(total2); // Update the CartTotal state
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setLoading(false);
      }
    };
    const fetchPopular = async () => {
      try {
        const data = await PopularProductAPI();
        setPopularProduct(data.result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfileData();
    fetchPopular();
    fetchCartData();
    setfetch(false);
  }, [loggedIn]);

  const deleteCart = (cartId) => {
    const fetchCartData = async () => {
      try {
        const data = await RetriveCartApi();
        const total = data.cart_items.reduce(
          (acc, item) => acc + item.cart_item_total,
          0
        );
        const total2 = data.cart_items.reduce(
          (acc, item) => acc + item.cart_item_total +item.item_details.deposit,
          0
        );
        setCartData(data);
        setCartItems(data.cart_items);
        setCartTotal(total); // Update the CartTotal state
        setCartTotal2(total2); // Update the CartTotal state
        setLoading(false);  // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setLoading(false);
      }
    };
    const CartDelete = async () => {
      try {
        const data = await CartDeleteApi(cartId);
        updateCartcount(cartCount - 1);
        setCartItems((prevCarts) => {
          return prevCarts.filter((CartItems) => CartItems.id !== cartId);
        });
        const newCount = Math.max(cartCount - 1, 0); // Ensure it doesn't go below 0
        updateCartcount(newCount);
        fetchCartData();
        setfetch(true);
      } catch {
        console.error("Error deleting cart:");
      }
    };

    CartDelete();
  };
  const handleCartItemUpdate = (updatedData) => {
    // Handle the updated cart item data in the parent component
    // You can use the updatedData to update the state in Cart.js
    if(isActive){
      setCartTotal(updatedData);
    }else{
      setCartTotal2(updatedData);
    }
  };

  const formSubmit = async (values) => {
    console.log('values', values)
    try {
      if (selectedStation) {
        setLoadingAPI(true);
        const profileedit = await ProfileEdit(values);
        const data = await FetchProfileApi();
        const response = await RedeemCartApi(
          Cart.user,
          updateCartcount,
          values,
          selectedStation
        );

        if (response.response.status === 400 && response?.response?.data?.detail === "Invalid promo code") {
          Swal.fire({
            icon: "error",
            title: response?.response?.data?.detail,
          });
          setLoadingAPI(false)
          // window.location.reload()
        } else if (response.response.status === 400 && response?.response?.data?.shortfall) {
          const shortfall = response.response.data.shortfall;
          const topupresponse = await TopupApi(shortfall);
          setOrderId(topupresponse.id);
          setRazorpayOrderId(topupresponse.razorpay_order_id);
          setRazorpayAmount(shortfall * 100);
          setValue(true);
        }
        if (response.response.status === 502) {
          setLoadingAPI(false);
          Swal.fire({
            icon: "error",
            title: "Request timed out. Tre placing 3 orders at a time",
          });
        }
        if (response.response.status === 500) {
          setLoadingAPI(false);
          Swal.fire({
            icon: "error",
            title: "Opps!",
            text: "It Looks like some of the products in your cart are out of stock remove them & try again",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "You need to select Station",
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (setted) {
      handleOpen();
    }
  }, [setted, razorpay_orderid, razorpay_amount, orderid]);

  const handleCloseCoupon = () => {
    setOpenCoupon(false)
  }

  console.log("Cart Itemmmssss", CartItems);

  if (loading) return <div>Loading...</div>; // Show loading message while data is being fetched

  return (
    <>
      {/* <div className="container cart-wallet">
                    <Wallet bal={'2000'}></Wallet>
                </div> */}

      {loadingAPI && (
        <>
          <div className=" bg-black">
            <ColorRing
              type="Puff"
              colors={["#4B9E99", "#4B9E99", "#4B9E99", "#4B9E99", "#4B9E99"]}
              height={100}
              width={100}
            />
          </div>
        </>
      )}

      {Cart.cart_items.length <= 0 ? (
        <>
          <div className="empty-cart container " style={{marginTop:"50px"}}>
            <div className="left">
              <div className="img-container">
                <img src="/images/cart-empty.png" alt="" />
              </div>
            </div>
            <div className="right">
              <div className="content">The Cart is empty</div>
              <a href="/toy-store">
                <div className="button green">Rent a Toy</div>
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="cart-container container" style={{marginTop:"50px"}}>
            <div className="left">
              <div className="title">Delivery Information</div>
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  formSubmit(values);
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group-container">
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="name">Name</InputLabel>
                          <OutlinedInput
                            id="name"
                            name="name"
                            label="Name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={
                              formik.touched.name && Boolean(formik.errors.name)
                            }
                            helpertext={
                              formik.touched.name && formik.errors.name
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="email">Email Address</InputLabel>
                          <OutlinedInput
                            id="email"
                            name="email"
                            label="Email Address"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helpertext={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="phoneNumber">
                            Phone Number
                          </InputLabel>
                          <OutlinedInput
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            error={
                              formik.touched.phoneNumber &&
                              Boolean(formik.errors.phoneNumber)
                            }
                            helpertext={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <TextField
                            id="address1"
                            name="address1"
                            label="Flat, House no, Building, Company, Apartment"
                            variant="outlined"
                            color="green"
                            multiline
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                            error={
                              formik.touched.address1 &&
                              Boolean(formik.errors.address1)
                            }
                            helpertext={
                              formik.touched.address1 && formik.errors.address1
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <TextField
                            id="address2"
                            name="address2"
                            label="Area, Street, Sector, Village"
                            variant="outlined"
                            color="green"
                            multiline
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.address2}
                            error={
                              formik.touched.address2 &&
                              Boolean(formik.errors.address2)
                            }
                            helpertext={
                              formik.touched.address2 && formik.errors.address2
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="city">City</InputLabel>
                          <OutlinedInput
                            id="city"
                            name="city"
                            label="City"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                            helpertext={
                              formik.touched.city && formik.errors.city
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="pincode">Pincode</InputLabel>
                          <OutlinedInput
                            id="pincode"
                            name="pincode"
                            label="Pincode"
                            onChange={formik.handleChange}
                            value={formik.values.pincode}
                            error={
                              formik.touched.pincode &&
                              Boolean(formik.errors.pincode)
                            }
                            helpertext={
                              formik.touched.pincode && formik.errors.pincode
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <Autocomplete
                            name="selectedStation"
                            value={selectedStation}
                            onChange={handleStationChange}
                            options={stations}
                            renderInput={(params) => (
                              <TextField
                                color="green"
                                {...params}
                                label="Select Station"
                                variant="outlined"
                              />
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <FormControl fullWidth color="green">
                          <InputLabel htmlFor="promo_code">Promo code</InputLabel>
                          <OutlinedInput
                            id="promo_code"
                            name="promo_code"
                            label="Promo code"
                            onChange={formik.handleChange}
                            value={formik.values.promo_code}
                            error={
                              formik.touched.promo_code &&
                              Boolean(formik.errors.promo_code)
                            }
                            helpertext={
                              formik.touched.promo_code && formik.errors.promo_code
                            }
                          />
                        </FormControl>
                      </div>
                    </div>

                    <div className="form-group mt-20">
                      <input
                        type="submit"
                        value="Rent Now"
                        className="button green"
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <div className="right">
              <div className="title">My Cart</div>
              <div className="my-cart ">
                {CartItems.map((e) => {
                  return (
                    <CartCard
                      img={e.item_details.image}
                      name={e.item_details.name}
                      price={e.cart_item_total}
                      deposit={e.item_details.deposit}
                      key={e.id}
                      product_id={e.item_details.id}
                      date={e.desired_delivery_date}
                      cart_id={e.id}
                      days={e.days}
                      cartData={e}
                      is_active={isActive}
                      cart_total={CartTotal}
                      deleteCart={deleteCart}
                      toggleFetch={setfetch}
                      onCartItemUpdated={handleCartItemUpdate}
                      is_available={e.item_details.is_available}
                    />
                  );
                })}
              </div>
              <div className="total-cart container">
                
                <div className="content">
                  <div className="small-text">Total</div>
                  <div className="token">
                    <div className="icon-container">
                      <svg
                        width="49"
                        height="49"
                        viewBox="0 0 49 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_308_10291)">
                          <path
                            d="M24.5 49C38.0309 49 49 38.0309 49 24.5C49 10.9691 38.0309 0 24.5 0C10.9691 0 0 10.9691 0 24.5C0 38.0309 10.9691 49 24.5 49Z"
                            fill="#F6D738"
                          />
                          <path
                            d="M24.5 45.2928C35.9836 45.2928 45.2928 35.9836 45.2928 24.5C45.2928 13.0164 35.9836 3.70715 24.5 3.70715C13.0164 3.70715 3.70718 13.0164 3.70718 24.5C3.70718 35.9836 13.0164 45.2928 24.5 45.2928Z"
                            fill="#F3CC30"
                          />
                          <path
                            d="M35.3785 6.75323C37.5206 10.0955 38.6561 13.9834 38.6498 17.9532C38.6498 29.4368 29.3406 38.746 17.857 38.746C14.0162 38.7519 10.2494 37.6887 6.97845 35.6755C8.85781 38.6178 11.4483 41.0391 14.5107 42.7157C17.5731 44.3924 21.0087 45.2703 24.5 45.2683C35.9836 45.2683 45.2928 35.959 45.2928 24.4755C45.2945 20.92 44.3839 17.4236 42.6481 14.3207C40.9122 11.2178 38.4092 8.61226 35.3785 6.75323Z"
                            fill="#EDBD31"
                          />
                          <path
                            d="M7.34956 26.4025C7.34956 16.2655 15.5672 8.04789 25.7043 8.04789C28.5167 8.04557 31.292 8.69075 33.815 9.93335C36.3381 11.176 38.5412 12.9828 40.2539 15.2137C38.6454 12.4146 36.3269 10.0894 33.5324 8.47292C30.7379 6.8564 27.5663 6.00588 24.338 6.0072C14.2011 6.0072 5.98336 14.2249 5.98336 24.3619C5.97735 28.41 7.31575 32.3455 9.78844 35.5507C8.18579 32.7686 7.34458 29.6132 7.34956 26.4025Z"
                            fill="#F7DB5E"
                          />
                          <path
                            d="M19.6386 21.8957C19.42 21.5355 19.1177 21.259 18.7319 21.0661C18.3589 20.8732 17.9152 20.7768 17.4008 20.7768C16.4491 20.7768 15.6967 21.0854 15.1437 21.7028C14.6035 22.3201 14.3335 23.1496 14.3335 24.1913C14.3335 25.3617 14.6228 26.2555 15.2016 26.8728C15.7932 27.4773 16.6484 27.7795 17.7673 27.7795C19.092 27.7795 20.0373 27.1815 20.6031 25.9854H16.8027V23.2653H23.8634V26.9307C23.5676 27.6509 23.1303 28.3261 22.5516 28.9563C21.9857 29.5865 21.2654 30.1074 20.3909 30.5189C19.5164 30.9176 18.5197 31.1169 17.4008 31.1169C16.0375 31.1169 14.8286 30.8276 13.774 30.2488C12.7323 29.6572 11.922 28.8405 11.3433 27.7988C10.7774 26.7442 10.4945 25.5417 10.4945 24.1913C10.4945 22.8538 10.7774 21.6642 11.3433 20.6224C11.922 19.5678 12.7323 18.7512 13.774 18.1724C14.8157 17.5808 16.0182 17.285 17.3815 17.285C19.092 17.285 20.5067 17.6966 21.6256 18.5197C22.7445 19.3428 23.439 20.4681 23.709 21.8957H19.6386ZM34.239 21.8957C34.0203 21.5355 33.7181 21.259 33.3323 21.0661C32.9593 20.8732 32.5156 20.7768 32.0012 20.7768C31.0495 20.7768 30.2971 21.0854 29.7441 21.7028C29.2039 22.3201 28.9338 23.1496 28.9338 24.1913C28.9338 25.3617 29.2232 26.2555 29.8019 26.8728C30.3936 27.4773 31.2488 27.7795 32.3677 27.7795C33.6924 27.7795 34.6377 27.1815 35.2035 25.9854H31.4031V23.2653H38.4638V26.9307C38.168 27.6509 37.7307 28.3261 37.1519 28.9563C36.5861 29.5865 35.8658 30.1074 34.9913 30.5189C34.1168 30.9176 33.1201 31.1169 32.0012 31.1169C30.6379 31.1169 29.429 30.8276 28.3744 30.2488C27.3327 29.6572 26.5224 28.8405 25.9437 27.7988C25.3778 26.7442 25.0949 25.5417 25.0949 24.1913C25.0949 22.8538 25.3778 21.6642 25.9437 20.6224C26.5224 19.5678 27.3327 18.7512 28.3744 18.1724C29.4161 17.5808 30.6186 17.285 31.9819 17.285C33.6924 17.285 35.1071 17.6966 36.226 18.5197C37.3449 19.3428 38.0393 20.4681 38.3094 21.8957H34.239Z"
                            fill="#D49A34"
                          />
                          <path
                            d="M18.9493 34.7923V35.5627H18.0209V38.274H17.0529V35.5627H16.1343V34.7923H18.9493ZM20.9767 38.3086C20.6508 38.3086 20.3511 38.2329 20.0779 38.0814C19.8046 37.9267 19.5873 37.7143 19.426 37.4443C19.2679 37.1711 19.1889 36.8632 19.1889 36.5208C19.1889 36.1784 19.2679 35.8722 19.426 35.6022C19.5873 35.329 19.8046 35.1166 20.0779 34.9652C20.3511 34.8137 20.6508 34.738 20.9767 34.738C21.306 34.738 21.6055 34.8137 21.8755 34.9652C22.1488 35.1166 22.3644 35.329 22.5225 35.6022C22.6805 35.8722 22.7595 36.1784 22.7595 36.5208C22.7595 36.8632 22.6805 37.1711 22.5225 37.4443C22.3644 37.7143 22.1488 37.9267 21.8755 38.0814C21.6023 38.2329 21.3026 38.3086 20.9767 38.3086ZM20.9767 37.4097C21.2236 37.4097 21.4179 37.3291 21.5594 37.1678C21.7043 37.0064 21.7767 36.7908 21.7767 36.5208C21.7767 36.2442 21.7043 36.0269 21.5594 35.8689C21.4179 35.7076 21.2236 35.6269 20.9767 35.6269C20.7264 35.6269 20.5306 35.7076 20.389 35.8689C20.2474 36.0269 20.1766 36.2442 20.1766 36.5208C20.1766 36.794 20.2474 37.0113 20.389 37.1727C20.5306 37.3307 20.7264 37.4097 20.9767 37.4097ZM25.254 38.274L24.1626 36.748V38.274H23.1947V34.7923H24.1626V36.2936L25.2442 34.7923H26.3603L25.0812 36.4813L26.4245 38.274H25.254ZM27.746 35.5677V36.1356H28.8572V36.8714H27.746V37.4986H29.0054V38.274H26.7781V34.7923H29.0054V35.5677H27.746ZM32.7085 38.274H31.7406L30.4516 36.3282V38.274H29.4836V34.7923H30.4516L31.7406 36.7628V34.7923H32.7085V38.274Z"
                            fill="#D49A34"
                          />
                        </g>
                      </svg>
                    </div>
                    {isActive? (<>{CartTotal} GG Token</>):(<>{CartTotal2} GG Token</>)}
                    
                  </div>
                  {/* <p className=" mt-20" style={{ cursor:'pointer' }} onClick={()=> setOpenCoupon(true)}>Apply Coupon Code</p> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {setted ? (
          <>
            <RazorpayWidget
              id={orderid}
              amount={razorpay_amount}
              rpay_order_id={razorpay_orderid}
            ></RazorpayWidget>
          </>
        ) : (
          <></>
        )}
      </Modal>

      {/* //Apply Coupon Code */}
      <Dialog open={openCoupon} onClose={handleCloseCoupon}>
        <DialogTitle>{'Apply Coupon code'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Code
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            color="green"
            id="name"
            label="Coupon code"
            type="text"
            fullWidth
            variant="outlined"

          />
        </DialogContent>
        <DialogActions>

          <button className="button green">Apply</button>

        </DialogActions>

      </Dialog>
    </>
  );
}

export default Cart;
