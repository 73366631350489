import React from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Components
import { SubscriptionTable } from '../components/Table/TableComponent'

// API
import data from '../../../web/api/SubscriptionInfo';


function Subscriptions() {
	return (
		<>
			<div className="title-container">
				<h5>Subscriptions</h5>
			</div>

			{/* Subcription Table */}
			<Box sx={{ flexGrow: 1, mt: 2 }}>

				<Grid container spacing={2}>
					<SubscriptionTable 
					extrasmall={12} small={12} medium={12}
					cardtitle={'Subscriptions'}
					data={data}
					>

					</SubscriptionTable>
				</Grid>
			</Box>
			{/* Subcription Table */}

		</>
	)
}

export default Subscriptions