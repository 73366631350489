import axios from 'axios';
import { BASE_API_URL } from '../server'



export const SubscriptionAPI = async () => {
    const apiEndpoint = `${BASE_API_URL}api/subscription/`;
    try {
        const response      = await axios.get(apiEndpoint, { withCredentials: true });
        const Profile       = response.data
        return Profile;

    } catch (error) {
        console.log('***** ERROR in Fetching Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};