import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {BASE_API_URL} from '../server'

export const FaqAPI = async () => {
    const apiEndpoint = `${BASE_API_URL}api/faq/`;
    try {
        const response = await axios.get(apiEndpoint, { withCredentials: true });
        const data = response.data
        return data;

    } catch (error) {
        console.log('***** ERROR in FAQ Order *****');
        console.log(error);
        console.log('***************************************');
        // Additional error handling as needed
    }
};