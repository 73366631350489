import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';

import { BASE_API_URL } from '../../server';
import axios from 'axios';

// Form
import { TextField, IconButton, InputAdornment, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Swal from 'sweetalert2';
import { useAppContext } from '../context/UserContext';

import { useNavigate, Link } from 'react-router-dom';





function Register() {

    const register = `${BASE_API_URL}account/register/`;
    const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
    const loginApiUrl = `${BASE_API_URL}account/login/`;


    const [csrfToken, setCsrfToken] = useState();

    const { UpdateLogin, updateSuperUser, updatecustomer, updateCsrf } = useAppContext();

    const validationSchema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        username: yup
            .string('Enter your username')
            .required('username is required'),
        first_name: yup
            .string('Enter your name')
            .required('first name is required'),
        last_name: yup
            .string('Enter your name')
            .required('Last name is required'),
        mobile_number: yup
            .string('Enter your mobile number')
            .required('Mobile Number is required'),
        password: yup
            .string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.'),
        confirmpassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [showerror, setshowError] = useState([]);



    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Fetch CSRF Token API
    const fetchCsrfToken = async () => {
        const abortController = new AbortController();
        try {
            const response = await axios.get(
                csrfTokenApiUrl,
                { withCredentials: true }
            );
            setCsrfToken(response.data.csrftoken);
            return () => {
                abortController.abort()
            }
        } catch (error) {
            console.error('There was a problem fetching the CSRF token:', error);
        }
    };


    

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '', // Added last_name
            email: '',
            username: '',
            password: '',
            confirmpassword: '',
            address1: '',  // Added address1
            address2: '',  // Added address2
            mobile_number: '',  // Added address2
        },
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            console.log(formik.errors);

            console.log(values)
            try {
                const response = await axios.post(
                    register, {
                    username: values.username,
                    password: values.password,
                    email: values.email,
                    profile: {
                        profile_image: null,
                        name: values.first_name+values.last_name,
                        address_1: values.address1 || null,
                        address_2: values.address2 || null,
                        postal_code: null,
                        city: null,
                        region_state: null,
                        country: null,
                        user_type: "customer",
                        mobile_number: values.mobile_number || null,
                    }
                },
                    {
                        headers: {
                            'Accept': 'application/json',
                            
                            
                        },
                        withCredentials: true,
                    }
                )
                if (response.status >= 200 && response.status < 300) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'You are Successfuly registered.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(async function () {
                        if (typeof window.gtag === 'function') {
                            window.gtag('event', 'conversion', {'send_to': 'AW-16600074795/OO_wCK2dx78ZEKuUxOs9'});
                          } else {
                            console.error('gtag is not defined');
                          }
                        window.location = '/login'
                    });
                }
            } catch (error) {
                const errorResponse = error.response.data;
                console.log(error)
                let newErrors = {};
                for (let key in errorResponse) {
                    if (errorResponse.hasOwnProperty(key)) {
                        newErrors[key] = errorResponse[key][0];  // assuming each key in errorResponse has an array, and you want to store the first message.
                    }
                }
                setshowError(newErrors)
            }
        },
    });



    // Calling the fetch CSRF
    useEffect(() => {
        // fetchCsrfToken();
    }, [2]);

    return (
        <>
            <div className="registeration-container login-container">
                <div className="left">
                    <div className="logo-container">
                        <img src="/images/logo-2.png" alt="" />
                    </div>
                    <div className="img-container">
                        <img src="/images/login.png" alt="" />
                    </div>
                </div>
                <div className="right">

                    <div className='form'>
                        <div className="logo-container">
                            <img src="/images/logo-2.png" alt="" />
                        </div>
                        <div className="title-container flex-direction">
                            <h3 style={{ marginBottom: '5px' }}>Welcome To Growing Giraffe</h3>
                            <span style={{ color: '#616161' }}>Already have an account? <a style={{ color: '#4B9E99' }} href="/login">Sigup</a></span>
                        </div>

                        {error ? (
                            <>

                            </>) :
                            (<>
                                {Object.entries(showerror).map(([key, value]) => (
                                    <div className='error'>
                                        <p key={key}>{key}: {value}</p>
                                    </div>
                                ))}
                            </>)}


                        <form onSubmit={formik.handleSubmit} >
                            <div className="form-group-container mt-20">
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="first_name"
                                            name="first_name"
                                            required
                                            label="First Name"
                                            color="green"
                                            value={formik.values.first_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                            helperText={formik.touched.first_name && formik.errors.first_name}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="last_name"
                                            name="last_name"
                                            required
                                            label="Last Name"
                                            color="green"
                                            value={formik.values.last_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="username"
                                            name="username"
                                            required
                                            label="Username"
                                            color="green"
                                            value={formik.values.username}
                                            onChange={e => {
                                                // Transform the input value to lowercase
                                                const lowercaseValue = e.target.value.toLowerCase();
                                                // Update formik state with the lowercase value
                                                formik.setFieldValue("username", lowercaseValue);
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.username && Boolean(formik.errors.username)}
                                            helperText={formik.touched.username && formik.errors.username}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="mobile_number"
                                            name="mobile_number"
                                            required
                                            label="Mobile Number"
                                            color="green"
                                            value={formik.values.mobile_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                                            helperText={formik.touched.mobile_number && formik.errors.mobile_number}
                                        />
                                    </FormControl>
                                </div>
                                
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            color="green"
                                            required

                                            value={formik.values.email}
                                            onChange={e => {
                                                // Transform the input value to lowercase
                                                const lowercaseValue = e.target.value.toLowerCase();
                                                // Update formik state with the lowercase value
                                                formik.setFieldValue("email", lowercaseValue);
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            name="password"
                                            label="Password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            required
                                            color="green"
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            id="confirmpassword"
                                            name="confirmpassword"
                                            label="Confirm Password"
                                            value={formik.values.confirmpassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
                                            fullWidth
                                            required
                                            color="green"

                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="address1"
                                            name="address1"
                                            label="Flat, House no, Building, Company, Apartment"
                                            color="green"

                                            value={formik.values.address1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                            helperText={formik.touched.address1 && formik.errors.address1}
                                            multiline
                                        />
                                    </FormControl>
                                </div>

                                <div className="form-group">
                                    <FormControl fullWidth color="green">
                                        <TextField
                                            fullWidth
                                            id="address2"
                                            name="address2"
                                            label="Area, Street, Sector, Village"
                                            color="green"
                                            value={formik.values.address2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                            helperText={formik.touched.address2 && formik.errors.address2}
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <input type='submit' value={'Login'} className='button green' style={{ width: "100%" }} />
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>

    );
}

export default Register