import React from 'react'

function HouseRules() {
    return (

        <>
            <section className='container case-detail-container'>
                <div class="case-study-detail">
                    <div class="case-study-title-container">
                        <div class="case-study-title">
                            <h1>House Rules</h1>
                        </div>
                    </div>
                    <div class="case-study">
                        <div class="section client-problem">
                            <div class="heading">
                                GG Tokens
                            </div>
                            Each Growing Giraffe product is valued as per GG Tokens based on the price and durability. This is your virtual wallet which can be used to pick the toys and books as per your chosen plan. As per your chosen plan, you get GG Tokens for the mentioned duration. On the expiry of the plan, the balance Money will be carried forward in case of renewal within 3 months of the date of expiry.
                            Please note that the GG Tokens is not real money, nor is it equivalent to any real money and hence, cannot be exchanged for real money. GG Tokens is the value of each GrowingGiraffe product.
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Damages or Loss
                            </div>
                            The amount of damage fee will be decided as per our policy. We request all our members to check the toys and books at the time of delivery. You can refuse to accept any toys/books in case you notice damage. We will replace the same soon.
                            <br />
                            <br />
                            In case of damage or loss caused by you, you will be charged a replacement amount of the toy/book. The decision of the cost of replacement will be at the sole discretion of Growing Giraffe. The damages against any toys/books will be recovered on a monthly basis and you will be expected to replenish the deducted amount to maintain the deposit.
                            The replacement of the exact same product can also be handed over to us by sourcing it from your means.
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Change of delivery date
                            </div>
                            You can choose your date of delivery as per your convenience.                            <br />
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Milestone Card
                            </div>
                            The Milestone Card is an AI based card generated on the basis of the inputs provided by you and the toys/books chosen by you enroute your journey with Growing Giraffe. 
                            <br />
                            <br />
                            You can fill up a questionnaire after registering yourself at our website if you would like to receive recommendations from us about the toys/books. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Extended use of Toys
                            </div>
                            You can inform us a week before your next scheduled delivery if you wish to keep a toy/book for an extended period of time. We will adjust the same with your balance GG Tokens. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Delivery
                            </div>
                            You can inform us a week before your next scheduled delivery if you wish to keep a toy/book for an extended period of time. We will adjust the same with your balance GG Tokens. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Plans
                            </div>
                            We have quarterly, half yearly, nine months and annual plans. Please look up on our Plans page for more details. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Cart
                            </div>
                            CART is a space where the toys/books that you select will appear. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Wishlist
                            </div>
                            Wishlist is a space where you can keep the toys that you like for future rentals. You can wishlist items that you like but are currently out of circulation. We will notify you when that particular item is available for renting.
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Payments
                            </div>
                            Wishlist is a space where you can keep the toys that you like for future rentals. You can wishlist items that you like but are currently out of circulation. We will notify you when that particular item is available for renting.
                            <ul>
                                <li>Cash</li>
                                <li>Gpay</li>
                                <li>Online transfer</li>
                                <li>Debit card/credit card</li>
                            </ul>

                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Toy Care
                            </div>
                            Please make sure that your child maintains the basic hygiene while using the toys and books. We would appreciate your mindfulness in cleaning/washing the toys before returning them to us. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Safety Disclaimer
                            </div>
                            While we take utmost care in choosing our products, as a parent, we expect you to be the best judge of what kinds of toys/books are safe and suitable for your child. GrowingGiraffe shall not be held responsible for any injury or harm our products might cause the child. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Code of Ethics
                            </div>
                            We expect all our members to maintain a basic code of ethics while communicating with us or posting comments on our website. Do not use any derogatory, defamatory or obscene language, remarks or emojis. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Subscription Fee
                            </div>
                            This is the fee that you pay as per your chosen toy rental plan. Once paid, the subscription fee will not be refundable. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Security Deposit
                            </div>
                            This is a fully refundable amount that you pay as security. At the end of your membership, the entire amount of the Security Deposit will be refunded to you after deducting the damages/loss if any. The damages against any toys/books will be recovered on a monthly basis and you will be expected to replenish the deducted amount to maintain the deposit. The amount of the Security Deposit will be refunded within 7 working days of the termination of the plan.
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Late Fee
                            </div>
                            In case you miss the return/exchange of the toys/book as per the scheduled date, per day rental as given on the website shall be charged. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Booking Toys
                            </div>
                            You can book the toys/books as per your need and requirement. You have the flexibility to choose the number of days for which you’d like to keep the products with you.
                            <br />
                            <br />
                            In case you miss booking before your chosen cycle, the GG Tokens from your next cycle will be adjusted in lieu of the toys/books that you already have.The products will be booked subject to its availability. 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                                Gift Cards
                            </div>
                            Gift cards can be redeemed within 12 months of the date of issue. 
                        </div>
                       
                        
                        <div class="section team-solution">
                            <div class="heading">
                                Communication
                            </div>
                            Please get in touch with us by writing to us on info@growinggiraffe.com or give us a call on 9321146692 
                        </div>
                        <div class="section team-solution">
                            <div class="heading">
                            Termination / Cancellation
                            </div>
                            In case of non adherence to any of the terms mentioned above Growing Giraffe holds complete and non-negotiable right to terminate your membership. GrowingGiraffe will not be liable to refund the Security Deposit in such a case. 
                        </div>
                       
                    </div>
                </div>
            </section>
        </>

    )
}

export default HouseRules