import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

// Form
import {
  Autocomplete,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Swal from "sweetalert2";
import axios from "axios";

import { BASE_API_URL } from "../../../../server";

// API
import data from "../../../../web/api/SubscriptionInfo";
import { useFormik } from "formik";
import * as yup from "yup";
import { stations } from "../../../../web/pages/Cart";
import { SchoolApi } from "../../api/SchoolApi";
import { AgeApi } from "../../api/AgeApi";
import { AddCustomerPasswordApi, CustomerApi, EditCustomerApi } from "../../api/CustomerApi";
import { SubscriptionAPI } from "../../../../globalAPIs/SubscriptionApi";
import { AddOrderApi } from "../../api/OrderApi";

// BOX
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "red" : "white",
  ...theme.typography.body2,
  padding: "20px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid #E7E7E7",
  color: "black",
  height: "100%",
}));

function AddCusomter() {
  const addUser = `${BASE_API_URL}api-admin/auth/user/add/`;

  const addSubscription = `${BASE_API_URL}api-admin/web/profilesubscription/add/`;
  const [School, setSchool] = useState(null);
  const [ageData, setageData] = useState(null);
  const [pricing, setSubscription] = useState();
  const [subPrice, setsubPrice] = useState();
  const [token, setToken] = useState();

  // State to hold the milestone children
  const [children, setChildren] = useState(null);

  // Function to handle adding a new child
  const addChild = () => {
    if (!children) {
      // If there are no children yet, add the first child
      setChildren([{ child_name: "", age: "", id: uuidv4(), milestone: null }]);
    } else {
      // Otherwise, add a new child to the existing array
      setChildren([
        ...children,
        { child_name: "", age: "", id: uuidv4(), milestone: null },
      ]);
    }
  };

  // Function to handle changes to a child's name and age
  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const usersType = ["school", "customer"];

  const fetchData = async () => {
    const [school, age] = await Promise.all([SchoolApi('?page_size=1000'), AgeApi()]);

    console.log('fnmedbfo', school)
    setSchool(school);
    setageData(age?.results);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const data = await SubscriptionAPI();
        setSubscription(data.results)
      } catch (error) {

      }
    };

    fetchSubscription();
  }, []);

  // Calculate today's date
const today = new Date();
const start_date = today.toISOString().split('T')[0];

// Calculate one year from today
const one_year_from_today = new Date(today);
one_year_from_today.setFullYear(today.getFullYear() + 1);
const end_date = one_year_from_today.toISOString().split('T')[0];

  const validationSchema = yup.object({
    email: yup
      .string("Enter email")
      .email("Enter a valid email")
      .required("Email is required"),
    username: yup.string("Enter username").required("username is required"),
    first_name: yup.string("Enter name").required("first name is required"),
    last_name: yup.string("Enter name").required("Last name is required"),
    gg_token: yup.string("Enter GG Token").required("GG Token is required"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    mobile_number: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone number should be 10 digits")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "", // Added last_name
      email: "",
      username: "",
      password: "",
      confirmpassword: "",
      city: "",
      station: "",
      school_name: "",
      user_type: "",
      address1: "", // Added address1
      address2: "", // Added address2
      mobile_number: "",
      gg_token: 0,
      start_date: start_date, // Set start_date to today's date
      end_date: end_date, // Set end_date to one year from today
      subscription: null,
      is_deposit_refunded: false,
      is_comission_paid: false,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      //milestone object
      const milestone = {
        child: children,
        token_leisure: [
          {
            selectedTokens: token + Number(values?.gg_token),
            comment: `${token + Number(values?.gg_token)} token has been alloted from Backend`,
            createdDate: new Date().toISOString().split("T")[0],
          }
        ],
      };
      const milestoneJson = JSON.stringify(milestone);
      try {
        const response = await axios.post(
          addUser,

          {
            password: values?.password,
            last_login: null,
            is_superuser: false,
            username: values.username,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            is_staff: false,
            is_active: true,
            date_joined: new Date(),
          },
          {
            headers: {
              Accept: "application/json",
            },
            withCredentials: true,
          }
        );

        console.log("responsee data", response);
        await AddCustomerPasswordApi(response?.data?.data?.pk, {
          new_password: values?.password,
        })
        const profile = await CustomerApi(`&user=${response?.data?.data?.pk}`);

        const addCustomer = `${BASE_API_URL}api-admin/web/profile/${profile?.results[0]?.id}/change/`;
        const profileData = {
          email: values.email,
          user: response?.data?.data?.pk,
          name: `${values.first_name} ${values.last_name}`,
          address_1: values.address1 || null,
          address_2: values.address2 || null,
          city: values.city,
          station: values.station,
          country: "India",
          username: values.username,
          user_type: values?.user_type,
          school_name: values.school_name?.id||"",
          mobile_number: values.mobile_number,
          gg_token: values.gg_token,
          milestone: milestone,
        };
        // Create FormData object
        const formData = new FormData();

        for (const key in profileData) {
          // For other keys, append the original value
          if (key === "milestone") {
            formData.append(key, milestoneJson);
          } else {
            formData.append(key, profileData[key]);
          }
        }

        if (response.status >= 200) {
          const result = await axios.put(addCustomer, formData, {
            headers: {
              Accept: "application/json",
            },
            withCredentials: true,
          });
        }

        const subscription_data = {
          user: response?.data?.data?.pk,
          subscription: values?.subscription,
          start_date: values?.start_date,
          end_date: values?.end_date,
        }

        if (response.status >= 200) {
          const totaltoken = token + Number(values?.gg_token)

          const updatedtoken = {
            "gg_token": totaltoken,
          }
          const order_data = {
            "user": response?.data?.data?.pk,
            "email": values?.email,
            "school": values?.school_name?.id,
            "country": values?.country,
            "first_name": values?.first_name,
            "last_name": values?.last_name,
            "address": values?.address1 ?? values?.address2,
            "city": values?.city,
            "pincode": values?.postal_code,
            "phone_number": values?.mobile_number,
            "total": subPrice,
            "status": 'working'
          }

          // const res = await axios.post(
          //   addSubscription,
          //   subscription_data,
          //   {
          //     headers: {
          //       Accept: "application/json",
          //     },
          //     withCredentials: true,
          //   }
          // );

          // const orderdata = await AddOrderApi(order_data)
          // console.log(orderdata)

          // const updateToken = await EditCustomerApi(updatedtoken, response?.data?.data?.pk)

          // if (res.status >= 200) {
            Swal.fire({
              title: "Success!",
              text: "You are Successfully registered.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(async function () {
              window.location.href = "/admin/customers";
            });
          // } else {
          //   // Handle the case where the second request fails
          //   Swal.fire({
          //     title: "Error",
          //     text: "Something went wrong while adding Subscription",
          //     icon: "error",
          //   });
          // }
        }
      } catch (error) {
        // Checking if the error structure is as expected
        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessages = error.response.data.errors;
          let errorMessage = '';

          // Aggregate error messages
          for (let key in errorMessages) {
            if (Object.prototype.hasOwnProperty.call(errorMessages, key)) {
              // Assuming each key in errorMessages has an array of messages
              // Join all messages for a key with a comma
              errorMessage += `${errorMessages[key].join(', ')}\n`;
            }
          }

          console.log('message', errorMessage);

          // Use SweetAlert to display the error messages
          if (errorMessage) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `<p>${errorMessage}</p>`, // Display errors using `html` to render the pre tag correctly
            });
          } else {
            // Fallback error message if no specific errors were found
            Swal.fire(
              'Error',
              'An unexpected error occurred. Please try again.',
              'error'
            );
          }
        } else {
          // Handle cases where the error structure is not as expected
          Swal.fire(
            'Error',
            'An unexpected error occurred. Please try again.',
            'error'
          );
        }
      }

    },
  });

  console.log("input vcdscvaluesss", pricing);

  return (
    <>
      <div className="title-container">
        <h5>Add Customer</h5>
      </div>

      {/* Order Add Form */}
      <Box sx={{ flecGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Item className="popular-box">
              <div className="card-title-container">
                <div className="card-title">Personal Details</div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group-container mt-20">
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="first_name"
                        name="first_name"
                        required
                        label="Customer First Name"
                        color="green"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                        helperText={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="last_name"
                        name="last_name"
                        required
                        label="Customer Last Name"
                        color="green"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                        helperText={
                          formik.touched.last_name && formik.errors.last_name
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        required
                        type="password"
                        label="Password"
                        color="green"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="confirmpassword"
                        name="confirmpassword"
                        required
                        type="password"
                        label="Confirm Password"
                        color="green"
                        value={formik.values.confirmpassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirmpassword &&
                          Boolean(formik.errors.confirmpassword)
                        }
                        helperText={
                          formik.touched.confirmpassword && formik.errors.confirmpassword
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="username"
                        name="username"
                        required
                        label="Customer Username"
                        color="green"
                        value={formik.values.username}
                        onChange={(e) => {
                          // Transform the input value to lowercase
                          const lowercaseValue = e.target.value.toLowerCase();
                          // Update formik state with the lowercase value
                          formik.setFieldValue("username", lowercaseValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.username &&
                          Boolean(formik.errors.username)
                        }
                        helperText={
                          formik.touched.username && formik.errors.username
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        color="green"
                        required
                        value={formik.values.email}
                        onChange={(e) => {
                          // Transform the input value to lowercase
                          const lowercaseValue = e.target.value.toLowerCase();
                          // Update formik state with the lowercase value
                          formik.setFieldValue("email", lowercaseValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="mobile_number"
                        name="mobile_number"
                        required
                        label="Mobile Number"
                        color="green"
                        value={formik.values.mobile_number}
                        onChange={(e) => {
                          // Transform the input value to lowercase
                          const lowercaseValue = e.target.value.toLowerCase();
                          // Update formik state with the lowercase value
                          formik.setFieldValue("mobile_number", lowercaseValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.mobile_number &&
                          Boolean(formik.errors.mobile_number)
                        }
                        helperText={
                          formik.touched.mobile_number &&
                          formik.errors.mobile_number
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="gg_token"
                        name="gg_token"
                        label="GG Tokens"
                        color="green"
                        required
                        value={formik.values.gg_token}
                        disabled
                        onChange={(e) => {
                          // Transform the input value to lowercase
                          const lowercaseValue = e.target.value.toLowerCase();
                          // Update formik state with the lowercase value
                          formik.setFieldValue("gg_token", lowercaseValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.gg_token &&
                          Boolean(formik.errors.gg_token)
                        }
                        helperText={
                          formik.touched.gg_token && formik.errors.gg_token
                        }
                      />
                    </FormControl>
                  </div>

                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="city"
                        name="city"
                        required
                        label="City"
                        color="green"
                        value={formik.values.city}
                        onChange={(e) => {
                          // Transform the input value to lowercase
                          const lowercaseValue = e.target.value.toLowerCase();
                          // Update formik state with the lowercase value
                          formik.setFieldValue("city", lowercaseValue);
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.username && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </FormControl>

                    <Autocomplete
                      name="selectedStation"
                      value={formik.values.station}
                      fullWidth
                      onChange={(event, newValue) => {
                        // Handle the selection change here
                        formik.setFieldValue("station", newValue); // Set the selected user value
                      }}
                      options={stations}
                      renderInput={(params) => (
                        <TextField
                          color="green"
                          {...params}
                          label="Select Station"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.station && Boolean(formik.errors.station)
                      }
                      helperText={
                        formik.touched.station && formik.errors.station
                      }
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                  </div>

                  <div className="form-group">
                    <Autocomplete
                      name="selectedSchool"
                      value={
                        formik.values.school_name
                          ? {
                            name: formik.values.school_name.name,
                            id: formik.values.school_name.id,
                          }
                          : null
                      }
                      fullWidth
                      getOptionLabel={(option) => option?.name}
                      onChange={(event, newValue) => {
                        // Handle the selection change here
                        formik.setFieldValue("school_name", newValue); // Set the selected user value
                      }}
                      options={School}
                      renderInput={(params) => (
                        <TextField
                          color="green"
                          {...params}
                          label="Select School"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    <Autocomplete
                      name="userType"
                      fullWidth
                      value={formik.values.user_type}
                      onChange={(event, newValue) => {
                        // Handle the selection change here
                        formik.setFieldValue("user_type", newValue); // Set the selected user value
                      }}
                      options={usersType}
                      renderInput={(params) => (
                        <TextField
                          color="green"
                          {...params}
                          label="Select User Type"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  {/* Address */}
                  <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="address1"
                        name="address1"
                        label="Address"
                        color="green"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.address1 &&
                          Boolean(formik.errors.address1)
                        }
                        helperText={
                          formik.touched.address1 && formik.errors.address1
                        }
                        multiline
                      />
                    </FormControl>
                  </div>
                  {/* <div className="form-group">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        id="address2"
                        name="address2"
                        label="Address 2"
                        color="green"
                        value={formik.values.address2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.address2 &&
                          Boolean(formik.errors.address2)
                        }
                        helperText={
                          formik.touched.address2 && formik.errors.address2
                        }
                        multiline
                      />
                    </FormControl>
                  </div> */}

                  {/* //Milestone Childrens */}
                  <div>
                    {children &&
                      children.map((child, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "10px 0",
                          }}
                        >
                          <TextField
                            label={`Child ${index + 1} Name`}
                            value={child.child_name}
                            fullWidth
                            onChange={(e) =>
                              handleChildChange(
                                index,
                                "child_name",
                                e.target.value
                              )
                            }
                          />

                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              id="demo-simple-select-label"
                              color="green"
                            >
                              Age
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) =>
                                handleChildChange(index, "age", e.target.value)
                              }
                              label="Age"
                              color="green"
                              key="age"
                              name="age"
                            >
                              <MenuItem key="1-2" value="1-2">
                                1-2
                              </MenuItem>
                              <MenuItem key="2-4" value="2-4">
                                2-4
                              </MenuItem>
                              <MenuItem key="4-6" value="4-6">
                                4-6
                              </MenuItem>
                              <MenuItem key="6-8" value="6-8">
                                6-8
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ))}
                    <div className="button yellow" onClick={addChild}>
                      Add Child
                    </div>
                  </div>

                  {/* //Subscription */}
                  <div className="form-group">
                    {/* <FormControl fullWidth>
                      <InputLabel color="green" id="select-subscription-label">
                        Select Subscription
                      </InputLabel>
                      <Select
                        labelId="select-subscription-label"
                        label="Select Subscription"
                        id="select-subscription"
                        color="green"
                        value={formik?.values?.subscription}
                        onChange={
                          (e) =>
                            formik.setFieldValue("subscription", e.target.value) // Set the selected user value
                        }
                      >
                        <MenuItem value={4}>3 months</MenuItem>
                        <MenuItem value={2}>6 months</MenuItem>
                        <MenuItem value={3}>12 months</MenuItem>
                      </Select>
                    </FormControl> */}

                    {/* <FormControl fullWidth>
                      <InputLabel id="select-subscription-label" color="green">
                        Select Subscription
                      </InputLabel>
                      <Select
                        label="Select Subscription"
                        labelId="select-subscription-label"
                        id="select-subscription"
                        color="green"
                        value={formik?.values?.subscription}
                        onChange={
                          (e) => {
                            const selectedPriceId = e.target.value;
                            formik.setFieldValue("subscription", selectedPriceId) // Set the selected user value
                            const selectedPrice = pricing?.find(price => price?.id === selectedPriceId);
                            if (selectedPrice) {
                              setsubPrice(selectedPrice?.subscrption_fees)
                              setToken(selectedPrice.total_gg_token); // Set the token value
                            }
                          }
                        }
                      >
                        {pricing?.map((price, i) => (
                          <MenuItem key={price?.id} value={price?.id}>{price?.name}</MenuItem>
                        ))}

                      </Select>
                    </FormControl> */}
                  </div>

                  {/* <div className="form-group my-20">
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        name="start_date"
                        value={formik?.values?.start_date}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                        required
                        type="date"
                        label="Start Date"
                        color="green"
                      />
                    </FormControl>
                    <FormControl fullWidth color="green">
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={formik?.values?.end_date}
                        onChange={formik.handleChange}
                        name="end_date"
                        required
                        type="date"
                        label="End Date"
                        color="green"
                      />
                    </FormControl>
                  </div> */}

                  {/* <div className="form-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik?.values?.is_comission_paid}
                          onChange={
                            (e) =>
                              formik.setFieldValue(
                                "is_comission_paid",
                                e.target.checked
                              ) // Set the selected user value
                          }
                          name="is_comission_paid"
                        />
                      }
                      label="Is Commission Paid?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik?.values?.is_deposit_refunded}
                          onChange={
                            (e) =>
                              formik.setFieldValue(
                                "is_deposit_refunded",
                                e.target.checked
                              ) // Set the selected user value
                          }
                          name="is_deposit_refunded"
                        />
                      }
                      label="Is Deposit Refunded?"
                    />
                  </div> */}

                  <div className="form-group">
                    <input
                      type="submit"
                      value={"Add Customer"}
                      className="button green"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </form>
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/* Order Add Form */}
    </>
  );
}

export default AddCusomter;
