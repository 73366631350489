import React, {useEffect,useState} from 'react';

import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from "react-router-dom";

function Layout() {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
        setIsLoading(true);

        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);  // Adjust this duration based on your needs
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		  });
        return () => clearTimeout(timer);  // Cleanup the timer
    }, [location]);
	return (
		<>	

			{/* <div className="img-1 ">
				<img src="/images/img-1.svg" alt="" />
			</div> */}
			<Header></Header>
			<div className="main" id='main'>
				{isLoading && 
					<div className="loader-animation d-flex" >
						<img src="/images/giraffe.gif" alt="" />
						Loading..
					</div>}
				<Outlet></Outlet>
			</div>
			<Footer></Footer>
		</>
	)
}

export default Layout