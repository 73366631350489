import '../style/components/header/header.css';
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom';
import { useAppContext } from '../context/UserContext';
import Hamburger from 'hamburger-react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutButton from '../../dashboard/admin/components/Logout/Logout';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


// Api
import { FetchProfileApi } from '../../globalAPIs/ProfileApi';
import { ProductSearchAPI } from '../../globalAPIs/ProductApi';
import { RetriveCartApi } from '../api/CartInfo';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { SubscriptionInfoApi } from '../../dashboard/customer/api/SubscriptionInfo';

// const Resize = (() => {
// 	var height = document.getElementById('header').offsetHeight
// 	document.getElementById('main').style.marginTop = height + 'px'
// 	document.getElementById('main').style.paddingTop = '30px'
// })

// function formatDataForAutocomplete(data) {
// 	// Flatten the data structure
// 	const flattenedData = data.flatMap((itemArray) => itemArray);

// 	// Transform it into the format expected by Autocomplete
// 	const autocompleteData = flattenedData.map((item, index) => ({
// 		label: item.name,
// 		sku: item.sku,
// 		available_stock: item.available_stock,
// 	}));

// 	return autocompleteData;
// }

export default function Header(props) {
	const [isOpen, setOpen] = useState(false)
	const location = useLocation();
	const [token, setToken] = useState()
	const [profile, setprofileName] = useState()
	const [user, setuser] = useState(null)
	const [subscription, setsubscription] = useState(null)

	const [list, setProductList] = useState()

	const { cartCount, customer, isSuperUser, updateCartcount, loggedIn, UpdateLogin, updateSuperUser, updatecustomer, updateCsrf } = useAppContext();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// Fetch Profile
	useEffect(() => {

		const fetchCartData = async () => {
			try {
				const data = await FetchProfileApi(UpdateLogin, updateSuperUser, updateCartcount, updatecustomer, updateCsrf);
				const cart = await RetriveCartApi();
				const subs = await SubscriptionInfoApi()
				updateCartcount(cart.cart_items.length)

				setToken(data?.gg_token + data?.topup_token)
				setprofileName(data.name);
				setuser(data)

				if(subs?.results?.length > 0){
					setsubscription(subs?.results?.[0])
				}

			} catch (error) {
				console.error("Error fetching cart data:", error);
			}
		};
		// const fetchProductSearch = async () => {
		// 	try {
		// 		const data = await ProductSearchAPI();
		// 		const response = await formatDataForAutocomplete(data.data.product)
		// 		console.log(response)
		// 		setProductList(response)
		// 	} catch (error) {
		// 		console.error("Error :", error);
		// 	}
		// };

		// fetchProductSearch()
		fetchCartData();
	}, [2]);

	// window.onresize = Resize;

	useEffect(() => {
		// Resize()
		setOpen(false);
	}, [location.pathname]);

	// const getOptionLabel = (option) => {
	// 	const stockStatus = option.available_stock === 0 ? "Out of Stock" : "In Stock";
	// 	return `${option.label} - ${option.sku} - ${stockStatus}`;
	// };

	// const renderOption = (props, option) => (
	// 	<li {...props}>
	// 		<div>
	// 			{getOptionLabel(option)}
	// 		</div>
	// 	</li>
	// );

	// const filterOptions = (options, { inputValue }) => {
	// 	return options.filter((option) => {
	// 		const searchValue = inputValue.toLowerCase();
	// 		const optionLabel = getOptionLabel(option).toLowerCase();
	// 		const skuValue = option.sku.toLowerCase(); // Include SKU in the search

	// 		return optionLabel.includes(searchValue) || skuValue === searchValue;
	// 	});
	// };

	function isSubscriptionAboutToExpire(subscriptionData) {
		const endDateStr = subscriptionData?.end_date;
		if (!endDateStr) {
			return false;
		}
	
		const endDate = new Date(endDateStr);
		const currentDate = new Date();
		const timeDifference = endDate.getTime() - currentDate.getTime();
		const daysUntilEnd = Math.ceil(timeDifference / (1000 * 3600 * 24));
	
		return daysUntilEnd <= 2;
	}

	console.log('fduifidsfb', user, isSubscriptionAboutToExpire(subscription))

	return (
		<header id='header'>
			<div className="desktop-nav ">
				{user?.gg_token < 500 ? (
					<marquee direction="left" loop="" className="marquee-text">
					Your GG Tokens are less than 500, Please buy GG tokens to continue shopping.
				</marquee>
				) : isSubscriptionAboutToExpire(subscription) ? (
					<marquee direction="left" loop="" className="marquee-text">
						Your Subscription will be expired in 2 days, Please renew your subscription to continue shopping.
					</marquee>
				) : ''}

				<div className="left">
					<a href="/">
						<div className="logo-container">
							<img src="/images/logo.png" alt="" />
						</div>
					</a>

				</div>
				<div className="center">
					<form action="/toy-store" method='GET'>
						<FormControl fullWidth color="green">
							<InputLabel htmlFor="name">Search for Toys, Books, Puzzles etc</InputLabel>
							<OutlinedInput
								id="name"
								name="name"
								label="Search for Toys, Books, Puzzles etc"
							/>
						</FormControl>
						{/* <TextField id="name" color='green' label="Search Products" name='name' variant="standard" /> */}
					</form>
				</div>
				<div className="right">
					<nav>
						<ul>
							{/* <li>
								<Link to="/">
									Home
								</Link>
							</li> */}

							<li>
								<Link to="/toy-store">
									Toy Catalogue
								</Link>
							</li>

							{/* <li>
								<Link to="/about">
									About Us
								</Link>
							</li> */}
							<li>
								<Link to="/pricing">
									Pricing
								</Link>
							</li>
							<li>
								<Link to="/contact-us">
									Contact Us
								</Link>
							</li>

							<li>
								{loggedIn ? (<>
									<Link to='/cart'>
										<div className="icon-container">
											<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M4.25 1.3623C3.55965 1.3623 3 1.92195 3 2.6123C3 3.30265 3.55965 3.8623 4.25 3.8623H5.77403L6.15597 5.3901C6.15999 5.40777 6.16437 5.42529 6.16912 5.44267L7.86566 12.2288L6.74994 13.3446C5.17502 14.9194 6.29044 17.6123 8.5177 17.6123H19.25C19.9403 17.6123 20.5 17.0527 20.5 16.3623C20.5 15.6719 19.9403 15.1123 19.25 15.1123H8.51771L9.7677 13.8623H18C18.4735 13.8623 18.9062 13.5948 19.118 13.1713L22.868 5.67132C23.0618 5.28384 23.0411 4.82367 22.8134 4.45514C22.5855 4.08662 22.1833 3.8623 21.75 3.8623H8.35098L7.96268 2.30913C7.82356 1.75268 7.32359 1.3623 6.75 1.3623H4.25Z" fill="#4B9E99" />
												<path d="M20.5 20.7373C20.5 21.7728 19.6605 22.6123 18.625 22.6123C17.5895 22.6123 16.75 21.7728 16.75 20.7373C16.75 19.7018 17.5895 18.8623 18.625 18.8623C19.6605 18.8623 20.5 19.7018 20.5 20.7373Z" fill="#4B9E99" />
												<path d="M8.625 22.6123C9.66054 22.6123 10.5 21.7728 10.5 20.7373C10.5 19.7018 9.66054 18.8623 8.625 18.8623C7.58946 18.8623 6.75 19.7018 6.75 20.7373C6.75 21.7728 7.58946 22.6123 8.625 22.6123Z" fill="#4B9E99" />
											</svg>
											{cartCount ? (<>
												<div className="text-container">
													{cartCount}
												</div>
											</>) : (<></>)}

										</div>

									</Link>
								</>) : (<>
									<Link to='/login'>
										<div className="icon-container">
											<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M4.25 1.3623C3.55965 1.3623 3 1.92195 3 2.6123C3 3.30265 3.55965 3.8623 4.25 3.8623H5.77403L6.15597 5.3901C6.15999 5.40777 6.16437 5.42529 6.16912 5.44267L7.86566 12.2288L6.74994 13.3446C5.17502 14.9194 6.29044 17.6123 8.5177 17.6123H19.25C19.9403 17.6123 20.5 17.0527 20.5 16.3623C20.5 15.6719 19.9403 15.1123 19.25 15.1123H8.51771L9.7677 13.8623H18C18.4735 13.8623 18.9062 13.5948 19.118 13.1713L22.868 5.67132C23.0618 5.28384 23.0411 4.82367 22.8134 4.45514C22.5855 4.08662 22.1833 3.8623 21.75 3.8623H8.35098L7.96268 2.30913C7.82356 1.75268 7.32359 1.3623 6.75 1.3623H4.25Z" fill="#4B9E99" />
												<path d="M20.5 20.7373C20.5 21.7728 19.6605 22.6123 18.625 22.6123C17.5895 22.6123 16.75 21.7728 16.75 20.7373C16.75 19.7018 17.5895 18.8623 18.625 18.8623C19.6605 18.8623 20.5 19.7018 20.5 20.7373Z" fill="#4B9E99" />
												<path d="M8.625 22.6123C9.66054 22.6123 10.5 21.7728 10.5 20.7373C10.5 19.7018 9.66054 18.8623 8.625 18.8623C7.58946 18.8623 6.75 19.7018 6.75 20.7373C6.75 21.7728 7.58946 22.6123 8.625 22.6123Z" fill="#4B9E99" />
											</svg>
											{cartCount ? (<>
												<div className="text-container">
													{cartCount}
												</div>
											</>) : (<></>)}

										</div>

									</Link>
								</>)}

							</li>
							<li>
								{loggedIn ? (<>
									<Link to='/customer-dashboard' target='_blank'>
										<div className="icon-container">
											<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd" d="M4.46446 7.07286C6.41708 5.12025 9.58291 5.12025 11.5355 7.07286L13 8.53733L14.4645 7.07286C16.4171 5.12025 19.5829 5.12025 21.5355 7.07286C23.4881 9.02548 23.4881 12.1913 21.5355 14.1439L13 22.6795L4.46446 14.1439C2.51185 12.1913 2.51185 9.02548 4.46446 7.07286Z" fill="#4B9E99" />
											</svg>
										</div>

									</Link>
								</>) : (<>
								</>)}
							</li>
							{loggedIn ?
								(
									<>
										<li style={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#D49A34' }}>
											<div className="icon-container">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_601_4935)">
														<path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z" fill="#F6D738" />
														<path d="M8.00043 14.7895C11.7502 14.7895 14.7899 11.7497 14.7899 8C14.7899 4.25025 11.7502 1.2105 8.00043 1.2105C4.25069 1.2105 1.21094 4.25025 1.21094 8C1.21094 11.7497 4.25069 14.7895 8.00043 14.7895Z" fill="#F3CC30" />
														<path d="M11.5518 2.20514C12.2513 3.2965 12.6221 4.56602 12.62 5.86227C12.62 9.61202 9.58024 12.6518 5.83049 12.6518C4.57635 12.6537 3.34639 12.3065 2.27832 11.6491C2.89199 12.6099 3.73787 13.4005 4.73783 13.948C5.73779 14.4955 6.85961 14.7821 7.99964 14.7815C11.7494 14.7815 14.7891 11.7417 14.7891 7.99198C14.7897 6.83101 14.4923 5.68934 13.9255 4.67615C13.3587 3.66296 12.5414 2.81217 11.5518 2.20514Z" fill="#EDBD31" />
														<path d="M2.40022 8.62123C2.40022 5.31119 5.08352 2.62788 8.39358 2.62788C9.31193 2.62712 10.2181 2.83779 11.042 3.24354C11.8659 3.64931 12.5853 4.23927 13.1445 4.96773C12.6193 4.05374 11.8622 3.29449 10.9497 2.76666C10.0372 2.23882 9.00161 1.9611 7.94747 1.96153C4.63744 1.96153 1.95411 4.64486 1.95411 7.9549C1.95215 9.27672 2.38917 10.5618 3.19658 11.6084C2.67327 10.6999 2.39859 9.66962 2.40022 8.62123Z" fill="#F7DB5E" />
														<path d="M6.41258 7.14961C6.3412 7.03201 6.24251 6.94173 6.11652 6.87874C5.99475 6.81575 5.84986 6.78425 5.68188 6.78425C5.37112 6.78425 5.12545 6.88504 4.94487 7.08662C4.76849 7.28819 4.6803 7.55906 4.6803 7.89921C4.6803 8.28138 4.77479 8.57323 4.96377 8.77481C5.15695 8.97217 5.43621 9.07087 5.80156 9.07087C6.23412 9.07087 6.54278 8.87559 6.72755 8.48504H5.4866V7.59685H7.79211V8.7937C7.69553 9.02886 7.55274 9.24934 7.36377 9.45512C7.179 9.6609 6.94381 9.83098 6.65825 9.96536C6.3727 10.0955 6.04723 10.1606 5.68188 10.1606C5.23672 10.1606 4.84199 10.0661 4.49762 9.87717C4.15747 9.68398 3.8929 9.41732 3.70392 9.07717C3.51915 8.7328 3.42676 8.34016 3.42676 7.89921C3.42676 7.46248 3.51915 7.07402 3.70392 6.73386C3.8929 6.38949 4.15747 6.12284 4.49762 5.93386C4.83778 5.74068 5.23042 5.6441 5.67558 5.6441C6.23412 5.6441 6.69605 5.77847 7.0614 6.04725C7.42676 6.31602 7.65353 6.68347 7.74172 7.14961H6.41258ZM11.1801 7.14961C11.1087 7.03201 11.01 6.94173 10.884 6.87874C10.7622 6.81575 10.6173 6.78425 10.4493 6.78425C10.1386 6.78425 9.89292 6.88504 9.71234 7.08662C9.53596 7.28819 9.44777 7.55906 9.44777 7.89921C9.44777 8.28138 9.54226 8.57323 9.73124 8.77481C9.92442 8.97217 10.2037 9.07087 10.569 9.07087C11.0016 9.07087 11.3102 8.87559 11.495 8.48504H10.2541V7.59685H12.5596V8.7937C12.463 9.02886 12.3202 9.24934 12.1312 9.45512C11.9465 9.6609 11.7113 9.83098 11.4257 9.96536C11.1402 10.0955 10.8147 10.1606 10.4493 10.1606C10.0042 10.1606 9.60946 10.0661 9.26509 9.87717C8.92494 9.68398 8.66037 9.41732 8.47139 9.07717C8.28663 8.7328 8.19423 8.34016 8.19423 7.89921C8.19423 7.46248 8.28663 7.07402 8.47139 6.73386C8.66037 6.38949 8.92494 6.12284 9.26509 5.93386C9.60525 5.74068 9.99789 5.6441 10.443 5.6441C11.0016 5.6441 11.4635 5.77847 11.8289 6.04725C12.1942 6.31602 12.421 6.68347 12.5092 7.14961H11.1801Z" fill="#D49A34" />
														<path d="M6.18774 11.3608V11.6123H5.88457V12.4976H5.5685V11.6123H5.26855V11.3608H6.18774ZM6.84973 12.5089C6.7433 12.5089 6.64546 12.4842 6.55624 12.4347C6.46702 12.3842 6.39606 12.3149 6.34338 12.2267C6.29177 12.1375 6.26597 12.037 6.26597 11.9252C6.26597 11.8134 6.29177 11.7134 6.34338 11.6252C6.39606 11.536 6.46702 11.4667 6.55624 11.4172C6.64546 11.3677 6.7433 11.343 6.84973 11.343C6.95725 11.343 7.05506 11.3677 7.14323 11.4172C7.23245 11.4667 7.30287 11.536 7.35448 11.6252C7.40608 11.7134 7.43188 11.8134 7.43188 11.9252C7.43188 12.037 7.40608 12.1375 7.35448 12.2267C7.30287 12.3149 7.23245 12.3842 7.14323 12.4347C7.054 12.4842 6.95616 12.5089 6.84973 12.5089ZM6.84973 12.2154C6.93036 12.2154 6.99378 12.1891 7.04002 12.1364C7.08731 12.0837 7.11097 12.0133 7.11097 11.9252C7.11097 11.8349 7.08731 11.7639 7.04002 11.7123C6.99378 11.6596 6.93036 11.6333 6.84973 11.6333C6.76802 11.6333 6.70407 11.6596 6.65783 11.7123C6.6116 11.7639 6.58849 11.8349 6.58849 11.9252C6.58849 12.0144 6.6116 12.0853 6.65783 12.138C6.70407 12.1896 6.76802 12.2154 6.84973 12.2154ZM8.24642 12.4976L7.89003 11.9993V12.4976H7.57397V11.3608H7.89003V11.851L8.24319 11.3608H8.60764L8.18998 11.9123L8.62861 12.4976H8.24642ZM9.06013 11.6139V11.7994H9.42296V12.0397H9.06013V12.2445H9.47134V12.4976H8.74406V11.3608H9.47134V11.6139H9.06013ZM10.6805 12.4976H10.3645L9.94358 11.8623V12.4976H9.62751V11.3608H9.94358L10.3645 12.0042V11.3608H10.6805V12.4976Z" fill="#D49A34" />
													</g>
													<defs>
														<clipPath id="clip0_601_4935">
															<rect width="16" height="16" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</div>
											{token} GG Tokens
										</li>
										<li>
											<button className='button  profile-btn' aria-controls={open ? 'basic-menu' : undefined}
												aria-haspopup="true"
												aria-expanded={open ? 'true' : undefined}
												onClick={handleClick}>
												<div className="icon-container">
													<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fillRule="evenodd" clipRule="evenodd" d="M23 13.1084C23 18.6313 18.5229 23.1084 13 23.1084C7.47715 23.1084 3 18.6313 3 13.1084C3 7.58555 7.47715 3.1084 13 3.1084C18.5229 3.1084 23 7.58555 23 13.1084ZM15.5 9.3584C15.5 10.7391 14.3807 11.8584 13 11.8584C11.6193 11.8584 10.5 10.7391 10.5 9.3584C10.5 7.97769 11.6193 6.8584 13 6.8584C14.3807 6.8584 15.5 7.97769 15.5 9.3584ZM12.9999 14.3584C10.478 14.3584 8.30492 15.8521 7.31717 18.003C8.69254 19.5984 10.7283 20.6084 13 20.6084C15.2716 20.6084 17.3074 19.5985 18.6827 18.0031C17.695 15.8521 15.5219 14.3584 12.9999 14.3584Z" fill="white" />
													</svg>
												</div>
												{profile}
											</button>
											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												MenuListProps={{
													'aria-labelledby': 'basic-button',
												}}
											>
												{customer ? (
													[
														<MenuItem key="dashboard">
															<Link to="/customer-dashboard" target="_blank">
																My Profile
															</Link>
														</MenuItem>,
														<MenuItem key="logout">
															<LogoutButton></LogoutButton>
														</MenuItem>,
													]
												) : (
													[
														<MenuItem key="admin-dashboard">
															<Link to="/admin" target="_blank">
																Dashboard
															</Link>
														</MenuItem>,
														<MenuItem key="logout">
															<LogoutButton></LogoutButton>
														</MenuItem>,
													]
												)}
											</Menu>
										</li>
									</>
								) :
								(<>
									<Link to='/login'>
										<button className='login button green'>
											Login
										</button>
									</Link>
								</>)}
							<Link>

							</Link>
						</ul>

					</nav>
					{/* {list ? (<>
							<Autocomplete
								disablePortal
								id="combo-box-demo"
								options={list}
								filterOptions={filterOptions}
								renderOption={renderOption} // Custom rendering of options
								color='green'
								sx={{ width: 200 }}
								renderInput={(params) => <TextField {...params}
									label="Search"
									color='green'
									variant="standard"
									style={{ backgroundColor: "transparent" }}
								/>}
							/>
						</>) : (<></>)} */}
				</div>
			</div>
			<div className="mobile-nav-bar container">
				<div className="mobile-nav ">
					<div className="left">
						<div className="logo-container">
							<img src="/images/logo.png" alt="" />
						</div>

					</div>

					<div className="right">
						{/* {list ? (<>
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									options={list}
									filterOptions={filterOptions}
									renderOption={renderOption} // Custom rendering of options
									color='green'
									sx={{ width: 200 }}
									renderInput={(params) => <TextField {...params}
										label="Search"
										color='green'
										variant="standard"
										style={{ backgroundColor: "" }}
									/>}
								/>
							</>) : (<></>)} */}


						<Hamburger rounded toggled={isOpen} toggle={setOpen} />
						<div className={isOpen ? "active mobile-list" : "inactive mobile-list"}>
							<div className="logo-container">
								<img src="/images/logo.png" alt="" />
							</div>
							<nav>
								<ul>
									{loggedIn ? (<>
										<li style={{ fontSize: 20, color: 'black' }}>
											Welcome <span style={{ fontWeight: 'bold', fontSize: 20, color: '#4B9E99' }}>{profile}</span>
										</li>
										<li style={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#D49A34' }}>
											<div className="icon-container">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_601_4935)">
														<path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z" fill="#F6D738" />
														<path d="M8.00043 14.7895C11.7502 14.7895 14.7899 11.7497 14.7899 8C14.7899 4.25025 11.7502 1.2105 8.00043 1.2105C4.25069 1.2105 1.21094 4.25025 1.21094 8C1.21094 11.7497 4.25069 14.7895 8.00043 14.7895Z" fill="#F3CC30" />
														<path d="M11.5518 2.20514C12.2513 3.2965 12.6221 4.56602 12.62 5.86227C12.62 9.61202 9.58024 12.6518 5.83049 12.6518C4.57635 12.6537 3.34639 12.3065 2.27832 11.6491C2.89199 12.6099 3.73787 13.4005 4.73783 13.948C5.73779 14.4955 6.85961 14.7821 7.99964 14.7815C11.7494 14.7815 14.7891 11.7417 14.7891 7.99198C14.7897 6.83101 14.4923 5.68934 13.9255 4.67615C13.3587 3.66296 12.5414 2.81217 11.5518 2.20514Z" fill="#EDBD31" />
														<path d="M2.40022 8.62123C2.40022 5.31119 5.08352 2.62788 8.39358 2.62788C9.31193 2.62712 10.2181 2.83779 11.042 3.24354C11.8659 3.64931 12.5853 4.23927 13.1445 4.96773C12.6193 4.05374 11.8622 3.29449 10.9497 2.76666C10.0372 2.23882 9.00161 1.9611 7.94747 1.96153C4.63744 1.96153 1.95411 4.64486 1.95411 7.9549C1.95215 9.27672 2.38917 10.5618 3.19658 11.6084C2.67327 10.6999 2.39859 9.66962 2.40022 8.62123Z" fill="#F7DB5E" />
														<path d="M6.41258 7.14961C6.3412 7.03201 6.24251 6.94173 6.11652 6.87874C5.99475 6.81575 5.84986 6.78425 5.68188 6.78425C5.37112 6.78425 5.12545 6.88504 4.94487 7.08662C4.76849 7.28819 4.6803 7.55906 4.6803 7.89921C4.6803 8.28138 4.77479 8.57323 4.96377 8.77481C5.15695 8.97217 5.43621 9.07087 5.80156 9.07087C6.23412 9.07087 6.54278 8.87559 6.72755 8.48504H5.4866V7.59685H7.79211V8.7937C7.69553 9.02886 7.55274 9.24934 7.36377 9.45512C7.179 9.6609 6.94381 9.83098 6.65825 9.96536C6.3727 10.0955 6.04723 10.1606 5.68188 10.1606C5.23672 10.1606 4.84199 10.0661 4.49762 9.87717C4.15747 9.68398 3.8929 9.41732 3.70392 9.07717C3.51915 8.7328 3.42676 8.34016 3.42676 7.89921C3.42676 7.46248 3.51915 7.07402 3.70392 6.73386C3.8929 6.38949 4.15747 6.12284 4.49762 5.93386C4.83778 5.74068 5.23042 5.6441 5.67558 5.6441C6.23412 5.6441 6.69605 5.77847 7.0614 6.04725C7.42676 6.31602 7.65353 6.68347 7.74172 7.14961H6.41258ZM11.1801 7.14961C11.1087 7.03201 11.01 6.94173 10.884 6.87874C10.7622 6.81575 10.6173 6.78425 10.4493 6.78425C10.1386 6.78425 9.89292 6.88504 9.71234 7.08662C9.53596 7.28819 9.44777 7.55906 9.44777 7.89921C9.44777 8.28138 9.54226 8.57323 9.73124 8.77481C9.92442 8.97217 10.2037 9.07087 10.569 9.07087C11.0016 9.07087 11.3102 8.87559 11.495 8.48504H10.2541V7.59685H12.5596V8.7937C12.463 9.02886 12.3202 9.24934 12.1312 9.45512C11.9465 9.6609 11.7113 9.83098 11.4257 9.96536C11.1402 10.0955 10.8147 10.1606 10.4493 10.1606C10.0042 10.1606 9.60946 10.0661 9.26509 9.87717C8.92494 9.68398 8.66037 9.41732 8.47139 9.07717C8.28663 8.7328 8.19423 8.34016 8.19423 7.89921C8.19423 7.46248 8.28663 7.07402 8.47139 6.73386C8.66037 6.38949 8.92494 6.12284 9.26509 5.93386C9.60525 5.74068 9.99789 5.6441 10.443 5.6441C11.0016 5.6441 11.4635 5.77847 11.8289 6.04725C12.1942 6.31602 12.421 6.68347 12.5092 7.14961H11.1801Z" fill="#D49A34" />
														<path d="M6.18774 11.3608V11.6123H5.88457V12.4976H5.5685V11.6123H5.26855V11.3608H6.18774ZM6.84973 12.5089C6.7433 12.5089 6.64546 12.4842 6.55624 12.4347C6.46702 12.3842 6.39606 12.3149 6.34338 12.2267C6.29177 12.1375 6.26597 12.037 6.26597 11.9252C6.26597 11.8134 6.29177 11.7134 6.34338 11.6252C6.39606 11.536 6.46702 11.4667 6.55624 11.4172C6.64546 11.3677 6.7433 11.343 6.84973 11.343C6.95725 11.343 7.05506 11.3677 7.14323 11.4172C7.23245 11.4667 7.30287 11.536 7.35448 11.6252C7.40608 11.7134 7.43188 11.8134 7.43188 11.9252C7.43188 12.037 7.40608 12.1375 7.35448 12.2267C7.30287 12.3149 7.23245 12.3842 7.14323 12.4347C7.054 12.4842 6.95616 12.5089 6.84973 12.5089ZM6.84973 12.2154C6.93036 12.2154 6.99378 12.1891 7.04002 12.1364C7.08731 12.0837 7.11097 12.0133 7.11097 11.9252C7.11097 11.8349 7.08731 11.7639 7.04002 11.7123C6.99378 11.6596 6.93036 11.6333 6.84973 11.6333C6.76802 11.6333 6.70407 11.6596 6.65783 11.7123C6.6116 11.7639 6.58849 11.8349 6.58849 11.9252C6.58849 12.0144 6.6116 12.0853 6.65783 12.138C6.70407 12.1896 6.76802 12.2154 6.84973 12.2154ZM8.24642 12.4976L7.89003 11.9993V12.4976H7.57397V11.3608H7.89003V11.851L8.24319 11.3608H8.60764L8.18998 11.9123L8.62861 12.4976H8.24642ZM9.06013 11.6139V11.7994H9.42296V12.0397H9.06013V12.2445H9.47134V12.4976H8.74406V11.3608H9.47134V11.6139H9.06013ZM10.6805 12.4976H10.3645L9.94358 11.8623V12.4976H9.62751V11.3608H9.94358L10.3645 12.0042V11.3608H10.6805V12.4976Z" fill="#D49A34" />
													</g>
													<defs>
														<clipPath id="clip0_601_4935">
															<rect width="16" height="16" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</div>
											{token} GG Tokens
										</li>
										{customer ? (<>
											<li>
												<Link to="/customer-dashboard">
													My Profile
												</Link>
											</li>
										</>) : (<>
											<li >
												<Link to="/admin">
													Dashboard
												</Link>
											</li>
										</>)}
										<li>
										<Link to="/">
											Home
										</Link>
									</li>

									<li>
										<Link to="/toy-store">
											Toy Catalogue
										</Link>
									</li>

									<li>
										<Link to="/about">
											About Us
										</Link>
									</li>
									<li>
										<Link to="/pricing">
											Pricing
										</Link>
									</li>
									
									</>) : (<>

										<li>
										<Link to="/">
											Home
										</Link>
									</li>

									<li>
										<Link to="/toy-store">
											Toy Catalogue
										</Link>
									</li>

									<li>
										<Link to="/about">
											About Us
										</Link>
									</li>
									<li>
										<Link to="/pricing">
											Pricing
										</Link>
									</li>
									</>)}

									
									
									{/* <li>
									<Link to="/contact">
										Contact Us
									</Link>
								</li> */}

									<li>
										{loggedIn ? (<>
											<Link to='/cart'>
												My Cart
											</Link>
										</>) : (<>
											<Link to='/login'>
												<div className="icon-container">

													<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M4.25 1.3623C3.55965 1.3623 3 1.92195 3 2.6123C3 3.30265 3.55965 3.8623 4.25 3.8623H5.77403L6.15597 5.3901C6.15999 5.40777 6.16437 5.42529 6.16912 5.44267L7.86566 12.2288L6.74994 13.3446C5.17502 14.9194 6.29044 17.6123 8.5177 17.6123H19.25C19.9403 17.6123 20.5 17.0527 20.5 16.3623C20.5 15.6719 19.9403 15.1123 19.25 15.1123H8.51771L9.7677 13.8623H18C18.4735 13.8623 18.9062 13.5948 19.118 13.1713L22.868 5.67132C23.0618 5.28384 23.0411 4.82367 22.8134 4.45514C22.5855 4.08662 22.1833 3.8623 21.75 3.8623H8.35098L7.96268 2.30913C7.82356 1.75268 7.32359 1.3623 6.75 1.3623H4.25Z" fill="#4B9E99" />
														<path d="M20.5 20.7373C20.5 21.7728 19.6605 22.6123 18.625 22.6123C17.5895 22.6123 16.75 21.7728 16.75 20.7373C16.75 19.7018 17.5895 18.8623 18.625 18.8623C19.6605 18.8623 20.5 19.7018 20.5 20.7373Z" fill="#4B9E99" />
														<path d="M8.625 22.6123C9.66054 22.6123 10.5 21.7728 10.5 20.7373C10.5 19.7018 9.66054 18.8623 8.625 18.8623C7.58946 18.8623 6.75 19.7018 6.75 20.7373C6.75 21.7728 7.58946 22.6123 8.625 22.6123Z" fill="#4B9E99" />
													</svg>
													{cartCount ? (<>
														<div className="text-container">

														</div>
													</>) : (<></>)}

												</div>

											</Link>
										</>)}

									</li>
									{/* <li>
										{loggedIn ? (<>
											<Link to='/customer-dashboard'>
												My Favourites
											</Link>
										</>) : (<>
										</>)}
									</li> */}
									{loggedIn ?
										(
											<>
											<li>
										<LogoutButton></LogoutButton>
									</li>
											</>
										) :
										(<>
											<Link to='/login'>
												<button className='button green'>
													Login
												</button>
											</Link>
										</>)}

								</ul>
							</nav>
						</div>
					</div>
				</div>
				<form action="/toy-store" method='GET'>
					<TextField style={{ width: 300 }}
						id="name" color='green' label="Search Products" name='name' variant="standard" />
				</form>
			</div>

		</header>
	)

}
