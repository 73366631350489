import axios from "axios";
import { BASE_API_URL } from "../../../server";
import dayjs from "dayjs";

// Define the API endpoint
const csrfTokenApiUrl = `${BASE_API_URL}api-admin/csrf_token/`;
let csrf;

const fetchCsrfToken = async () => {
  const abortController = new AbortController();
  try {
    const response = await axios.get(csrfTokenApiUrl, {
      withCredentials: true,
    });
    csrf = response.data.csrftoken;
    return () => {
      abortController.abort();
    };
  } catch (error) {
    console.error("There was a problem fetching the CSRF token:", error);
  }
};

export const ProductApi = async (query) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/list/?` + query;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });
    const data = {
      current_page: 1,
      prev: response.data.links.previous,
      next: response.data.links.next,
      pages: response.data.links.pages,
      total_count: response.data.total_items,
      page_size: response.data.page_size,
    };
    const result = response.data.results.map((item) => ({
      id: item.id,
      product_img: `${BASE_API_URL}` + item.image,
      name: item.name,
      small_description: item.small_description,
      slug: item.slug,
      seven: item.price_7,
      deposit: item.deposit,
      is_featured: item.is_featured,
      inventory: item.available_stock,
      renvenue_generated: "", // This field is not available in the API response
      number_of_days_booked: "", // This field is not available in the API response
      total_in_wishlist: item.total_wishlist,
      available_stock: item.available_stock,
      stock_status: item.available_stock > 0 ? true : false,
      order: item.order_by,
      sku: item.sku,
      stock: item.total_stock,
      categories: item.category, // You might need to adjust this based on the actual structure of the category field
    }));
    const ProductData = {
      result,
      data,
    };

    return ProductData;
  } catch (error) {
    console.log("***** ERROR in Fetching Products *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const ProductDeleteApi = async (id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/${id}/delete/`;
  // await fetchCsrfToken();
  try {
    const response = await axios.delete(
      apiEndpoint,

      {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    console.log("***** ERROR in Deleting the Products *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const ProductCategoryApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/toycategory/list/`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });

    const ProductCategoryData = response.data.results;
    return ProductCategoryData;
  } catch (error) {
    console.log("***** ERROR in Fetching Products *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const ProductSubCategoryApi = async () => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/toysubcategory/list/`;
  try {
    const response = await axios.get(apiEndpoint, { withCredentials: true });

    const ProductSubCategoryData = response.data.results;
    return ProductSubCategoryData;
  } catch (error) {
    console.log("***** ERROR in Fetching Products *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const ProductDetailApi = async (id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/${id}/detail/`;
  // await fetchCsrfToken();
  try {
    const response = await axios.get(
      apiEndpoint,

      {
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
      }
    );

    return response;
  } catch (error) {
    console.log("***** ERROR in fetching the Product Detail *****");
    console.log(error);
    console.log("***************************************");
    // Additional error handling as needed
  }
};

export const AddProductApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const EditProductApi = async (values, id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/product/${id}/change/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.put(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const ProductImageApi = async (id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/productimage/list/?product=${id}`;
  // await fetchCsrfToken()
  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const AddProductImageApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/productimage/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const EditProductImageApi = async (values) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/productimage/add/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.post(apiEndpoint, values, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const DeleteProductImageApi = async (id) => {
  const apiEndpoint = `${BASE_API_URL}api-admin/web/productimage/${id}/delete/`;
  // await fetchCsrfToken()
  try {
    const response = await axios.delete(apiEndpoint, {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.log("Add Product is not working");
  }
};

export const ProductDetailReport = async (startDate, endDate) => {
  const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

  console.log("formt datesss", formattedStartDate, formattedEndDate);
  const apiEndpoint = `${BASE_API_URL}api/product-utilization/?start_date=${formattedStartDate}&end_date=${formattedEndDate}&format=json`;

  try {
    const response = await axios.get(apiEndpoint, {
      responseType: "blob", // Tell Axios to expect a Blob
      withCredentials: true,
    });

    return response;
  } catch (error) {
    console.log("***** ERROR in fetching the Product Detail *****");
    console.log(error);
    console.log("***************************************");
    throw error; // Rethrow the error so it can be caught by the caller
  }
};
