import axios from 'axios';
import { BASE_API_URL } from '../server'

export const FetchProfileApi = async (updateCartcount,UpdateLogin, updateSuperUser, updatecustomer, updateCsrf) => {

    const axiosInstance = axios.create({
        headers: {
            'Accept': 'application/json',
        },
        withCredentials: true,
    });

    // Add a response interceptor
    axiosInstance.interceptors.response.use(response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    }, error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response && error.response.status === 403) {
            console.log("403 error")
            UpdateLogin(false)
            updateSuperUser(false);
            updatecustomer(false);
            updateCartcount(0)
        }
        return Promise.reject(error);
    });

    const apiEndpoint = `${BASE_API_URL}api/profile/`;
    try {
            const response      = await axiosInstance.get(apiEndpoint, { withCredentials: true });
            const Profile       = response.data
        return Profile;

    } catch (error) {
       
    }
};

export const ProfileEdit = async (values) => {
    try {
        const response = await axios.post(
            `${BASE_API_URL}api/profile/`,
            {
                "profile_image": null,
                "name": values.name,
                "address_1": values.address1,
                "address_2": values.address2,
                "postal_code": values.postal_code,
                "city": values.city,
                "region_state": "",
                "country": "India",
                "user_type": "customer",
                "mobile_number": values.telephone,
                "email_confirmed": false
            },
            {
                headers: {
                    'Accept': 'application/json',
                },
                withCredentials: true,
            }
        );

       return response

    } catch (error) {
       
    }
};